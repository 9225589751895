import { useDispatch, useSelector } from "react-redux";
import { ICategory } from "../../models";
import { AppState } from "../main.reducer";
import { getCategoriesApi } from "../Menu/api/getCategoriesApi";
import { SELECT_CATEGORY } from "../Menu/menu.actions";

export const useCategories = () => {
  const categories = useSelector<AppState, ICategory[]>(
    (store) => store.menu.categories as ICategory[]
  );
  const selectedCategory = useSelector<AppState, ICategory | undefined>(
    (store) => store.menu.selectedCategory
  );

  const ALL_CATEGORY = {
    id: 0,
    code: "svi",
    name: "Svi motivi",
    icon: "images",
  } as ICategory;
  const ON_DISCOUNT = {
    id: -1,
    code: "akcija",
    name: "Na akciji",
    icon: "sparkles",
  } as ICategory;
  const allCategories = [ALL_CATEGORY, ON_DISCOUNT, ...categories];

  const findCategoryById = (id: number) => {
    return categories.find((c) => c.id === id) || ({} as ICategory);
  };
  const findCategoryByCode = (code: string) => {
    return categories.find((c) => c.code === code) || ({} as ICategory);
  };

  const dispatch = useDispatch();

  const getCategories = () => getCategoriesApi(dispatch);
  const selectCategory = (category: ICategory) =>
    dispatch({ type: SELECT_CATEGORY, category });

  const selectCategoryByCode = (code: string) => {
    const category = findCategoryByCode(code);
    if (category?.id) dispatch({ type: SELECT_CATEGORY, category });
  };

  const categoryName = (id: number) => {
    const category = allCategories.find((c) => c.id === id);
    return category ? category.name : "";
  };

  const loadCategories = () => {
    if (categories.length === 0) {
      getCategories();
    }
  };

  return {
    loadCategories,
    categories,
    selectedCategory,
    allCategories,
    selectCategory,
    selectCategoryByCode,
    findCategoryById,
    categoryName,
  };
};
