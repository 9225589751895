import * as actions from "./menu.actions";
import { ICategory } from "../../models";
import { MenuActions } from "./menu.actions";

export type LoadedStatus = "loading" | "loaded" | "error";

interface IMenuReducerState {
  categories: ICategory[];
  selectedCategory?: ICategory;
}

const initialState: IMenuReducerState = {
  categories: [] as ICategory[],
} as IMenuReducerState;

export const menuReducer = (
  state: IMenuReducerState = initialState,
  action: MenuActions
): IMenuReducerState => {
  switch (action.type) {
    case actions.LOAD_CATEGORIES:
      return {
        ...state,
        categories: action.categories,
      };
    case actions.SELECT_CATEGORY:
      return {
        ...state,
        selectedCategory: action.category,
      };

    default:
      return state;
  }
};
