import React, { useEffect, useState } from "react";
import {
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonMenuToggle,
  IonPage,
  IonReorder,
  IonReorderGroup,
  IonRow,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { ellipse, menu, save } from "ionicons/icons";
import { useCommon } from "../../../common/useCommon";
import { usePictures } from "../../Pictures/usePictures";
import "./Reorder.css";

const Reorder: React.FC = () => {
  useCommon();
  const { allPictures, getAllPictures, updatePictureOrders } = usePictures();

  const array = allPictures;

  const [saveActive, setSaveActive] = useState<boolean>(false);

  useEffect(() => {
    if (allPictures.length === 0) {
      getAllPictures();
    }
  }, []);

  const itemReorder = (event: any) => {
    // console.log(array[event.detail.from]);
    // console.log(array[event.detail.to]);

    setSaveActive(true);

    const draggedItem = array.splice(event.detail.from, 1)[0];
    array.splice(event.detail.to, 0, draggedItem);
    event.detail.complete();

    // console.log(array);
  };

  const saveReorder = () => {
    const saveData = array.map((a) => a.code);
    updatePictureOrders(saveData);
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="warning">
          <IonButtons slot="start">
            <IonMenuToggle>
              <IonIcon icon={menu} style={{ fontSize: 28 }} />
            </IonMenuToggle>
          </IonButtons>
          <IonTitle>Ažuriranje redosleda</IonTitle>
          <IonButtons slot="end">
            <IonButton disabled={!saveActive} onClick={saveReorder}>
              <IonIcon icon={save} style={{ fontSize: 28 }} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <IonGrid className="ion-no-padding">
          <IonRow>
            <IonCol size="12" offsetMd="4" sizeMd="4">
              <IonReorderGroup disabled={false} onIonItemReorder={itemReorder}>
                {array.map((pic) => (
                  <IonItem key={pic.id}>
                    <div className="reorder-item">
                      <img
                        src={`assets/pics/320/${pic.code}.jpg`}
                        alt={pic.fileName}
                        className="reorder-img"
                      />
                    </div>
                    <IonLabel>{pic.code}</IonLabel>
                    <IonIcon
                      icon={ellipse}
                      style={{
                        fontSize: 16,
                        paddingRight: 5,
                        color: pic.status
                          ? "var(--ion-color-success-shade)"
                          : "var(--ion-color-danger-shade)",
                      }}
                    />
                    <IonReorder></IonReorder>
                  </IonItem>
                ))}
              </IonReorderGroup>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default Reorder;
