import * as actions from "./cart.actions";
import { CartActions } from "./cart.actions";

export type OrderStatus = "saving" | "saved" | "error";

export interface IPictureQuantity {
  [pictureId: number]: number;
}

interface ICartReducerState {
  page: number;
  cart: IPictureQuantity;
  reserve: IPictureQuantity;
  easelWood: number;
  easelAlum: number;
  frames: number;
  framesAssembled: number;
  framesAsPictures: boolean;
  framesAssembledAsPictures: boolean;
  notifyCart: boolean;
}

const initialState: ICartReducerState = {
  page: 1,
  cart: {},
  reserve: {},
  easelWood: 0,
  easelAlum: 0,
  frames: 0,
  framesAssembled: 0,
  framesAsPictures: false,
  framesAssembledAsPictures: false,
  notifyCart: false,
} as ICartReducerState;

export const cartReducer = (
  state: ICartReducerState = initialState,
  action: CartActions
): ICartReducerState => {
  switch (action.type) {
    case actions.LOAD_CART:
      return { ...state, cart: action.cart };

    case actions.LOAD_RESERVE:
      return { ...state, reserve: action.reserve };

    case actions.LOAD_EASEL_WOOD:
      return { ...state, easelWood: action.number };

    case actions.LOAD_EASEL_ALUM:
      return { ...state, easelAlum: action.number };

    case actions.LOAD_FRAMES:
      return { ...state, frames: action.frames };

    case actions.LOAD_FRAMES_ASSEMBLED:
      return { ...state, framesAssembled: action.framesAssembled };

    case actions.SET_CART_PAGE:
      return { ...state, page: action.page };

    case actions.CHANGE_CART:
      return {
        ...state,
        cart: { ...state.cart, [action.pictureId]: action.quantity },
        notifyCart: true,
      };

    case actions.CHANGE_RESERVE:
      return {
        ...state,
        reserve: { ...state.reserve, [action.pictureId]: action.quantity },
      };

    case actions.CHANGE_EASEL_WOOD_NUMBER:
      return { ...state, easelWood: action.quantity, notifyCart: true };

    case actions.CHANGE_EASEL_ALUM_NUMBER:
      return { ...state, easelAlum: action.quantity, notifyCart: true };

    case actions.CHANGE_FRAME_NUMBER:
      return { ...state, frames: action.quantity, notifyCart: true };

    case actions.CHANGE_FRAME_ASSEMBLED_NUMBER:
      return { ...state, framesAssembled: action.quantity, notifyCart: true };

    case actions.HIDE_TOAST_CART_NOTIFICATION:
      return {
        ...state,
        notifyCart: false,
      };

    case actions.RESET_CART:
      return {
        ...state,
        cart: {},
        reserve: {},
        easelWood: 0,
        easelAlum: 0,
        frames: 0,
        framesAssembled: 0,
        framesAsPictures: false,
        framesAssembledAsPictures: false,
      };

    case actions.FRAMES_AS_PICTURES:
      return { ...state, framesAsPictures: action.flag };

    case actions.FRAMES_ASSEMBLED_AS_PICTURES:
      return { ...state, framesAssembledAsPictures: action.flag };

    default:
      return state;
  }
};
