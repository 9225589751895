import {
  IonCard,
  IonCol,
  IonGrid,
  IonIcon,
  IonLabel,
  IonRow,
} from "@ionic/react";
import { addCircle, easel, removeCircle } from "ionicons/icons";
import { useCart } from "./useCart";
import { NumberMoney } from "../../components/NumberMoney/NumberMoney";
import SubHeader from "../FrameAndEasel/SubHeader";
import { PRICE_EASEL_ALUMINIUM } from "../../common/constants";
import { usePictures } from "../Pictures/usePictures";

const EaselAluminium = () => {
  const { easelAlum, changeEaselAlumNumber } = useCart();

  const { equipmentState } = usePictures();
  const disabled = !equipmentState.easelA;

  return (
    <IonCard style={{ margin: "3px", padding: "13px 16px 0 16px" }}>
      <SubHeader
        icon={easel}
        text="Aluminijumski štafelaj"
        onTop
        disabled={disabled}
      >
        <NumberMoney value={PRICE_EASEL_ALUMINIUM} />
      </SubHeader>
      <br />

      {!disabled && (
        <IonGrid>
          <IonRow>
            <IonCol className="ion-text-right">
              <IonIcon
                className={easelAlum > 0 ? "cursorP" : "op5"}
                icon={removeCircle}
                size="large"
                color={easelAlum > 0 ? "primary" : "var(--ion-color-light)"}
                onClick={() => {
                  if (easelAlum > 0) {
                    changeEaselAlumNumber(easelAlum - 1);
                  }
                }}
              />
            </IonCol>
            <IonCol className="ion-text-center f20 b" style={{ paddingTop: 7 }}>
              <IonLabel>{easelAlum}</IonLabel>
            </IonCol>
            <IonCol className="ion-text-left">
              <IonIcon
                className={"cursorP"}
                color={"primary"}
                icon={addCircle}
                size="large"
                onClick={() => changeEaselAlumNumber(easelAlum + 1)}
              />
            </IonCol>
          </IonRow>
        </IonGrid>
      )}
      {disabled && (
        <div className="b f15 ion-text-center" style={{ marginBottom: 12 }}>
          <IonLabel>Trenutno nema na stanju</IonLabel>
        </div>
      )}
    </IonCard>
  );
};

export default EaselAluminium;
