import { FC } from "react";

interface Props {
  price: number;
  priceNoDiscount?: number;
  isPopup?: boolean;
}
export const DiscountBadge: FC<Props> = ({
  price,
  priceNoDiscount,
  isPopup,
}) => (
  <>
    {!!priceNoDiscount && priceNoDiscount > price && (
      <div
        style={{
          backgroundColor: "var(--ion-color-danger)",
          borderRadius: 3,
          color: "#fff",
          fontWeight: 600,
          fontSize: isPopup ? "20px" : "14px",
          padding: isPopup ? "4px 8px" : "3px 5px",
          position: "absolute",
          top: 0,
          left: 0,
          zIndex: 10,
        }}
      >
        {`${Math.round(((priceNoDiscount - price) / priceNoDiscount) * 100)}%`}
      </div>
    )}
  </>
);
