import { IonButton, IonIcon, IonLabel } from "@ionic/react";
import { arrowDownOutline, closeOutline } from "ionicons/icons";
import { FC, useState } from "react";
import { useCommon } from "../../../common/useCommon";
import { Flex } from "../../../components/Flex/Flex";
import { IPicture } from "../../../models";
import { usePictures } from "../../Pictures/usePictures";

interface PriceEditProps {
  pic: IPicture;
}

export const PriceEdit: FC<PriceEditProps> = ({ pic }) => {
  const { darkMode } = useCommon();
  const { updatePicturePrices } = usePictures();

  const inputStyle = {
    backgroundColor: !darkMode
      ? "var(--ion-color-dark)"
      : "var(--ion-color-light)",
    border: "2px solid #8888",
    color: darkMode ? "var(--ion-color-dark)" : "var(--ion-color-light)",
    lineHeight: "10px",
    margin: 5,
    outline: "none",
    padding: 3,
    width: 100,
  };

  const [price, setPrice] = useState<number>(pic.price);
  const [priceNoDiscount, setPriceNoDiscount] = useState<number>(
    pic.priceNoDiscount
  );
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [hasChanges, setHasChanges] = useState<boolean>(false);

  const cancelEdit = () => {
    setPrice(pic.price);
    setPriceNoDiscount(pic.priceNoDiscount);
    setIsEditMode(false);
    setHasChanges(false);
  };

  const changePrice = (value: number) => {
    setHasChanges(true);
    setPrice(value);
  };

  const changePriceNoDicsount = (value: number) => {
    setHasChanges(true);
    setPriceNoDiscount(value);
  };

  const clearNoDicount = () => {
    setPriceNoDiscount(0);
    setHasChanges(true);
  };

  const takeNoDiscountPrice = () => {
    setPrice(priceNoDiscount || 0);
    clearNoDicount();
    setHasChanges(true);
  };

  const save = async () => {
    const newPrice = !price && price == null ? 0 : price;
    const newPriceNo = isNaN(priceNoDiscount) ? 0 : priceNoDiscount;
    if (await updatePicturePrices(pic.id, newPrice, newPriceNo)) {
      setIsEditMode(false);
      setHasChanges(false);
    }
  };

  return (
    <>
      <div style={{ textAlign: "center" }}>
        <IonLabel>Cena bez popusta</IonLabel>
        <br />
        <input
          disabled={!isEditMode}
          type="number"
          required={true}
          value={priceNoDiscount || ""}
          onChange={(e: any) => changePriceNoDicsount(e.target.value)}
          style={{
            ...inputStyle,
            fontWeight: "bold",
            textAlign: "center",
          }}
        />
        <IonButton
          disabled={!isEditMode}
          size="small"
          style={{ marginTop: -5 }}
          onClick={clearNoDicount}
        >
          <IonIcon icon={closeOutline} />
        </IonButton>
        <br />
        <IonLabel>Cena</IonLabel>
        <br />
        <input
          disabled={!isEditMode}
          type="number"
          required={true}
          value={price || ""}
          onChange={(e: any) => changePrice(e.target.value)}
          style={{
            ...inputStyle,
            fontWeight: "bold",
            textAlign: "center",
          }}
        />
        <IonButton
          disabled={!isEditMode}
          size="small"
          style={{ marginTop: -5 }}
          onClick={takeNoDiscountPrice}
        >
          <IonIcon icon={arrowDownOutline} />
        </IonButton>
      </div>
      {!isEditMode && (
        <IonButton
          expand="block"
          color={!!pic.priceNoDiscount ? "warning" : "primary"}
          onClick={() => setIsEditMode(true)}
        >
          Izmeni cenu
        </IonButton>
      )}
      {isEditMode && (
        <Flex css={{ justifyContent: "space-evenly" }}>
          <IonButton
            color={"medium"}
            onClick={cancelEdit}
            style={{ width: "45%" }}
          >
            Cancel
          </IonButton>
          <IonButton
            disabled={!hasChanges}
            color={"success"}
            style={{ width: "45%" }}
            onClick={save}
          >
            Snimi
          </IonButton>
        </Flex>
      )}
    </>
  );
};
