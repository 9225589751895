import { IPicture } from "../../models";
import { ActionType } from "../../util/types";

export const LOAD_ALL_PICTURES = "LOAD_ALL_PICTURES";
export const LOAD_ALL_PICTURES_ADMIN = "LOAD_ALL_PICTURES_ADMIN";
export const AVAILABLE_ONLY_CHANGE = "AVAILABLE_ONLY_CHANGE";
export const SELECT_PICTURE = "SELECT_PICTURE";
export const LOAD_FAVORITES = "LOAD_FAVORITES";
export const SET_FAVORITES = "SET_FAVORITES";
export const HIDE_TOAST_FAV_NOTIFICATION = "HIDE_TOAST_FAV_NOTIFICATION";
export const UPDATE_PICTURE_STATE = "UPDATE_PICTURE_STATE";
export const UPDATE_PICTURE_ISDELETED = "UPDATE_PICTURE_ISDELETED";
export const UPDATE_PICTURE_PRICES = "UPDATE_PICTURE_PRICES";

export const getAllPictures = (data: IPicture[]) =>
  ({
    type: LOAD_ALL_PICTURES,
    data,
  } as const);

export const getAllPicturesAdmin = (data: IPicture[]) =>
  ({
    type: LOAD_ALL_PICTURES_ADMIN,
    data,
  } as const);

export const setAvailableOnly = (available: boolean) =>
  ({
    type: AVAILABLE_ONLY_CHANGE,
    available,
  } as const);

export const selectPicture = (picture?: IPicture) =>
  ({
    type: SELECT_PICTURE,
    picture,
  } as const);

export const loadFavorites = (favorites: number[]) =>
  ({
    type: LOAD_FAVORITES,
    favorites,
  } as const);

export const setFavorites = (favorites: number[]) =>
  ({
    type: SET_FAVORITES,
    favorites,
  } as const);

export const hideToastNotifications = () =>
  ({
    type: HIDE_TOAST_FAV_NOTIFICATION,
  } as const);

export const updatePictureState = (pictureId: number, status: boolean) =>
  ({
    type: UPDATE_PICTURE_STATE,
    pictureId,
    status,
  } as const);

export const updatePictureIsDeleted = (pictureId: number, isDeleted: boolean) =>
  ({
    type: UPDATE_PICTURE_ISDELETED,
    pictureId,
    isDeleted,
  } as const);

export const updatePicturePrices = (
  pictureId: number,
  price: number,
  priceNoDiscount: number
) =>
  ({
    type: UPDATE_PICTURE_PRICES,
    pictureId,
    price,
    priceNoDiscount,
  } as const);

export type PicturesActions =
  | ActionType<typeof getAllPictures>
  | ActionType<typeof getAllPicturesAdmin>
  | ActionType<typeof setAvailableOnly>
  | ActionType<typeof selectPicture>
  | ActionType<typeof loadFavorites>
  | ActionType<typeof setFavorites>
  | ActionType<typeof hideToastNotifications>
  | ActionType<typeof updatePictureState>
  | ActionType<typeof updatePictureIsDeleted>
  | ActionType<typeof updatePicturePrices>;
