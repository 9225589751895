import React from "react";
import { arrowBack } from "ionicons/icons";
import { IPicture } from "../../../models";
import { usePictures } from "../../Pictures/usePictures";
import {
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonModal,
  IonRow,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import PictureLight from "./PictureLight";

interface Props {
  isOpen: boolean;
  close: () => void;
  selectPicture: (pic: IPicture) => void;
}

export const PickProduct: React.FC<Props> = ({
  isOpen,
  close,
  selectPicture,
}) => {
  const { allPictures } = usePictures();

  return (
    <IonModal isOpen={isOpen} onDidDismiss={close} showBackdrop={true}>
      <IonHeader>
        <IonToolbar color="danger">
          <IonButtons slot="start">
            <IonIcon
              className="f28"
              icon={arrowBack}
              onClick={close}
              style={{ paddingLeft: "5px" }}
            />
          </IonButtons>
          <IonTitle>Dodaj proizvod</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <IonGrid className="ion-no-padding">
          <IonRow>
            {allPictures.map((pic) => (
              <IonCol key={pic.id} size="4">
                <PictureLight pic={pic} selectPicture={selectPicture} />
              </IonCol>
            ))}
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonModal>
  );
};
