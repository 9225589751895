import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../pages/main.reducer";
import { CHANGE_DARK_MODE, SET_ZOOM } from "./common.actions";


export const useCommon = () => {
  const zoom = useSelector<AppState, number>(store => store.common.zoom);
  const darkMode = useSelector<AppState, boolean>(store => store.common.darkMode);

  const dispatch = useDispatch();

  const setZoomAction = (zoom: number) => dispatch({ type: SET_ZOOM, zoom });
  const setDarkMode = (darkMode: boolean) => dispatch({ type: CHANGE_DARK_MODE, darkMode });

  const zoomMax = window.innerWidth > 540 ? 5 : 2;
  const zoomMin = window.innerWidth > 540 ? 2 : 1;

  const setZoom = (zoom: number) => {
    const newZoom = zoom > zoomMax ? zoomMax : (zoom < zoomMin ? zoomMin : zoom);
    setZoomAction(newZoom);
    localStorage.setItem("bpb.zoom", newZoom.toString());
  }

  const getLocalDarkMode = (): boolean => {
    const storageString = localStorage.getItem("bpb.darkMode");
    return storageString ? JSON.parse(storageString) : false;
  }

  useEffect(() => {
    setDarkMode(getLocalDarkMode());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    zoom,
    setZoom,
    darkMode,
    setDarkMode,
    zoomMax,
    zoomMin,
  }
}