import React, { useEffect } from "react";
import {
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardSubtitle,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonMenuToggle,
  IonPage,
  IonRow,
  IonTitle,
  IonToggle,
  IonToolbar,
} from "@ionic/react";
import { ellipse, eye, eyeOff, menu } from "ionicons/icons";
import { usePictures } from "../../Pictures/usePictures";
import { useCommon } from "../../../common/useCommon";

const Availability = () => {
  useCommon();
  const {
    allPicturesAdmin,
    getAllPicturesAdmin,
    updatePictureState,
    updatePictureIsDeleted,
  } = usePictures();

  const toggleState = (pictureId: number, status: boolean) => {
    updatePictureState(pictureId, status);
  };

  const toggleIsDeleted = (pictureId: number, isDeleted: boolean) => {
    updatePictureIsDeleted(pictureId, isDeleted);
  };

  useEffect(() => {
    if (allPicturesAdmin.length === 0) getAllPicturesAdmin();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="warning">
          <IonButtons slot="start">
            <IonMenuToggle>
              <IonIcon icon={menu} style={{ fontSize: 28 }} />
            </IonMenuToggle>
          </IonButtons>
          <IonTitle>Ažuriranje stanja</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonGrid>
          <IonRow>
            {allPicturesAdmin
              .sort((a, b) => (a.code > b.code ? 1 : -1))
              .map((pic) => (
                <IonCol key={pic.id} sizeSm="3" sizeXs="4">
                  <IonCard style={{ margin: 0 }}>
                    <picture>
                      <source
                        media="(min-width:650px)"
                        srcSet={`assets/pics/${pic.code}.jpg`}
                      />
                      <img
                        src={`assets/pics/320/${pic.code}.jpg`}
                        alt={pic.fileName}
                        style={{ opacity: pic.isDeleted ? 0.2 : 1 }}
                      />
                    </picture>
                    <IonCardContent style={{ padding: 3 }}>
                      <IonCardSubtitle
                        style={{ fontSize: 12, textAlign: "center" }}
                      >
                        <IonLabel>{pic.code}</IonLabel>
                      </IonCardSubtitle>
                      {/* <div className="vertical-center">
                      <IonIcon icon={ellipse} style={{
                        paddingRight: 5,
                        color: pic.status ? "var(--ion-color-success-shade)" : "var(--ion-color-danger-shade)",
                        fontSize: 18,
                      }} />
                      <IonText style={{ fontSize: 12, lineHeight: "30px" }}>{pic.status ? "Na stanju" : "Trenutno nema na stanju"}</IonText>
                    </div>
                    <div className="vertical-center ion-text-right" style={{ width: "100%", textAlign: "right" }}>
                      <IonText slot="end">Ukloni</IonText>
                      <IonToggle slot="end" checked={true} color="danger" />
                    </div> */}

                      <IonItem className="ion-no-padding">
                        <IonIcon
                          icon={ellipse}
                          style={{
                            padding: "0 5px",
                            color: pic.status
                              ? "var(--ion-color-success-shade)"
                              : "var(--ion-color-danger-shade)",
                            fontSize: 18,
                          }}
                        />
                        <IonLabel>Na stanju</IonLabel>
                        <IonToggle
                          checked={pic.status}
                          onClick={() => toggleState(pic.id, pic.status)}
                        />
                      </IonItem>

                      <IonItem className="ion-no-padding">
                        {pic.isDeleted ? (
                          <IonIcon
                            icon={eyeOff}
                            style={{
                              padding: "0 5px",
                              color: "var(--ion-color-danger-shade)",
                              fontSize: 18,
                            }}
                          />
                        ) : (
                          <IonIcon
                            icon={eye}
                            style={{
                              padding: "0 5px",
                              color: "var(--ion-color-success-shade)",
                              fontSize: 18,
                            }}
                          />
                        )}
                        <IonLabel>Vidljiva</IonLabel>
                        <IonToggle
                          checked={!pic.isDeleted}
                          onClick={() => toggleIsDeleted(pic.id, pic.isDeleted)}
                        />
                      </IonItem>
                    </IonCardContent>
                  </IonCard>
                </IonCol>
              ))}
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default Availability;
