import axios from "axios";
import appconfig from '../../../AppConfig';
import { debug } from "../../../common/utils";
import { ICart } from "../../../models";
import { NEW_ORDER } from "../cart.actions";

export const orderApi = async (
  dispatch: any,
  newCart: ICart,
  result: (cartID: number) => void
) => {
  const url = "cart/order";
  const data = newCart;
  const config = {
    baseURL: appconfig.baseURL,
  }

  await axios.post(url, data, config)
    .then((response: any) => {
      debug("!!! API - cart/order !!!");

      dispatch({
        type: NEW_ORDER,
      });
      result(response.data);

      debug(response);
    })
    .catch((err: any) => {
      console.log(err);
    });
};