import React from "react";
import {
  IonButton,
  IonButtons,
  IonContent,
  IonFooter,
  IonHeader,
  IonIcon,
  IonModal,
  IonText,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { arrowBackCircle, cart } from "ionicons/icons";
import { Link } from "react-router-dom";

interface Props {
  show: boolean;
  dismiss: () => void;
  orderId: number;
}

export const OrderAccepted: React.FC<Props> = ({ show, dismiss, orderId }) => (
  <IonModal isOpen={show} onDidDismiss={dismiss}>
    <IonHeader>
      <IonToolbar>
        <IonButtons slot="start">
          <Link to="/" onClick={dismiss}>
            <IonIcon
              color="primary"
              className="f36"
              icon={arrowBackCircle}
              style={{ paddingLeft: "5px" }}
            />
          </Link>
        </IonButtons>
        <IonTitle>Porudžbina je prihvaćena</IonTitle>
      </IonToolbar>
    </IonHeader>

    <IonContent className="ion-text-center ion-align-items-center">
      <div
        style={{
          height: "70%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div style={{ width: "100%" }}>
          <div style={{ padding: 20 }}>
            <IonText>Vaša porudžbina je prihvaćena.</IonText>
            <br />
            <IonText>Hvala na poverenju.</IonText>
          </div>
          <div style={{ padding: 20 }}>
            <IonIcon icon={cart} style={{ fontSize: 100 }} />
          </div>
          <div style={{ padding: 20 }}>
            <IonText>Broj Vaše porudžbine: {orderId + 5000}</IonText>
          </div>
        </div>
      </div>

      <IonFooter>
        <Link to="/" onClick={dismiss}>
          <IonButton>Nazad na početak</IonButton>
        </Link>
      </IonFooter>
    </IonContent>
  </IonModal>
);
