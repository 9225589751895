import { IonCardSubtitle } from "@ionic/react";
import { FC } from "react";
import { NumberMoney } from "../NumberMoney/NumberMoney";

interface Props {
  price: number;
  priceNoDiscount?: number;
  isPopup?: boolean;
}

export const Price: FC<Props> = ({ price, priceNoDiscount, isPopup }) => (
  <>
    {!!priceNoDiscount && priceNoDiscount > price && (
      <IonCardSubtitle
        style={{
          color: "var(--ion-color-danger)",
          textDecoration: "line-through",
        }}
      >
        <NumberMoney
          value={priceNoDiscount}
          style={{ fontSize: isPopup ? 16 : 14 }}
        />
      </IonCardSubtitle>
    )}
    {price && (
      <IonCardSubtitle>
        <NumberMoney
          value={price}
          style={{ fontSize: isPopup ? 18 : 15, fontWeight: 600 }}
        />
      </IonCardSubtitle>
    )}
  </>
);
