import React, { useEffect } from "react";
import {
  IonContent,
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonGrid,
  IonRow,
  IonCol,
  IonIcon,
  IonButtons,
  IonMenuToggle,
  IonToast,
} from "@ionic/react";
import { menu } from "ionicons/icons";
import PictureCard from "./PictureCard";
import "../../theme/common.css";
import { debug } from "../../common/utils";
import PicturePreview from "./PicturePreview";
import { usePictures } from "./usePictures";
import InfoMessage from "../../components/InfoMessage/InfoMessage";
import appconfig from "../../AppConfig";
import { InfoKids } from "../../components/InfoMessage/InfoKids";
import ToolbarIcons from "../../components/ToolbarIcons/ToolbarIcons";
import {
  ADDITIONAL_EQUIPMENT_CATEGORY,
  DELIVERY_MESSAGE,
} from "../../common/constants";
import { useParams } from "react-router";
import { useCommon } from "../../common/useCommon";
import { useCategories } from "./useCategories";
import { useCart } from "../Cart/useCart";

const Pictures = () => {
  const { categoryCode } = useParams<{ categoryCode: string }>();

  const { notifyCart, loadCart, hideNotificationCart } = useCart();
  const { allCategories, categories, selectedCategory, selectCategoryByCode } =
    useCategories();
  const { zoom } = useCommon();
  const {
    allPictures,
    availableOnly,
    notifyFavorites,
    getAllPictures,
    loadFavorites,
    hideNotificationFav,
  } = usePictures();

  const getLocalFavorites = (): number[] => {
    const storageString = localStorage.getItem("bpb.pictures.favorites");
    return storageString ? JSON.parse(storageString) : ([] as number[]);
  };
  const getLocalCart = () => {
    const storageString = localStorage.getItem("bpb.cart");
    return storageString ? JSON.parse(storageString) : {};
  };

  useEffect(() => {
    if (allPictures.length === 0) {
      getAllPictures();
    }

    debug("LOAD DEFAULTS");
    loadFavorites(getLocalFavorites());
    loadCart(getLocalCart());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    selectCategoryByCode(categoryCode);
  }, [categories]);

  const categoryName = React.useMemo(() => {
    return allCategories.find((c) => c.code === categoryCode)?.name || "";
  }, [allCategories, categoryCode]);

  // const selectedCategory = React.useMemo((): number => {
  //   const category = categories.find((c) => c.code === categoryCode);
  //   return category ? category.id : 0;
  // }, [categories, categoryCode]);

  const picturesFiltered = () =>
    categoryCode === "akcija"
      ? allPictures
          .filter(
            (p) =>
              p.price !== p.priceNoDiscount &&
              p.priceNoDiscount > 0 &&
              (!availableOnly || p.status)
          )
          .sort((a, b) =>
            (a.priceNoDiscount - a.price) / a.priceNoDiscount <
            (b.priceNoDiscount - b.price) / b.priceNoDiscount
              ? 1
              : -1
          )
      : allPictures.filter(
          (p) =>
            p.category !== ADDITIONAL_EQUIPMENT_CATEGORY &&
            (p.category === selectedCategory?.id ||
              selectedCategory?.id === 0 ||
              selectedCategory?.id === undefined) &&
            (!availableOnly || p.status)
        );

  const pics =
    categoryCode === "svi"
      ? picturesFiltered().sort(() => Math.random() - 0.5)
      : picturesFiltered();

  return (
    <IonPage id="picatures">
      <IonHeader>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonMenuToggle>
              <IonIcon icon={menu} style={{ fontSize: 28 }} />
            </IonMenuToggle>
          </IonButtons>
          <IonTitle>{categoryName}</IonTitle>
          <ToolbarIcons />
        </IonToolbar>
      </IonHeader>
      <IonContent>
        {appconfig.showDeliveryInfoNotification && (
          <InfoMessage text={DELIVERY_MESSAGE} />
        )}
        {selectedCategory?.code === "najmladji" && (
          <InfoMessage content={<InfoKids />} />
        )}
        {/* <div>{JSON.stringify({ categoryCode })}</div>
        <div>{JSON.stringify({ picturesFiltered })}</div>
        <div>{JSON.stringify({ selectedCategory })}</div> */}
        <PicturePreview />

        <IonGrid className="ion-no-padding">
          <IonRow>
            {pics.map((pic) => (
              // <IonCol key={pic.id} sizeLg={(12 / zoom).toString()} sizeMd={(12 / zoom > 3 ? 3 : zoom).toString()} sizeSm={(12 / zoom > 3 ? 3 : zoom).toString()}>
              // <IonCol key={pic.id} sizeLg={(12 / zoom).toString()} sizeMd="6" sizeSm="12">
              // <IonCol key={pic.id} sizeXl={(12 / zoom).toString()} sizeLg={(12 / zoom).toString()} sizeMd={(12 / zoom).toString()} sizeSm="12" sizeXs="12">
              // <IonCol key={pic.id} size={(12 / zoom).toString()}>
              <IonCol
                key={pic.id}
                sizeSm={(12 / zoom).toString()}
                sizeXs={(12 / (zoom > 2 ? 2 : zoom)).toString()}
              >
                <PictureCard pic={pic} />
              </IonCol>
            ))}
          </IonRow>
        </IonGrid>
      </IonContent>

      <IonToast
        isOpen={notifyFavorites}
        onDidDismiss={hideNotificationFav}
        message={"Omiljene slike su ažurirane."}
        position="bottom"
        duration={3000}
        buttons={[
          {
            text: "OK",
            role: "cancel",
            handler: () => {
              debug("Cancel clicked");
            },
          },
        ]}
      />
      <IonToast
        isOpen={notifyCart}
        onDidDismiss={hideNotificationCart}
        message={"Korpa za naručivanje ažurirana."}
        position="bottom"
        duration={3000}
        buttons={[
          {
            text: "OK",
            role: "cancel",
            handler: () => {
              debug("Cancel clicked");
            },
          },
        ]}
      />
    </IonPage>
  );
};

export default Pictures;
