import * as actions from "./admin.actions";
import { ICart, ICartItem } from "../../models";
import { AdminActions } from "./admin.actions";

export interface IOrderItems {
  [cartId: number]: ICartItem[];
}

export type TOrdersTab = "new-orders" | "done-manual" | "slanje-paketa";

interface IAdminReducerState {
  tab: TOrdersTab;
  isLoadingTab: boolean;
  cartOrders: ICart[];
  hasMore: boolean;
  total: number;
  orderItems: IOrderItems;
  selectedCart?: ICart;
  isLoggedIn: boolean;
}

const initialState: IAdminReducerState = {
  tab: "new-orders",
  isLoadingTab: false,
  cartOrders: [] as ICart[],
  hasMore: true,
  total: 0,
  orderItems: {},
} as IAdminReducerState;

export const adminReducer = (
  state: IAdminReducerState = initialState,
  action: AdminActions
): IAdminReducerState => {
  switch (action.type) {
    case actions.CHANGE_TAB:
      return {
        ...state,
        tab: action.tab,
        cartOrders: [],
        hasMore: true,
        isLoadingTab: true,
      };

    case actions.SET_LOGGED_IN:
      return {
        ...state,
        isLoggedIn: action.isLoggedIn,
      };

    case actions.GET_CART_ORDERS_PAGED:
      return {
        ...state,
        cartOrders: [...state.cartOrders, ...action.data],
        hasMore: action.data.length === action.take,
        isLoadingTab: false,
        total: action.total,
      };

    case actions.GET_CART_ORDERS_PAGED_REFRESH:
      return {
        ...state,
        cartOrders: action.data,
        hasMore: action.data.length === action.take,
        isLoadingTab: false,
        total: action.total,
      };

    case actions.GET_CART_ORDER_ITEMS:
      return {
        ...state,
        orderItems: { ...state.orderItems, [action.cartId]: action.data },
      };

    case actions.SELECT_CART:
      return {
        ...state,
        selectedCart: action.cart,
      };

    case actions.UPDATE_CART:
      return {
        ...state,
        selectedCart: action.editedCart,
      };

    case actions.UPDATE_CART_SENT:
      if (state.selectedCart) {
        const cartOrder = state.cartOrders.find((c) => c.id === action.cart.id);
        const selectedCart = {
          ...state.selectedCart,
          package_sent: !action.cart.package_sent,
        };
        if (cartOrder) {
          return {
            ...state,
            cartOrders: [
              ...state.cartOrders.filter((c) => c.id !== action.cart.id),
            ],
            selectedCart,
          };
        }
        return {
          ...state,
          cartOrders: [...state.cartOrders, selectedCart].sort(
            (a, b) => b.id - a.id
          ),
          selectedCart,
        };
      }
      return state;

    case actions.UPDATE_CART_SENT_2_SP:
      if (state.selectedCart) {
        const cartOrder = state.cartOrders.find((c) => c.id === action.cart.id);
        const selectedCart = {
          ...state.selectedCart,
          package_sent_SP: !action.cart.package_sent_SP,
        };
        if (cartOrder) {
          return {
            ...state,
            cartOrders: [
              ...state.cartOrders.filter((c) => c.id !== action.cart.id),
            ],
            selectedCart,
          };
        }
        return {
          ...state,
          cartOrders: [...state.cartOrders, selectedCart].sort(
            (a, b) => b.id - a.id
          ),
          selectedCart,
        };
      }
      return state;

    case actions.UPDATE_CART_HANDLED:
      const cartOrder = state.cartOrders.find((c) => c.id === action.cart.id);
      if (cartOrder) {
        return {
          ...state,
          cartOrders: [
            ...state.cartOrders.filter((c) => c.id !== action.cart.id),
            { ...cartOrder, is_handled: !cartOrder.is_handled },
          ].sort((a, b) => b.id - a.id),
        };
      }
      return state;

    default:
      return state;
  }
};
