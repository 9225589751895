import axios, { AxiosRequestConfig } from "axios";
import appconfig from "../../../AppConfig";
import { debug } from "../../../common/utils";
import { LOAD_CATEGORIES } from "../menu.actions";

export const getCategoriesApi = async (
    dispatch: any,
) => {
    // dispatch({ type: LOAD_ALL_PICTURES, newStatus: "loading" });

    const config = {
        baseURL: appconfig.baseURL,
    } as AxiosRequestConfig;

    await axios.get("/products/getCategories", config)
        .then((response: any) => {
            debug("!!! API - getCategories !!!");

            dispatch({
                type: LOAD_CATEGORIES,
                categories: response.data
            });

            // if (response.status === 200) {
            //     dispatch({ type: LOAD_ALL_PICTURES, newStatus: "loaded" });
            // }

            debug(response);
        })
        .catch((err: any) => {
            console.log(err);
            // dispatch({ type: LOAD_STATUS, newStatus: "error" });
        });
};