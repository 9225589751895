import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import appconfig from "../../AppConfig";
import { debug } from "../../common/utils";
import { ICart, ICartItem } from "../../models";
import { AppState } from "../main.reducer";
import { CHANGE_TAB, SELECT_CART, SET_LOGGED_IN } from "./admin.actions";
import { IOrderItems, TOrdersTab } from "./admin.reducer";
import { getCartOrderItemsApi } from "./CartOrders/api/getCartOrderItemsApi";
import { getCartOrdersPagedApi } from "./CartOrders/api/getCartOrdersPagedApi";
import { updateCartApi } from "./CartOrders/api/updateCartApi";
import { updateCartSentApi } from "./CartOrders/api/updateCartSentApi";
import { updateCartHandledApi } from "./CartOrders/api/updateCartHandledApi";

export const useAdmin = () => {
  const isLoggedIn =
    useSelector<AppState, boolean>((store) => store.admin.isLoggedIn) ||
    appconfig.admin;
  const cartTab = useSelector<AppState, string>((store) => store.admin.tab);
  const isLoadingTab = useSelector<AppState, boolean>(
    (store) => store.admin.isLoadingTab
  );
  const cartOrders = useSelector<AppState, ICart[]>(
    (store) => store.admin.cartOrders
  );
  const hasMore = useSelector<AppState, boolean>(
    (store) => store.admin.hasMore
  );
  const total = useSelector<AppState, number>((store) => store.admin.total);
  const orderItems = useSelector<AppState, IOrderItems>(
    (store) => store.admin.orderItems
  );
  const selectedCart = useSelector<AppState, ICart | undefined>(
    (store) => store.admin.selectedCart
  );

  const selectedOrderItems = useMemo(() => {
    return orderItems && selectedCart
      ? orderItems[selectedCart.id]
      : ([] as ICartItem[]);
  }, [orderItems, selectedCart]);

  const dispatch = useDispatch();

  const changeTab = (tab: TOrdersTab) => dispatch({ type: CHANGE_TAB, tab });

  const setIsLoggedIn = (isLoggedIn: boolean) =>
    dispatch({ type: SET_LOGGED_IN, isLoggedIn });

  const getCartOrdersPaged = (
    tab: string,
    skip: number,
    take: number,
    refresh?: boolean
  ) => getCartOrdersPagedApi(dispatch, tab, skip, take, refresh);

  const getOrderItems = () => {
    if (selectedCart) {
      getCartOrderItemsApi(dispatch, selectedCart!.id);
    }
  };

  const setSelectedCart = async (cart?: ICart) => {
    dispatch({ type: SELECT_CART, cart });
  };

  const toggleCartSent = (cart: ICart, isSP: boolean = false) =>
    updateCartSentApi(dispatch, cart, isSP);

  const toggleCartHandled = (cart: ICart) =>
    updateCartHandledApi(dispatch, cart, !cart.is_handled);

  const saveCartEdit = async (
    cart: ICart | undefined,
    cartItems: ICartItem[]
  ) => {
    if (!cart) {
      return false;
    }
    const editedCart = { ...cart, cartItems };

    if (await updateCartApi(dispatch, editedCart)) {
      debug("Update Cart SUCCESS!");
      return true;
    } else {
      debug("Update Cart FAIL!");
      return false;
    }
  };

  return {
    isLoggedIn,
    setIsLoggedIn,
    cartTab,
    isLoadingTab,
    cartOrders,
    hasMore,
    total,
    changeTab,
    getCartOrdersPaged,
    getOrderItems,
    selectedCart,
    setSelectedCart,
    selectedOrderItems,
    toggleCartSent,
    toggleCartHandled,
    saveCartEdit,
  };
};
