import { IonIcon, IonText } from "@ionic/react";
import { informationCircle } from "ionicons/icons";
import { useCart } from "./useCart";
import styled from "styled-components";

const NotificationWrap = styled.div`
  margin: 10px auto 0;
  padding: 4px;
  text-align: center;
`;

const MyChip = styled.div`
  background-color: var(--ion-color-warning-shade);
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
`;

const Text = styled(IonText)`
  color: var(--ion-color-light);
  font-weight: 600;
  margin-left: 10px;
`;

const FramesNumberWarning: React.FC = () => {
  const { frames, framesAssembled, cartPictures } = useCart();
  return cartPictures.length > 0 &&
    frames + framesAssembled > cartPictures.length ? (
    <NotificationWrap>
      <MyChip>
        <IonIcon
          icon={informationCircle}
          color="light"
          style={{ fontSize: 23 }}
        />
        <Text>
          Ukupan broj odabranih (blind) ramova je veći od broja odabranih
          motiva.
        </Text>
      </MyChip>
    </NotificationWrap>
  ) : null;
};

export default FramesNumberWarning;
