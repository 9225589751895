import * as actions from "./pictures.actions";
import { IPicture } from "../../models";
import { PicturesActions } from "./pictures.actions";

export type LoadedStatus = "loading" | "loaded" | "error";

interface IPicturesReducerState {
  allPictures: IPicture[];
  allPicturesAdmin: IPicture[];
  availableOnly: boolean;
  selectedPicture?: IPicture;
  favorites: number[];
  notifyFavorites: boolean;
}

const initialState: IPicturesReducerState = {
  allPictures: [] as IPicture[],
  allPicturesAdmin: [] as IPicture[],
  availableOnly: false,
  favorites: [] as number[],
  notifyFavorites: false,
  notifyCart: false,
} as IPicturesReducerState;

export const picturesReducer = (
  state: IPicturesReducerState = initialState,
  action: PicturesActions
): IPicturesReducerState => {
  switch (action.type) {
    case actions.LOAD_ALL_PICTURES:
      return {
        ...state,
        allPictures: action.data,
      };

    case actions.LOAD_ALL_PICTURES_ADMIN:
      return {
        ...state,
        allPicturesAdmin: action.data,
      };

    case actions.AVAILABLE_ONLY_CHANGE:
      return {
        ...state,
        availableOnly: action.available,
      };

    case actions.SELECT_PICTURE:
      return {
        ...state,
        selectedPicture: action.picture,
      };

    case actions.LOAD_FAVORITES:
      return {
        ...state,
        favorites: action.favorites,
      };

    case actions.SET_FAVORITES:
      return {
        ...state,
        favorites: action.favorites,
        notifyFavorites: true,
      };

    case actions.HIDE_TOAST_FAV_NOTIFICATION:
      return {
        ...state,
        notifyFavorites: false,
      };

    case actions.UPDATE_PICTURE_STATE:
      const picture = state.allPicturesAdmin.find(
        (c) => c.id === action.pictureId
      );
      if (picture) {
        picture.status = action.status;
        return {
          ...state,
          allPicturesAdmin: [
            ...state.allPicturesAdmin.filter((c) => c.id !== action.pictureId),
            picture,
          ],
        };
      }
      return { ...state };

    case actions.UPDATE_PICTURE_ISDELETED:
      const pictureAdmin = state.allPicturesAdmin.find(
        (c) => c.id === action.pictureId
      );
      if (pictureAdmin) {
        pictureAdmin.isDeleted = action.isDeleted;
        return {
          ...state,
          allPicturesAdmin: [
            ...state.allPicturesAdmin.filter((c) => c.id !== action.pictureId),
            pictureAdmin,
          ],
        };
      }
      return { ...state };

    case actions.UPDATE_PICTURE_PRICES:
      return {
        ...state,
        allPicturesAdmin: state.allPicturesAdmin.map((p) =>
          p.id === action.pictureId
            ? {
                ...p,
                price: action.price,
                priceNoDiscount: action.priceNoDiscount,
              }
            : p
        ),
      };

    default:
      return state;
  }
};
