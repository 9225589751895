import React from "react";
import { Route } from "react-router-dom";
import {
  IonApp,
  IonRouterOutlet,
  IonSplitPane,
  setupIonicReact,
} from "@ionic/react";
import { IonReactHashRouter } from "@ionic/react-router";
import { createStore, applyMiddleware } from "redux";
import { Provider, connect } from "react-redux";
import { mainReducer, AppState } from "./pages/main.reducer";
import appconfig from "./AppConfig";
import logger from "redux-logger";
import Start from "./pages/Start/Start";
import AboutUs from "./pages/AboutUs/AboutUs";
import FrameAndEasel from "./pages/FrameAndEasel/FrameAndEasel";
import PackageContentPage from "./pages/PackageContent/PackageContentPage";
import Examples from "./pages/Examples/Examples";
import Menu from "./pages/Menu/Menu";
import Pictures from "./pages/Pictures/Pictures";
import Favorites from "./pages/Favorites/Favorites";
import Cart from "./pages/Cart/Cart";
import Availability from "./pages/Admin/Availability/Availability";
import Reorder from "./pages/Admin/Reorder/Reorder";
import CartOrders from "./pages/Admin/CartOrders/CartOrders";
import Prices from "./pages/Admin/Prices/Prices";
import Picture from "./pages/Picture/Picture";
import ReactGA from "react-ga4";
import { Login } from "./pages/Admin/Login/Login";
import { useAdmin } from "./pages/Admin/useAdmin";
import { createBrowserHistory } from "history";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";
import "./theme/common.css";

setupIonicReact({});

const mapStateToProps = (state: AppState) => ({
  darkMode: state.common.darkMode,
});

const mapDispatchToProps = (dispatch: (action: any) => any) => ({});

ReactGA.initialize("G-XY2FJ96HTV");

const history = createBrowserHistory();

// Run google analytics page view tracking on browser history change
history.listen((location) => {
  ReactGA.send({ hitType: "pageview", page: location.hash.replace("#/", "") });
});

type AllProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

const IonicApp = (props: AllProps) => {
  const { isLoggedIn } = useAdmin();

  return (
    <IonApp className={`${props.darkMode ? "dark-theme" : ""}`}>
      <IonReactHashRouter>
        <IonSplitPane contentId="main">
          <Menu />
          <IonRouterOutlet id="main">
            <Route path="/" component={Start} exact />
            <Route path="/o-nama" component={AboutUs} exact />
            <Route path="/paket" component={PackageContentPage} />
            <Route path="/ram" component={FrameAndEasel} />
            <Route path="/primeri" component={Examples} />
            <Route path="/slike" component={Pictures} />
            <Route path="/slika/:id" component={Picture} exact />
            <Route path="/korpa" component={Cart} />
            <Route path="/omiljene" component={Favorites} />
            <Route path="/slike/:categoryCode" component={Pictures} />
            <Route path="/admin$$$" component={Login} />
            <Route
              path="/adminOrders"
              component={isLoggedIn ? CartOrders : Login}
            />
            <Route
              path="/adminAvailability"
              component={isLoggedIn ? Availability : Login}
            />
            <Route
              path="/adminReorder"
              component={isLoggedIn ? Reorder : Login}
            />
            <Route
              path="/adminPrices"
              component={isLoggedIn ? Prices : Login}
            />
          </IonRouterOutlet>
        </IonSplitPane>
      </IonReactHashRouter>
    </IonApp>
  );
};

export const IonicAppConnected = connect(
  mapStateToProps,
  mapDispatchToProps
)(IonicApp);

const store = appconfig.debug
  ? createStore(mainReducer, applyMiddleware(logger))
  : createStore(mainReducer);

const App: React.FC = () => {
  return (
    <Provider store={store}>
      <IonicAppConnected />
    </Provider>
  );
};

export default App;
