import axios from "axios";
import appconfig from "../../../../AppConfig";
import { debug } from "../../../../common/utils";
import { ICart } from "../../../../models";
import { UPDATE_CART_SENT, UPDATE_CART_SENT_2_SP } from "../../admin.actions";

export const updateCartSentApi = async (
  dispatch: any,
  cart: ICart,
  isSP: boolean = false
) => {
  const sent = isSP ? !cart.package_sent_SP : !cart.package_sent;
  const url = `cart/updateCartSent/${cart.id}/${sent}/${isSP}`;
  const config = {
    baseURL: appconfig.baseURL,
  };

  await axios
    .post(url, null, config)
    .then((response) => {
      debug("!!! API - updateCartSentApi !!!");

      if (response && response.data) {
        if (isSP) {
          dispatch({
            type: UPDATE_CART_SENT_2_SP,
            cart: cart,
          });
        } else {
          dispatch({
            type: UPDATE_CART_SENT,
            cart: cart,
          });
        }
      }

      debug(response);
    })
    .catch((err) => {
      console.log(err);
      // alert(err);
    });
};
