import { IonButton, IonIcon } from "@ionic/react";
import { cart, cartOutline } from "ionicons/icons";

interface Props {
  disabled: boolean;
  onClick: () => void;
  textAdd?: string;
  textDisabled?: string;
}

const Add2Cart: React.FC<Props> = ({
  disabled,
  onClick,
  textAdd = "U korpu",
  textDisabled = "U korpi",
}) => {
  return (
    <IonButton
      color="success"
      disabled={disabled}
      expand="block"
      onClick={onClick}
    >
      <IonIcon icon={disabled ? cart : cartOutline} size="large" slot="start" />
      {disabled ? textDisabled : textAdd}
    </IonButton>
  );
};

export default Add2Cart;
