import { FC } from "react";
import { IPicture } from "../../../models";
import {
  IonCard,
  IonCardTitle,
  IonCardSubtitle,
  IonIcon,
  IonText,
  IonCardContent,
  IonGrid,
  IonRow,
  IonCol,
} from "@ionic/react";
import { ellipse } from "ionicons/icons";
import { Price } from "../../../components/Price/Price";
import { DiscountBadge } from "../../../components/DiscountBadge/DicsountBadge";

type Props = {
  pic?: IPicture;
  selectPicture: (pic: IPicture) => void;
};

const PictureCard: FC<Props> = ({ pic, selectPicture }) => {
  if (!pic) {
    return <></>;
  }

  return (
    <IonCard style={{ margin: "3px" }}>
      <picture style={{ cursor: "pointer" }}>
        <source
          media="(min-width:650px)"
          srcSet={`assets/pics/${pic.code}.jpg`}
        />
        <img
          src={`assets/pics/320/${pic.code}.jpg`}
          alt={pic.fileName}
          onClick={() => selectPicture(pic)}
          style={{ width: "100%" }}
          loading="lazy"
        />
      </picture>
      <DiscountBadge price={pic.price} priceNoDiscount={pic.priceNoDiscount} />

      <IonCardTitle
        className="ion-text-center f20xs"
        style={{ padding: "5px 0" }}
      >
        {pic.title}
      </IonCardTitle>

      <IonCardContent style={{ padding: 5 }}>
        <IonGrid>
          <IonRow>
            <IonCol>
              <IonCardSubtitle>
                <IonIcon
                  icon={ellipse}
                  style={{
                    paddingRight: 5,
                    color: pic.status
                      ? "var(--ion-color-success-shade)"
                      : "var(--ion-color-danger-shade)",
                  }}
                />
                <IonText>
                  {pic.status ? "Na stanju" : "Trenutno nema na stanju"}
                </IonText>
              </IonCardSubtitle>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol className="ion-text-right">
              <Price price={pic.price} priceNoDiscount={pic.priceNoDiscount} />
            </IonCol>
          </IonRow>
        </IonGrid>

        <IonGrid>
          <IonRow>
            <IonCol className="ion-text-right">
              <IonText>{`Šifra: ${pic.code}`}</IonText>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonCardContent>
    </IonCard>
  );
};

export default PictureCard;
