import React, { useMemo, useState } from "react";
import { IPicture, ICategory } from "../../models";
import {
  IonCard,
  IonCardTitle,
  IonCardSubtitle,
  IonIcon,
  IonText,
  IonCardContent,
  IonGrid,
  IonRow,
  IonCol,
  IonButton,
  IonChip,
  IonLabel,
} from "@ionic/react";
import { Link } from "react-router-dom";
import {
  ellipse,
  heart,
  heartOutline,
  cart as cartIcon,
  cartOutline,
  alarm,
  imageOutline,
  paw,
  flowerOutline,
  heartHalfOutline,
  images,
  earth,
  alarmOutline,
  accessibility,
  mail,
} from "ionicons/icons";
import { connect } from "react-redux";
import { AppState } from "../main.reducer";
import { CHANGE_CART, CHANGE_RESERVE } from "../Cart/cart.actions";
import { SET_FAVORITES, SELECT_PICTURE } from "./pictures.actions";
import { SELECT_CATEGORY } from "../Menu/menu.actions";
import { PackageContentModal } from "../PackageContent/PackageContentModal";
import { Price } from "../../components/Price/Price";
import { DiscountBadge } from "../../components/DiscountBadge/DicsountBadge";
import { Flex } from "../../components/Flex/Flex";

type OwnProps = {
  pic?: IPicture;
  isPopup?: boolean;
};

const mapStateToProps = (state: AppState, props: OwnProps) => ({
  availableOnly: state.pictures.availableOnly,
  favorites: state.pictures.favorites,
  cart: state.cart.cart,
  reserve: state.cart.reserve,
  categories: state.menu.categories as ICategory[],
  darkMode: state.common.darkMode,
});

const mapDispatchToProps = (dispatch: (action: any) => any) => ({
  selectPicture: (picture: IPicture) =>
    dispatch({ type: SELECT_PICTURE, picture }),
  setFavorites: (favorites: number[]) => {
    dispatch({ type: SET_FAVORITES, favorites });
  },
  selectCategory: (category: ICategory) =>
    dispatch({ type: SELECT_CATEGORY, category }),
  changeCart: (
    cart: { [pictureId: number]: number },
    pictureId: number,
    quantity: number
  ) => {
    const cartState = { ...cart, [pictureId]: quantity };
    localStorage.setItem("bpb.cart", JSON.stringify(cartState));
    dispatch({ type: CHANGE_CART, pictureId, quantity });
  },
  changeReserve: (
    reserve: { [pictureId: number]: number },
    pictureId: number,
    quantity: number
  ) => {
    const reserveState = { ...reserve, [pictureId]: quantity };
    localStorage.setItem("bpb.reserve", JSON.stringify(reserveState));
    dispatch({ type: CHANGE_RESERVE, pictureId, quantity });
  },
});

export const IconCategory = (props: { category: number }) => {
  switch (props.category) {
    case 0:
      return <IonIcon icon={images} color="dark" />;
    case 1:
      return <IonIcon icon={imageOutline} color="dark" />;
    case 3:
      return <IonIcon icon={paw} color="dark" />;
    case 4:
      return <IonIcon icon={flowerOutline} color="dark" />;
    case 5:
      return <IonIcon icon={heartHalfOutline} color="dark" />;
    case 6:
      return <IonIcon icon={earth} color="dark" />;
    case 7:
      return <IonIcon icon={accessibility} color="dark" />;
    default:
      return <></>;
  }
};

type AllProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  OwnProps;

const PictureCard = (props: AllProps) => {
  const { pic, isPopup, favorites, cart, reserve, setFavorites } = props;

  const [showPackageModal, setShowPackageModal] = useState<boolean>(false);

  const favoriteToggle: (id: number) => void = (id: number) => {
    const newFavorites: number[] =
      favorites.indexOf(id) < 0
        ? [...favorites, id]
        : favorites.filter((f) => f !== id);
    setFavorites(newFavorites);
    localStorage.setItem(
      "bpb.pictures.favorites",
      JSON.stringify(newFavorites)
    );
  };

  const categoryName = (id: number) => {
    const category = props.categories.find((c) => c.id === id);
    return category ? category.name : "";
  };

  const findCategory = (id: number) => {
    return props.categories.find((c) => c.id === id) || ({} as ICategory);
  };

  const isFavorite = useMemo(
    () => (id: number) => favorites.indexOf(id) >= 0,
    [favorites]
  );

  if (!pic) {
    return <></>;
  }

  return (
    <IonCard style={{ margin: "3px" }}>
      <picture style={{ cursor: isPopup ? "auto" : "pointer" }}>
        <source
          media="(min-width:650px)"
          srcSet={`assets/pics/${pic.code}.jpg`}
        />
        <img
          src={`assets/pics/320/${pic.code}.jpg`}
          alt={pic.fileName}
          onClick={() => props.selectPicture(pic)}
          style={{ width: "100%" }}
          loading="lazy"
        />
      </picture>
      <DiscountBadge
        price={pic.price}
        priceNoDiscount={pic.priceNoDiscount}
        isPopup={isPopup}
      />

      {!isPopup && (
        <IonCardTitle
          className="ion-text-center f20xs"
          style={{ padding: "5px 0" }}
        >
          {pic.title}
        </IonCardTitle>
      )}

      <IonCardContent style={{ padding: 5 }}>
        <IonGrid>
          <IonRow>
            <IonCol size="8">
              <IonCardSubtitle>
                <IonIcon
                  icon={ellipse}
                  style={{
                    paddingRight: 5,
                    color: pic.status
                      ? "var(--ion-color-success-shade)"
                      : "var(--ion-color-danger-shade)",
                  }}
                />
                <IonText>
                  {pic.status ? "Na stanju" : "Trenutno nema na stanju"}
                </IonText>
              </IonCardSubtitle>
            </IonCol>
            <IonCol size="4" className="ion-text-right">
              <Price
                price={pic.price}
                priceNoDiscount={pic.priceNoDiscount}
                isPopup={isPopup}
              />
            </IonCol>
          </IonRow>
        </IonGrid>

        <Flex
          css={{
            alignItems: "center",
            flexDirection: "column",
            justifyContent: "center",
            margin: "0 auto",
            width: "fit-content",
          }}
        >
          <Link
            to={{ pathname: `/slike/${findCategory(pic.category).code}` }}
            style={{ textDecoration: "none", color: "var(--ion-color-dark)" }}
            target="_self"
            onClick={() => props.selectCategory(findCategory(pic.category))}
          >
            <IonChip>
              <IconCategory category={pic.category} />
              <IonLabel>{categoryName(pic.category)}</IonLabel>
            </IonChip>
          </Link>

          <IonChip onClick={() => favoriteToggle(pic.id)} style={{}}>
            {isFavorite(pic.id) ? "Omiljena" : "Dodaj u omiljene"}
            <IonIcon
              color={isFavorite(pic.id) ? "danger" : "dark"}
              icon={isFavorite(pic.id) ? heart : heartOutline}
              style={{ cursor: "pointer", fontSize: 26, padding: "0 3px" }}
            />
          </IonChip>

          <IonChip onClick={() => setShowPackageModal(true)}>
            <IonIcon icon={mail} color="dark"></IonIcon>
            <IonLabel>Sadržaj seta</IonLabel>
          </IonChip>
        </Flex>

        <Flex css={{ padding: "5px 2%" }}>
          {pic.sizeX && pic.sizeY && (
            <IonText>
              {`Dimenzije:`}
              <br />
              {`${pic.sizeX}x${pic.sizeY}cm`}
            </IonText>
          )}
          <div style={{ flexGrow: 1 }} />
          <IonText style={{ textAlign: "right" }}>
            {"Šifra:"}
            <br />
            {pic.code}
          </IonText>
        </Flex>

        <IonGrid>
          <IonRow>
            <IonCol size="12" offset="0">
              {pic.status && (
                <IonButton
                  color="success"
                  disabled={cart[pic.id] > 0}
                  expand="block"
                  onClick={() =>
                    props.changeCart(cart, pic.id, cart[pic.id] + 1 || 1)
                  }
                >
                  <IonIcon
                    icon={cart[pic.id] > 0 ? cartIcon : cartOutline}
                    size="large"
                    slot="start"
                  />
                  {cart[pic.id] > 0 ? "U korpi" : "U korpu"}
                </IonButton>
              )}
              {!pic.status && (
                <IonButton
                  color="warning"
                  disabled={reserve[pic.id] > 0}
                  expand="block"
                  onClick={() =>
                    props.changeReserve(
                      reserve,
                      pic.id,
                      reserve[pic.id] + 1 || 1
                    )
                  }
                >
                  <IonIcon
                    icon={reserve[pic.id] > 0 ? alarm : alarmOutline}
                    size="large"
                    slot="start"
                  />
                  {reserve[pic.id] > 0 ? "Rezervisano" : "Rezerviši"}
                </IonButton>
              )}
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonCardContent>
      <PackageContentModal
        isOpen={showPackageModal}
        onDismiss={() => setShowPackageModal(false)}
      />
    </IonCard>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(PictureCard);
