import {
  IonButton,
  IonCard,
  IonCol,
  IonGrid,
  IonIcon,
  IonLabel,
  IonRow,
} from "@ionic/react";
import {
  addCircle,
  checkbox,
  removeCircle,
  squareOutline,
  tabletLandscape,
} from "ionicons/icons";
import { useCart } from "./useCart";
import { NumberMoney } from "../../components/NumberMoney/NumberMoney";
import SubHeader from "../FrameAndEasel/SubHeader";
import { PRICE_FRAME } from "../../common/constants";
import { usePictures } from "../Pictures/usePictures";

const Frame = () => {
  const {
    frames,
    cartPictures,
    changeFrameNumber,
    framesAsPictures,
    framesAsPicturesToggle,
  } = useCart();

  const { equipmentState } = usePictures();
  const disabled = !equipmentState.frame;
  const allowAdd = !cartPictures.length || !framesAsPictures;
  const allowRemove = frames > 0 && (!cartPictures.length || !framesAsPictures);

  return (
    <IonCard style={{ margin: "3px", padding: "13px 16px 0 16px" }}>
      <SubHeader
        icon={tabletLandscape}
        text="Drveni (blind) ram - rasklopljen"
        onTop
        disabled={disabled}
      >
        <NumberMoney value={PRICE_FRAME} />
      </SubHeader>

      {!disabled && (
        <IonGrid>
          <IonRow>
            <IonCol className="ion-text-right">
              <IonIcon
                className={allowRemove ? "cursorP" : "op5"}
                icon={removeCircle}
                size="large"
                color={allowRemove ? "primary" : "var(--ion-color-light)"}
                onClick={() => {
                  if (allowRemove) {
                    changeFrameNumber(frames - 1);
                  }
                }}
              />
            </IonCol>
            <IonCol className="ion-text-center f20 b" style={{ paddingTop: 7 }}>
              <IonLabel>{frames}</IonLabel>
            </IonCol>
            <IonCol className="ion-text-left">
              <IonIcon
                className={allowAdd ? "cursorP" : "op5"}
                color={allowAdd ? "primary" : "var(--ion-color-light)"}
                icon={addCircle}
                size="large"
                onClick={() => {
                  if (allowAdd) {
                    changeFrameNumber(frames + 1);
                  }
                }}
              />
            </IonCol>
          </IonRow>
          {cartPictures.length > 0 && (
            <IonRow>
              <IonCol className="center" style={{ justifyContent: "center" }}>
                <IonButton color="success" onClick={framesAsPicturesToggle}>
                  <IonIcon
                    icon={framesAsPictures ? checkbox : squareOutline}
                    color="light"
                    size="large"
                    slot="start"
                  />
                  Ram za svaku sliku
                </IonButton>
              </IonCol>
            </IonRow>
          )}
        </IonGrid>
      )}
      {disabled && (
        <div className="b f15 ion-text-center" style={{ marginBottom: 12 }}>
          <IonLabel>Trenutno nema na stanju</IonLabel>
        </div>
      )}
    </IonCard>
  );
};

export default Frame;
