import { FC, useEffect } from "react";
import {
  IonContent,
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonGrid,
  IonRow,
  IonCol,
  IonText,
  IonIcon,
  IonButtons,
  IonBackButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
} from "@ionic/react";
import {
  cartOutline,
  cart as cartIcon,
  heart,
  heartOutline,
} from "ionicons/icons";
import PictureCard from "../Pictures/PictureCard";
import "../../theme/common.css";
import { debug } from "../../common/utils";
import PicturePreview from "../Pictures/PicturePreview";
import { Link } from "react-router-dom";
import { usePictures } from "../Pictures/usePictures";
import { useCommon } from "../../common/useCommon";
import { useCart } from "../Cart/useCart";

const Favorites: FC = () => {
  const { cart } = useCart();
  const { zoom, setDarkMode } = useCommon();
  const { allPictures, favorites, getAllPictures, loadFavorites } =
    usePictures();

  const getLocalFavorites = (): number[] => {
    const storageString = localStorage.getItem("bpb.pictures.favorites");
    return storageString ? JSON.parse(storageString) : ([] as number[]);
  };
  const getLocalDarkMode = (): boolean => {
    const storageString = localStorage.getItem("bpb.darkMode");
    return storageString ? JSON.parse(storageString) : false;
  };

  useEffect(() => {
    debug("LOAD DEFAULTS");

    if (allPictures.length === 0) {
      getAllPictures();
    }
    if (favorites.length === 0) {
      loadFavorites(getLocalFavorites());
    }
    setDarkMode(getLocalDarkMode());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const picturesFiltered = () =>
    allPictures.filter((p) => favorites.includes(p.id));

  const cartCount = () => {
    let q = 0;
    if (cart) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      for (const [key, value] of Object.entries(cart)) {
        q += value;
      }
    }
    return q;
  };

  return (
    <IonPage id="favorites">
      <IonHeader>
        <IonToolbar color="danger">
          <IonButtons slot="start">
            <IonBackButton defaultHref="/"></IonBackButton>
          </IonButtons>
          <IonTitle>Omiljene</IonTitle>
          <IonButtons slot="end" style={{ paddingRight: 15 }}>
            <IonIcon
              icon={favorites.length > 0 ? heart : heartOutline}
              style={{ fontSize: 28 }}
            />
            <IonText style={{ paddingLeft: 3 }}>{favorites.length}</IonText>
          </IonButtons>
          <IonButtons slot="end" style={{ paddingRight: 15 }}>
            <Link
              to={{ pathname: "/korpa" }}
              style={{ textDecoration: "none", color: "#fff" }}
            >
              <IonIcon
                icon={cartCount() > 0 ? cartIcon : cartOutline}
                style={{ fontSize: 28 }}
              />
            </Link>
            <IonText style={{ paddingLeft: 3 }}>{cartCount()}</IonText>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        {/* <div>{JSON.stringify({ categoryCode })}</div> */}
        {/* <div>{JSON.stringify(selectedCategory)}</div> */}
        {/* <div>{JSON.stringify(picturesFiltered())}</div> */}
        <PicturePreview />

        {picturesFiltered().length > 0 && (
          <IonGrid className="ion-no-padding">
            <IonRow>
              {picturesFiltered().map((pic) => (
                // <IonCol key={pic.id} size={zoom ? "12" : "6"} sizeMd={zoom ? "6" : "4"} size-sm={zoom ? "12" : "6"}>
                <IonCol
                  key={pic.id}
                  sizeSm={(12 / zoom).toString()}
                  sizeXs={(12 / (zoom > 2 ? 2 : zoom)).toString()}
                >
                  <PictureCard pic={pic} />
                </IonCol>
              ))}
            </IonRow>
          </IonGrid>
        )}
        {picturesFiltered().length === 0 && (
          <IonCard>
            <IonCardContent className="ion-text-center">
              <IonIcon icon={heartOutline} style={{ fontSize: 100 }} />
              <IonCardHeader className="ion-text-center">
                Vaša lista omiljenih je prazna
              </IonCardHeader>
            </IonCardContent>
          </IonCard>
        )}
      </IonContent>
    </IonPage>
  );
};

export default Favorites;
