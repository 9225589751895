import axios from "axios";
import appconfig from "../../../AppConfig";
import { debug } from "../../../common/utils";
import { UPDATE_PICTURE_PRICES } from "../pictures.actions";

export const updatePicturePricesApi = async (
  dispatch: any,
  pictureId: number,
  price: number,
  priceNoDiscount: number | null
): Promise<boolean> => {
  const url = "products/updatePicturePrices";
  const data = {
    id: pictureId,
    price,
    priceNoDiscount,
  };
  var result = false;

  const config = {
    baseURL: appconfig.baseURL,
  };

  await axios
    .post(url, data, config)
    .then((response: any) => {
      debug("!!! API - updatePicturePricesApi !!!");

      if (response && response.data) {
        dispatch({
          type: UPDATE_PICTURE_PRICES,
          pictureId,
          price,
          priceNoDiscount,
        });
        result = true;
      }

      debug(response);
    })
    .catch((err) => {
      console.log(err);
      // alert(err);
    });

  return result;
};
