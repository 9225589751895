import axios, { AxiosRequestConfig } from "axios";
import appconfig from '../../../AppConfig';
import { debug } from "../../../common/utils";
import { LOAD_ALL_PICTURES_ADMIN } from "../pictures.actions";

export const getAllPicturesAdminApi = async (
    dispatch: any,
) => {
    // dispatch({ type: LOAD_ALL_PICTURES, newStatus: "loading" });

    const config = {
        baseURL: appconfig.baseURL,
    } as AxiosRequestConfig;

    await axios.get("/products/getAllPicturesAdmin", config)
        .then((response: any) => {
            debug("!!! API - getAllPicturesAdminApi !!!");

            dispatch({
                type: LOAD_ALL_PICTURES_ADMIN,
                data: response.data
            });

            // if (response.status === 200) {
            //     dispatch({ type: LOAD_ALL_PICTURES, newStatus: "loaded" });
            // }

            debug(response);
        })
        .catch((err: any) => {
            console.log(err);
            // dispatch({ type: LOAD_STATUS, newStatus: "error" });
        });
};