import React from "react";
import {
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenuToggle,
  IonToggle,
} from "@ionic/react";
import {
  moonOutline,
  cart,
  ellipse,
  home,
  list,
  cashOutline,
} from "ionicons/icons";
import "../Menu/Menu.css";
import { AppState } from "../main.reducer";
import { connect } from "react-redux";
import { CHANGE_DARK_MODE } from "../../common/common.actions";
import { Link, useLocation } from "react-router-dom";
import { useAdmin } from "./useAdmin";

interface OwnProps {}

const mapStateToProps = (state: AppState) => ({
  darkMode: state.common.darkMode as boolean,
});

const mapDispatchToProps = (dispatch: (action: any) => any) => ({
  setDarkMode: (darkMode: boolean) =>
    dispatch({ type: CHANGE_DARK_MODE, darkMode }),
});

type AllProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  OwnProps;

const AdminMenu = (props: AllProps) => {
  const { isLoggedIn } = useAdmin();

  const location = useLocation();

  const toggleDarkMode = () => {
    props.setDarkMode(!props.darkMode);
    localStorage.setItem("bpb.darkMode", JSON.stringify(!props.darkMode));
  };

  return (
    <>
      <IonList lines="none">
        <IonMenuToggle auto-hide="false">
          <Link
            to={{ pathname: "/" }}
            style={{ textDecoration: "none", color: "var(--ion-color-dark)" }}
          >
            <IonItem button>
              <IonIcon slot="start" icon={home}></IonIcon>
              <IonLabel>Početna strana</IonLabel>
            </IonItem>
          </Link>
        </IonMenuToggle>

        {isLoggedIn && <IonListHeader>Administracija</IonListHeader>}
        {isLoggedIn && (
          <IonMenuToggle auto-hide="false">
            <Link
              to={{ pathname: "/adminOrders" }}
              style={{ textDecoration: "none", color: "var(--ion-color-dark)" }}
            >
              <IonItem
                button
                className={
                  location.pathname.endsWith("adminOrders")
                    ? "selected"
                    : undefined
                }
              >
                <IonIcon slot="start" icon={cart}></IonIcon>
                <IonLabel>Porudžbine</IonLabel>
              </IonItem>
            </Link>
            <Link
              to={{ pathname: "/adminAvailability" }}
              style={{ textDecoration: "none", color: "var(--ion-color-dark)" }}
            >
              <IonItem
                button
                className={
                  location.pathname.endsWith("adminAvailability")
                    ? "selected"
                    : undefined
                }
              >
                <IonIcon
                  slot="start"
                  icon={ellipse}
                  style={{
                    color: "var(--ion-color-danger-shade)",
                    zIndex: 1,
                    marginRight: -11,
                    fontSize: 18,
                  }}
                />
                <IonIcon
                  slot="start"
                  icon={ellipse}
                  style={{
                    color: "var(--ion-color-success-shade)",
                    zIndex: 2,
                    fontSize: 18,
                  }}
                />
                <IonLabel>Stanje</IonLabel>
              </IonItem>
            </Link>
            <Link
              to={{ pathname: "/adminReorder" }}
              style={{ textDecoration: "none", color: "var(--ion-color-dark)" }}
            >
              <IonItem
                button
                className={
                  location.pathname.endsWith("adminReorder")
                    ? "selected"
                    : undefined
                }
              >
                <IonIcon slot="start" icon={list}></IonIcon>
                <IonLabel>Redosled</IonLabel>
              </IonItem>
            </Link>
            <Link
              to={{ pathname: "/adminPrices" }}
              style={{ textDecoration: "none", color: "var(--ion-color-dark)" }}
            >
              <IonItem
                button
                className={
                  location.pathname.endsWith("adminPrices")
                    ? "selected"
                    : undefined
                }
              >
                <IonIcon slot="start" icon={cashOutline}></IonIcon>
                <IonLabel>Cena</IonLabel>
              </IonItem>
            </Link>
          </IonMenuToggle>
        )}
      </IonList>

      {/* <IonList lines="none">
        <IonListHeader>Statistika</IonListHeader>
        <IonMenuToggle auto-hide="false">
          <Link to={{ pathname: "/omiljene" }}
            style={{ textDecoration: "none", color: "var(--ion-color-dark)" }}
          >
            <IonItem button className={location.pathname.endsWith("omiljene") ? "selected" : undefined}>
              <IonIcon slot="start" icon={heart} />
              <IonLabel>Omiljene</IonLabel>
            </IonItem>
          </Link>
          <Link to={{ pathname: "/korpa" }}
            style={{ textDecoration: "none", color: "var(--ion-color-dark)" }}
          >
            <IonItem button className={location.pathname.endsWith("korpa") ? "selected" : undefined}>
              <IonIcon slot="start" icon={cartIcon} />
              <IonLabel>Korpa</IonLabel>
            </IonItem>
          </Link>
        </IonMenuToggle>
      </IonList> */}

      <IonList lines="none">
        <IonListHeader>Opcije</IonListHeader>
        <IonItem>
          <IonIcon slot="start" icon={moonOutline}></IonIcon>
          <IonLabel>Tamni mod</IonLabel>
          <IonToggle checked={props.darkMode} onClick={toggleDarkMode} />
        </IonItem>
      </IonList>
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminMenu);
