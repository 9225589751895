import { ICart } from "../../models";
import { ActionType } from "../../util/types";

export const LOAD_CART = "LOAD_CART";
export const LOAD_RESERVE = "LOAD_RESERVE";
export const LOAD_EASEL_WOOD = "LOAD_EASEL_WOOD";
export const LOAD_EASEL_ALUM = "LOAD_EASEL_ALUM";
export const LOAD_FRAMES = "LOAD_FRAMES";
export const LOAD_FRAMES_ASSEMBLED = "LOAD_FRAMES_ASSEMBLED";
export const SET_CART_PAGE = "SET_CART_PAGE";
export const CHANGE_CART = "CHANGE_CART";
export const CHANGE_RESERVE = "CHANGE_RESERVE";
export const CHANGE_EASEL_WOOD_NUMBER = "CHANGE_EASEL_WOOD_NUMBER";
export const CHANGE_EASEL_ALUM_NUMBER = "CHANGE_EASEL_ALUM_NUMBER";
export const CHANGE_FRAME_NUMBER = "CHANGE_FRAME_NUMBER";
export const CHANGE_FRAME_ASSEMBLED_NUMBER = "CHANGE_FRAME_ASSEMBLED_NUMBER";
export const HIDE_TOAST_CART_NOTIFICATION = "HIDE_TOAST_CART_NOTIFICATION";
export const NEW_ORDER = "NEW_ORDER";
export const RESET_CART = "REST_CART";
export const FRAMES_AS_PICTURES = "FRAMES_AS_PICTURES";
export const FRAMES_ASSEMBLED_AS_PICTURES = "FRAMES_ASSEMBLED_AS_PICTURES";

export const loadCart = (cart: { [pictureId: number]: number }) =>
  ({
    type: LOAD_CART,
    cart,
  } as const);

export const loadReserve = (reserve: { [pictureId: number]: number }) =>
  ({
    type: LOAD_RESERVE,
    reserve,
  } as const);

export const loadFrames = (frames: number) =>
  ({
    type: LOAD_FRAMES,
    frames,
  } as const);
export const loadFramesAssembled = (framesAssembled: number) =>
  ({
    type: LOAD_FRAMES_ASSEMBLED,
    framesAssembled,
  } as const);
export const loadEaselWood = (number: number) =>
  ({
    type: LOAD_EASEL_WOOD,
    number,
  } as const);
export const loadEaselAlum = (number: number) =>
  ({
    type: LOAD_EASEL_ALUM,
    number,
  } as const);

export const setCartPage = (page: number) =>
  ({
    type: SET_CART_PAGE,
    page,
  } as const);

export const changeCart = (pictureId: number, quantity: number) =>
  ({
    type: CHANGE_CART,
    pictureId,
    quantity,
  } as const);

export const changeReserve = (pictureId: number, quantity: number) =>
  ({
    type: CHANGE_RESERVE,
    pictureId,
    quantity,
  } as const);

export const changeEaselWoodNumber = (quantity: number) =>
  ({
    type: CHANGE_EASEL_WOOD_NUMBER,
    quantity,
  } as const);

export const changeEaselAlumNumber = (quantity: number) =>
  ({
    type: CHANGE_EASEL_ALUM_NUMBER,
    quantity,
  } as const);

export const changeFrameNumber = (quantity: number) =>
  ({
    type: CHANGE_FRAME_NUMBER,
    quantity,
  } as const);

export const changeFrameAssembledNumber = (quantity: number) =>
  ({
    type: CHANGE_FRAME_ASSEMBLED_NUMBER,
    quantity,
  } as const);

export const hideToastNotifications = () =>
  ({
    type: HIDE_TOAST_CART_NOTIFICATION,
  } as const);

export const newOrder = (cart: ICart) =>
  ({
    type: NEW_ORDER,
    cart,
  } as const);

export const resetCart = () =>
  ({
    type: RESET_CART,
  } as const);

export const framesAsPictures = (flag: boolean) =>
  ({
    type: FRAMES_AS_PICTURES,
    flag,
  } as const);

export const framesAssembledAsPictures = (flag: boolean) =>
  ({
    type: FRAMES_ASSEMBLED_AS_PICTURES,
    flag,
  } as const);

export type CartActions =
  | ActionType<typeof loadCart>
  | ActionType<typeof loadReserve>
  | ActionType<typeof loadEaselWood>
  | ActionType<typeof loadEaselAlum>
  | ActionType<typeof loadFrames>
  | ActionType<typeof loadFramesAssembled>
  | ActionType<typeof setCartPage>
  | ActionType<typeof changeCart>
  | ActionType<typeof changeReserve>
  | ActionType<typeof changeEaselWoodNumber>
  | ActionType<typeof changeEaselAlumNumber>
  | ActionType<typeof changeFrameNumber>
  | ActionType<typeof changeFrameAssembledNumber>
  | ActionType<typeof hideToastNotifications>
  | ActionType<typeof newOrder>
  | ActionType<typeof resetCart>
  | ActionType<typeof framesAsPictures>
  | ActionType<typeof framesAssembledAsPictures>;
