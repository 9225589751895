import React from "react";
import { IonButton, IonButtons, IonCard, IonCol, IonContent, IonGrid, IonIcon, IonMenuToggle, IonPage, IonRow, IonTitle, IonToolbar } from "@ionic/react";
import { menu } from "ionicons/icons";
import { CategorieLinks } from "../../components/CategorieLinks/CategorieLinks";
import { Link } from "react-router-dom";

const array = () => {
  const result: string[] = [];
  for (var i = 1; i < 20; i++) {
    result.push(i < 10 ? `0${i}` : `${i}`)
  }
  return result;
};

const Examples = () =>
  <IonPage>
    <IonToolbar color="primary">
      <IonButtons slot="start">
        <IonMenuToggle>
          <IonIcon icon={menu} style={{ fontSize: 28 }} />
        </IonMenuToggle>
      </IonButtons>
      <IonTitle>Primeri radova</IonTitle>
      <IonButtons slot="end">
        <Link to={{ pathname: "/slike" }}>
          <IonButton color="dark">Motivi</IonButton>
        </Link>
      </IonButtons>
    </IonToolbar>
    <IonContent>
      <IonGrid>
        <IonRow>
          {array().map(n => <IonCol key={n} size="12" sizeMd="3" className="ion-no-padding">
            <IonCard>
              <img src={`assets/examples/example_${n}.jpg`} alt="Example" style={{ width: "100%" }} />
            </IonCard>
          </IonCol>)}
        </IonRow>
        <IonRow>
          <IonCol>
            <CategorieLinks />
          </IonCol>
        </IonRow>
      </IonGrid>
    </IonContent>
  </IonPage>

export default Examples;
