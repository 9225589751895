import { FC, useEffect, useState } from "react";
import {
  IonContent,
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonGrid,
  IonRow,
  IonCol,
  IonText,
  IonIcon,
  IonButtons,
  IonButton,
  IonBackButton,
  IonSpinner,
} from "@ionic/react";
import { ICart } from "../../models";
import { arrowBackCircle, arrowForwardCircle } from "ionicons/icons";
import "../../theme/common.css";
import { debug, getLocalBool, getLocalObject } from "../../common/utils";
import { NumberMoney } from "../../components/NumberMoney/NumberMoney";
import { useCart } from "./useCart";
import { CartOrder } from "./CartOrder";
import { CartInfo } from "./CartInfo";
import PicturePreview from "../Pictures/PicturePreview";
import InfoMessage from "../../components/InfoMessage/InfoMessage";
import appconfig from "../../AppConfig";
import { OrderAccepted } from "./OrderAccepted";
import { usePictures } from "../Pictures/usePictures";
import { OrderConfirmation } from "./OrderConfirmation";
import { DELIVERY_MESSAGE } from "../../common/constants";

const emptyCart = {
  id: -1,
  name: "",
  address: "",
  zip: "",
  place: "",
  phone: "",
  email: "",
  note: "",
  cartItems: [],
};

const Cart: FC = () => {
  const { allPictures, getAllPictures } = usePictures();

  const {
    loadCart,
    loadReserve,
    loadEaselWood,
    loadEaselAlum,
    loadFrames,
    loadFramesAssembled,
    loadFramesAsPictures,
    loadFramesAssembledAsPictures,
    page,
    setPage,
    cart,
    cartPictures,
    cartItems,
    isCartEmpty,
    framesAsPictures,
    framesAssembledAsPictures,
    setFrameAsCart,
    setFrameAssembledAsCart,
    priceTotal,
    orderSubmit,
    resetCart,
  } = useCart();

  const [showOrderAccepted, setShowOrderAccepted] = useState<boolean>(false);
  const [orderId, setOrderId] = useState<number>(0);
  const [orderButtonEnabled, setOrderButtonEnabled] = useState<boolean>(false);
  const [orderButtonLoading, setOrderButtonLoading] = useState<boolean>(false);
  const [newCart, setNewCart] = useState<ICart>(emptyCart as ICart);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState<boolean>(false);

  useEffect(() => {
    debug("LOAD DEFAULTS");

    setPage(1);

    if (allPictures.length === 0) {
      getAllPictures();
    }

    loadCart(getLocalObject("bpb.cart") || []);
    loadReserve(getLocalObject("bpb.reserve") || []);
    loadEaselWood(getLocalObject("bpb.easelWood") || 0);
    loadEaselAlum(getLocalObject("bpb.easelAlum") || 0);
    loadFrames(getLocalObject("bpb.frames") || 0);
    loadFramesAssembled(getLocalObject("bpb.framesAssembled") || 0);
    loadFramesAsPictures(getLocalBool("bpb.framesAsPictures") || false);
    loadFramesAssembledAsPictures(
      getLocalBool("bpb.framesAssembledAsPictures") || false
    );

    return setShowOrderAccepted(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // cartPictures.length - do not fire setFramesAsPictures if cart is empty (no pictures) - order only frame(s)
    if (framesAsPictures && cartPictures.length) {
      setFrameAsCart();
    }
  }, [cart, cartPictures, framesAsPictures, setFrameAsCart]);

  useEffect(() => {
    // cartPictures.length - do not fire setFramesAsPictures if cart is empty (no pictures) - order only frame(s) assembled
    if (framesAssembledAsPictures && cartPictures.length) {
      setFrameAssembledAsCart();
    }
  }, [cart, cartPictures, framesAssembledAsPictures, setFrameAssembledAsCart]);

  useEffect(() => {
    const isEnabled =
      newCart.name !== undefined &&
      newCart.name.length > 3 &&
      newCart.address !== undefined &&
      newCart.address.length > 5 &&
      newCart.zip !== undefined &&
      newCart.zip.length > 3 &&
      newCart.place !== undefined &&
      newCart.place.length > 1 &&
      newCart.phone !== undefined &&
      newCart.phone.length > 6 &&
      cartItems.length > 0;
    setOrderButtonEnabled(isEnabled);
  }, [newCart, cartItems]);

  const dismissConfirmation = () => {
    setPage(1);
    setShowOrderAccepted(false);
  };

  const updateCart = (cart: ICart) => {
    setNewCart(cart);
  };

  const orderSuccessfull = (id: number) => {
    if (id > 0) {
      setOrderId(id);
      setShowOrderAccepted(true);
      resetCart();
      setIsConfirmationOpen(false);
      setOrderButtonLoading(false);
    }
  };

  const order = () => {
    if (newCart && cartItems.length > 0) {
      setOrderButtonLoading(true);
      const cartWithItems = { ...newCart, cartItems: [...cartItems] } as ICart;
      orderSubmit(cartWithItems, (id) => orderSuccessfull(id));
    }
  };

  return (
    <IonPage id="cart">
      <IonHeader>
        <IonToolbar color="success">
          <IonButtons slot="start">
            <IonBackButton defaultHref="/"></IonBackButton>
          </IonButtons>
          <IonTitle>Korpa za naručivanje</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        {appconfig.showDeliveryInfoNotification && (
          <InfoMessage text={DELIVERY_MESSAGE} />
        )}
        <PicturePreview />

        {page === 1 && <CartInfo />}
        {page === 2 && <CartOrder cart={newCart} updateCart={updateCart} />}
      </IonContent>

      <IonToolbar className="ion-no-padding">
        <IonGrid className="ion-no-padding">
          <IonRow className="vertical-center">
            <IonCol size="4">
              {page === 2 && (
                <IonButton color="primary" onClick={() => setPage(1)}>
                  <IonIcon slot="start" icon={arrowBackCircle} />
                  Nazad
                </IonButton>
              )}
            </IonCol>
            <IonCol size="4" className="ion-text-center">
              <IonText className="f14">
                <span style={{ fontSize: 12 }}>UKUPNO </span>
                <br />
                <NumberMoney value={priceTotal()} />
              </IonText>
            </IonCol>
            <IonCol size="4" className="ion-text-right">
              {page === 1 && (
                <IonButton
                  color="primary"
                  disabled={isCartEmpty}
                  onClick={() => setPage(2)}
                >
                  Napred
                  <IonIcon slot="end" icon={arrowForwardCircle} />
                </IonButton>
              )}
              {page === 2 && (
                <IonButton
                  color="primary"
                  disabled={!orderButtonEnabled || orderButtonLoading}
                  expand="block"
                  onClick={() => setIsConfirmationOpen(true)}
                >
                  {orderButtonLoading ? (
                    <IonSpinner name="crescent" />
                  ) : (
                    <>NARUČI...</>
                  )}
                </IonButton>
              )}
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonToolbar>
      <OrderConfirmation
        isOpen={isConfirmationOpen}
        cart={newCart}
        onCancel={() => setIsConfirmationOpen(false)}
        onSubmit={order}
        isSubmitting={orderButtonLoading}
      />
      <OrderAccepted
        show={showOrderAccepted}
        dismiss={dismissConfirmation}
        orderId={orderId || 0}
      />
    </IonPage>
  );
};

export default Cart;
