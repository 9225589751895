import { FC } from "react";
import {
  DELIVERY_MESSAGE,
  PRICE_PICTURE,
  PRICE_SHIPPING,
} from "../../common/constants";
import { NumberMoney } from "../../components/NumberMoney/NumberMoney";

export const PackageContent: FC<{ showTitle?: boolean }> = ({
  showTitle = true,
}) => (
  <>
    {showTitle && (
      <>
        <div className="cursive f18" style={{ color: "var(--ion-color-dark)" }}>
          Sadržaj seta
        </div>
        <br />
      </>
    )}
    <div
      className={showTitle ? "cursive f14" : "cursive f18"}
      style={{ color: showTitle ? "" : "var(--ion-color-dark)" }}
    >
      Jedan set za bojenje sadrži:
    </div>
    <ul style={{ paddingLeft: "15px" }}>
      <li>
        Slikarsko platno dimenzija 40x50cm sa odštampanim konturama izabranog
        motiva (bez blind rama)
      </li>
      <li>Sve potrebne boje (akrilne), pripremljene i označene brojevima</li>
      <li>
        3 različite četkice (dve za precizno bojenje i jedna za veća polja)
      </li>
      <li>2 kukice za kačenje na zid</li>
      <li>Uputstvo za upotrebu</li>
      <li>Kontrolni list</li>
      <li>Račun - otpremnica</li>
    </ul>
    Cena jednog seta za bojenje je <NumberMoney value={PRICE_PICTURE} /> +
    poštarina.
    <br />
    Pored seta za bojenje, opciono u ponudi imamo i BLIND ram, kao i drvene i
    aluminijumske štafelaje.
    <br />
    <br />
  </>
);

export const PackageDelivery = () => (
  <>
    <div className="cursive f18" style={{ color: "var(--ion-color-dark)" }}>
      Poštarina
    </div>
    <br />
    <p className="f13 ion-text-justify">
      {`Poštarina je dodatnih ${PRICE_SHIPPING} din.`}
      <br />
      <br />
      Na našem web sajtu možete napraviti porudžbinu. Plaćanje je kuriru po
      prijemu porudžbine - pouzećem.
      <br />
      <br />
      {DELIVERY_MESSAGE.toUpperCase()}
    </p>
  </>
);
