import { IonCard, IonCardContent, IonText } from "@ionic/react";

const SetInfo = () => (
  <IonCard style={{ margin: "3px" }}>
    <IonCardContent>
      <IonText>
        Svaki set za bojenje sadrži:
        <ul>
          <li>
            Slikarsko platno dimenzija 40x50cm sa odštampanim konturama
            izabranog motiva (bez blind rama)
          </li>
          <li>
            Sve potrebne boje (akrilne), pripremljene i označene brojevima
          </li>
          <li>
            3 različite četkice (dve za precizno bojenje i jedna za veća polja)
          </li>
          <li>2 kukice za kačenje na zid</li>
          <li>Uputstvo za upotrebu</li>
          <li>Kontrolni list</li>
          <li>Račun - otpremnica</li>
        </ul>
      </IonText>
    </IonCardContent>
  </IonCard>
);

export default SetInfo;
