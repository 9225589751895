import React, { useEffect, useMemo } from "react";
import { useLocation } from "react-router";
import { IonContent, IonMenu } from "@ionic/react";
import MainMenu from "./MainMenu";
import AdminMenu from "../Admin/AdminMenu";

const Menu = () => {
  const location = useLocation();

  const isAdmin = useMemo(() => {
    return location.pathname.indexOf("admin") >= 0
  }, [location]);

  useEffect(() => {
    // props.getCategories();
    // setZoom(getLocalZoom() > zoomMin && getLocalZoom() < zoomMax ? getLocalZoom() : 2);
    // if (props.favorites.length === 0) {
    //   props.setFavorites(getLocalFavorites(), true);
    // }
  }, [])

  // const selectCategory = (category: ICategory) => {
  //   props.selectCategory(category);
  // }  

  return (
    <IonMenu type="overlay" contentId="main" disabled={false}>
      <IonContent forceOverscroll={false}>
        {isAdmin && <AdminMenu />}
        {!isAdmin && <MainMenu />}
      </IonContent>
    </IonMenu>
  );
};

export default Menu;
