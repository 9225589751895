import { useDispatch } from "react-redux";
import { ICart } from "../../../models";
import { sendCart2SPApi } from "./api/sendCart2SPApi";

export const useSP = () => {
  const dispatch = useDispatch();

  const sendOrder = async (cart: ICart) => {
    sendCart2SPApi(dispatch, cart);
  };

  return {
    sendOrder,
  };
};
