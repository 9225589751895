import React, { useEffect, useState } from "react";
import {
  IonAlert,
  IonButton,
  IonButtons,
  IonCard,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonRow,
  IonTextarea,
  IonTitle,
  IonToggle,
  IonToolbar,
} from "@ionic/react";
import {
  addCircle,
  alarm,
  arrowBack,
  removeCircle,
  save,
  trash,
} from "ionicons/icons";
import Moment from "react-moment";
import {
  calcTotal,
  debug,
  getCode,
  getEquipmentFileName,
  isAdditionalEquipment,
  pic,
} from "../../../common/utils";
import { NumberMoney } from "../../../components/NumberMoney/NumberMoney";
import { ICart, ICartItem, IPicture } from "../../../models";
import { usePictures } from "../../Pictures/usePictures";
import { useAdmin } from "../useAdmin";
import { PRICE_SHIPPING } from "../../../common/constants";
import { PickProduct } from "./PickProduct";

interface Props {
  isOpen: boolean;
  close: () => void;
}

const EditOrder: React.FC<Props> = ({ isOpen, close }) => {
  const [editingCart, setEditingCart] = useState<ICart | undefined>();
  const [isTouched, setIsTouched] = useState<boolean>(false);
  const [showUpdateCartSentWarning, setShowUpdateCartSentWarning] = useState<
    "SP" | "manual" | undefined
  >(undefined);
  const [showLoseChanges, setShowLoseChanges] = useState<boolean>(false);
  const [editingCartItems, setEditingCartItems] = useState<ICartItem[]>([]);
  const [pickOpen, setPickOpen] = useState(false);

  const {
    selectedCart,
    selectedOrderItems,
    cartTab,
    cartOrders,
    getCartOrdersPaged,
    toggleCartSent,
    saveCartEdit,
  } = useAdmin();
  const { allPictures } = usePictures();

  useEffect(() => {
    setEditingCart(selectedCart);
    setEditingCartItems(selectedOrderItems);
    setIsTouched(false);
    setShowLoseChanges(false);
    setShowUpdateCartSentWarning(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCart, isOpen]);

  const editCart = (field: keyof ICart, value: string) => {
    setEditingCart({ ...editingCart, [field]: value } as ICart);
    if (!isTouched) {
      setIsTouched(true);
    }
  };

  const removeItem = (pictireID: number) => {
    setEditingCartItems(
      editingCartItems.filter((i) => i.picture_id !== pictireID)
    );
    setIsTouched(true);
  };

  const addItem = (pic: IPicture) => {
    const newItem: ICartItem = {
      picture_id: pic.id,
      code: pic.code,
      price: pic.price,
      quantity: 1,
      isReservation: !pic.status,
      category: pic.category,
      codeSP: pic.codeSP,
    };
    setEditingCartItems([...editingCartItems, newItem]);
    setPickOpen(false);
    setIsTouched(true);
  };

  const updateItem = (updatedData: ICartItem) => {
    setEditingCartItems(
      editingCartItems.map((item) =>
        item.picture_id === updatedData.picture_id
          ? { ...item, ...updatedData }
          : item
      )
    );
    setIsTouched(true);
  };

  const tryToggle = (isSP: boolean = false) => {
    setShowUpdateCartSentWarning(isSP ? "SP" : "manual");
  };

  const toggleCart = () => {
    if (!!selectedCart) {
      toggleCartSent(selectedCart, showUpdateCartSentWarning === "SP");
    }
  };

  const onSave = async () => {
    await saveCartEdit(editingCart, editingCartItems);
    await getCartOrdersPaged(cartTab, 0, cartOrders.length, true);
    close();
  };

  const onClose = () => {
    if (isTouched) {
      setShowLoseChanges(true);
    } else {
      close();
    }
  };

  return (
    <IonModal isOpen={isOpen} onDidDismiss={close} showBackdrop={true}>
      <IonHeader>
        <IonToolbar color="danger">
          <IonButtons slot="start">
            <IonIcon
              className="f28"
              icon={arrowBack}
              onClick={() => onClose()}
              style={{ paddingLeft: "5px" }}
            />
          </IonButtons>
          <IonTitle>Ažuriranje Detalja</IonTitle>
          <IonButtons slot="end" style={{ paddingRight: 15 }}>
            <IonIcon
              icon={save}
              style={{ cursor: "pointer", fontSize: 28 }}
              onClick={onSave}
            />
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <IonCard color="light-shade">
          {selectedCart && (
            <IonGrid>
              <IonRow className="vertical-center">
                <IonCol>Slanje paketa - poslato</IonCol>
                <IonCol>
                  <IonToggle
                    color="success"
                    checked={selectedCart.package_sent_SP}
                    onIonChange={() => tryToggle(true)}
                  />
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>ID</IonCol>
                <IonCol className="b">{5000 + (selectedCart.id || 0)}</IonCol>
              </IonRow>
              <IonRow>
                <IonCol>Vreme porudžbine</IonCol>
                <IonCol className="b">
                  <Moment format="DD.MM.YYYY. HH:mm">
                    {selectedCart.date}
                  </Moment>
                </IonCol>
              </IonRow>

              <IonRow>
                <IonCol>Broj slika</IonCol>
                <IonCol className="b">{selectedCart.cartItemsCount}</IonCol>
              </IonRow>
              <IonRow>
                <IonCol>Ukupan iznos</IonCol>
                <IonCol className="b">
                  <NumberMoney
                    value={calcTotal(editingCartItems)}
                  ></NumberMoney>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>Ukupan iznos sa poštarinom</IonCol>
                <IonCol className="b">
                  <NumberMoney
                    value={calcTotal(editingCartItems) + PRICE_SHIPPING}
                    style={{ fontSize: 18, color: "#f00" }}
                  ></NumberMoney>
                </IonCol>
              </IonRow>
              <IonRow className="vertical-center">
                <IonCol>Porudžbina preuzeta</IonCol>
                <IonCol>
                  <IonToggle
                    color="success"
                    checked={selectedCart.package_sent}
                    onIonChange={() => tryToggle()}
                  />
                </IonCol>
              </IonRow>
            </IonGrid>
          )}
        </IonCard>

        {/* <div>Touched: {isTouched ? "true" : "false"}</div>
        <br />
        <div>{JSON.stringify(editingCart)}</div>
        <br />
        <div>{JSON.stringify(editingCartItems)}</div> */}

        <IonCard>
          {editingCart && (
            <IonList>
              <IonItem>
                <IonLabel
                  color="medium"
                  position="stacked"
                  // className={
                  //   !!cart.name && cart.name.length > 3 ? "" : "required"
                  // }
                >
                  Ime i prezime
                </IonLabel>
                <IonInput
                  type="text"
                  required
                  value={editingCart.name}
                  onIonChange={(e: any) => editCart("name", e.detail.value)}
                />
              </IonItem>
              <IonItem aria-required={true}>
                <IonLabel
                  color="medium"
                  position="stacked"
                  // className={
                  //   !!cart.address && cart.address.length > 5
                  //     ? ""
                  //     : "required"
                  // }
                >
                  Adresa
                </IonLabel>
                <IonInput
                  type="text"
                  required
                  value={editingCart.address}
                  onIonChange={(e: any) => editCart("address", e.detail.value)}
                />
              </IonItem>
              <IonItem>
                <IonLabel
                  color="medium"
                  position="stacked"
                  className={
                    !!editingCart.zip && editingCart.zip.length > 3
                      ? ""
                      : "required"
                  }
                >
                  Poštanski broj
                </IonLabel>
                <IonInput
                  type="text"
                  // name="postal-code"
                  // autocomplete="postal-code"
                  required
                  value={editingCart.zip}
                  onIonChange={(e: any) => editCart("zip", e.detail.value)}
                />
              </IonItem>
              <IonItem>
                <IonLabel
                  color="medium"
                  position="stacked"
                  // className={
                  //   !!cart.place && cart.place.length > 1 ? "" : "required"
                  // }
                >
                  Mesto
                </IonLabel>
                <IonInput
                  type="text"
                  required
                  value={editingCart.place}
                  onIonChange={(e: any) => editCart("place", e.detail.value)}
                />
              </IonItem>
              <IonItem>
                <IonLabel
                  color="medium"
                  position="stacked"
                  // className={
                  //   !!cart.phone && cart.phone.length > 6 ? "" : "required"
                  // }
                >
                  Kontakt telefon
                </IonLabel>
                <IonInput
                  type="text"
                  required={
                    !!editingCart.phone && editingCart.phone.length <= 6
                  }
                  value={editingCart.phone}
                  onIonChange={(e: any) => editCart("phone", e.detail.value)}
                />
              </IonItem>
              <IonItem>
                <IonLabel color="medium" position="stacked">
                  Email
                </IonLabel>
                <IonInput
                  type="text"
                  value={editingCart.email}
                  onIonChange={(e: any) => editCart("email", e.detail.value)}
                />
              </IonItem>
              <IonItem>
                <IonLabel color="medium" position="stacked">
                  Napomena
                </IonLabel>
                <IonTextarea
                  rows={3}
                  value={editingCart.note}
                  onIonChange={(e: any) => editCart("note", e.detail.value)}
                />
              </IonItem>
            </IonList>
          )}
        </IonCard>

        {allPictures.length > 0 &&
          editingCartItems &&
          editingCartItems.map((c: ICartItem, i: number) => (
            <IonCard
              key={c.picture_id}
              color={c.isReservation ? "dark" : "light"}
            >
              <IonGrid>
                <IonRow className="vertical-center">
                  <IonCol size="1" className="ion-text-center">
                    <div>{`${i + 1}.`}</div>
                  </IonCol>
                  <IonCol size="7">
                    <IonGrid className="ion-no-padding">
                      <IonRow
                        className="vertical-center"
                        style={{ padding: "5px 0" }}
                      >
                        <IonCol size="4" className="ion-text-right">
                          Šifra:
                        </IonCol>
                        <IonCol style={{ paddingLeft: 5 }}>
                          <div className="b">
                            {getCode(c.picture_id, allPictures)}
                          </div>
                        </IonCol>
                      </IonRow>
                      <IonRow
                        className="vertical-center"
                        style={{ padding: "5px 0" }}
                      >
                        <IonCol size="4" className="ion-text-right">
                          Naziv:
                        </IonCol>
                        <IonCol style={{ paddingLeft: 5 }}>
                          <div className="b">
                            {pic(c.picture_id, allPictures).title}
                          </div>
                        </IonCol>
                      </IonRow>
                      <IonRow className="vertical-center">
                        <IonCol size="4" className="ion-text-right">
                          Cena (din):
                        </IonCol>
                        <IonCol style={{ paddingLeft: 5 }}>
                          <input
                            type="number"
                            required={true}
                            value={c.price}
                            onChange={(e: any) =>
                              updateItem({ ...c, price: e.target.value })
                            }
                            style={{
                              backgroundColor: c.isReservation
                                ? "var(--ion-color-dark)"
                                : "var(--ion-color-light)",
                              border: "2px solid #8888",
                              fontWeight: "bold",
                              lineHeight: "10px",
                              outline: "none",
                              padding: 3,
                              textAlign: "center",
                              width: 90,
                            }}
                          />
                        </IonCol>
                      </IonRow>
                      <IonRow
                        className="vertical-center"
                        style={{ padding: "5px 0" }}
                      >
                        <IonCol size="4" className="ion-text-right">
                          Količina:
                        </IonCol>
                        <IonCol style={{ paddingLeft: 5 }}>
                          <div className="b vertical-center">
                            <span style={{ paddingRight: 10 }}>
                              {c.quantity}
                            </span>
                            <IonIcon
                              icon={removeCircle}
                              color={
                                c.quantity > 1
                                  ? "primary"
                                  : "var(--ion-color-light)"
                              }
                              onClick={() =>
                                updateItem({
                                  ...c,
                                  quantity: c.quantity > 1 ? c.quantity - 1 : 1,
                                })
                              }
                              size="large"
                            />
                            <IonIcon
                              icon={addCircle}
                              color="primary"
                              onClick={() =>
                                updateItem({
                                  ...c,
                                  quantity: c.quantity + 1,
                                })
                              }
                              size="large"
                            />
                          </div>
                        </IonCol>
                      </IonRow>
                      {
                        <IonRow className="vertical-center">
                          <IonCol size="4" className="ion-text-right">
                            <IonButton
                              size="small"
                              onClick={() =>
                                updateItem({
                                  ...c,
                                  isReservation: !c.isReservation,
                                })
                              }
                            >
                              <IonIcon icon={alarm} style={{ fontSize: 15 }} />
                            </IonButton>
                          </IonCol>
                          <IonCol style={{ paddingLeft: 5 }}>
                            <div className="b">
                              {c.isReservation ? "REZERVACIJA" : ""}
                            </div>
                          </IonCol>
                        </IonRow>
                      }
                    </IonGrid>
                  </IonCol>
                  <IonCol size="3" className="ion-text-center">
                    {!isAdditionalEquipment(c.picture_id) && (
                      <img
                        src={`assets/pics/320/${
                          pic(c.picture_id, allPictures).code
                        }.jpg`}
                        alt={pic(c.picture_id, allPictures).title}
                        style={{ maxHeight: "80px" }}
                      />
                    )}
                    {isAdditionalEquipment(c.picture_id) && (
                      <img
                        src={`assets/pics/320/${getEquipmentFileName(
                          c.picture_id
                        )}.jpg`}
                        alt={pic(c.picture_id, allPictures).title}
                        style={{ maxHeight: "80px" }}
                      />
                    )}
                  </IonCol>
                  <IonCol size="1">
                    <IonIcon
                      icon={trash}
                      style={{ fontSize: 18, cursor: "pointer" }}
                      onClick={() => removeItem(c.picture_id)}
                    />
                  </IonCol>
                </IonRow>
              </IonGrid>
            </IonCard>
          ))}
        <IonCard className="ion-text-center">
          <IonButton onClick={() => setPickOpen(true)}>
            Dodaj proizvod
          </IonButton>
        </IonCard>
        <PickProduct
          isOpen={pickOpen}
          close={() => setPickOpen(false)}
          selectPicture={addItem}
        />
        <IonAlert
          buttons={[
            {
              text: "Ne",
              role: "cancel",
              handler: () => {
                debug("Cancel pressed");
                setShowUpdateCartSentWarning(undefined);
              },
            },
            {
              text: "Da",
              role: "confirm",
              handler: () => {
                debug("Confirm pressed");
                toggleCart();
              },
            },
          ]}
          header="Izmeni status poslatosti porudžbine?"
          isOpen={!!showUpdateCartSentWarning}
          message="Da li zaista želite da promenite status poslatosti porudžbine?"
        />
        <IonAlert
          buttons={[
            {
              text: "Ne",
              // cssClass: "alert-button-cancel",
              role: "cancel",
              handler: () => {
                debug("Cancel pressed");
                setShowLoseChanges(false);
              },
            },
            {
              text: "Da",
              // cssClass: "alert-button-confirm",
              role: "confirm",
              handler: () => {
                debug("Confirm pressed");
                close();
              },
            },
          ]}
          header="Zanemari izmene?"
          isOpen={showLoseChanges}
          message="Da li želite da zanemarite izmene?"
        />
        <div style={{ padding: "10px" }}>&nbsp;</div>
      </IonContent>
    </IonModal>
  );
};

export default EditOrder;
