import { FC, useEffect } from "react";
import {
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardSubtitle,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonLabel,
  IonMenuToggle,
  IonPage,
  IonRow,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { menu } from "ionicons/icons";
import { usePictures } from "../../Pictures/usePictures";
import "./Prices.css";
import { PriceEdit } from "./PriceEdit";
import { ADDITIONAL_EQUIPMENT_CATEGORY } from "../../../common/constants";

const Prices: FC = () => {
  const { allPicturesAdmin, getAllPicturesAdmin } = usePictures();

  useEffect(() => {
    if (allPicturesAdmin.length === 0) getAllPicturesAdmin();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const allPicturesFiltered = allPicturesAdmin.filter(
    (p) => p.category !== ADDITIONAL_EQUIPMENT_CATEGORY
  );

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="warning">
          <IonButtons slot="start">
            <IonMenuToggle>
              <IonIcon icon={menu} style={{ fontSize: 28 }} />
            </IonMenuToggle>
          </IonButtons>
          <IonTitle>Ažuriranje cena</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <IonGrid>
          <IonRow>
            {allPicturesFiltered
              .sort((a, b) => (a.code > b.code ? 1 : -1))
              .map((pic) => (
                <IonCol key={pic.id} sizeSm="2" sizeXs="6">
                  <IonCard style={{ margin: 0 }}>
                    <picture>
                      <source
                        media="(min-width:650px)"
                        srcSet={`assets/pics/${pic.code}.jpg`}
                      />
                      <img
                        src={`assets/pics/320/${pic.code}.jpg`}
                        alt={pic.fileName}
                        style={{ opacity: pic.isDeleted ? 0.2 : 1 }}
                      />
                    </picture>
                    <IonCardContent style={{ padding: 3 }}>
                      <IonCardSubtitle
                        style={{ fontSize: 12, textAlign: "center" }}
                      >
                        <IonLabel>{`${pic.code} - ${pic.title}`}</IonLabel>
                      </IonCardSubtitle>
                    </IonCardContent>
                    <PriceEdit pic={pic} />
                  </IonCard>
                </IonCol>
              ))}
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default Prices;
