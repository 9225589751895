import { NumberMoney } from "../NumberMoney/NumberMoney";

export const InfoKids = () => (
  <div>
    <p>Sadržaj seta ZA NAJMLAĐE</p>
    <p className="ion-text-left">
      <ul>
        <li>
          Slikarsko platno dimenzija 20x20cm sa odštampanim konturama izabranog
          motiva zategnutno na blind ramu
        </li>
        <li>Sve potrebne boje (akrilne), pripremljene i označene brojevima</li>
        <li>
          2 različite četkice (jedna za precizno bojenje i jedna za veća polja)
        </li>
        <li>2 kukice za kačenje na zid</li>
        <li>Uputstvo za upotrebu</li>
        <li>Račun - otpremnica</li>
        <br />
      </ul>
      Cena jednog seta za bojenje je <NumberMoney value={900} /> + poštarina.
    </p>
  </div>
);
