import { FC } from "react";
import {
  IonButtons,
  IonCard,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonModal,
  IonRow,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { arrowBack } from "ionicons/icons";
import { PackageContent, PackageDelivery } from "./Content";

interface Props {
  isOpen: boolean;
  onDismiss: () => void;
}

export const PackageContentModal: FC<Props> = ({ isOpen, onDismiss }) => (
  <IonModal isOpen={isOpen} onDidDismiss={onDismiss} showBackdrop={true}>
    <IonHeader>
      <IonToolbar color="primary">
        <IonButtons slot="start">
          <IonIcon
            className="f28"
            icon={arrowBack}
            onClick={onDismiss}
            style={{ paddingLeft: "5px" }}
          />
        </IonButtons>
        <IonTitle>Sadržaj seta</IonTitle>
      </IonToolbar>
    </IonHeader>
    <IonContent>
      <IonGrid className="ion-no-padding">
        <IonRow>
          <IonCol>
            <img src="assets/BPB_logo.png" alt="" className="slide-image" />
          </IonCol>
        </IonRow>
      </IonGrid>
      <div style={{ height: "fit-content" }}>
        <IonCard style={{ padding: "5px 15px" }}>
          <PackageContent showTitle={false} />
          <PackageDelivery />
        </IonCard>
      </div>
    </IonContent>
  </IonModal>
);
