import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonMenuToggle,
  IonPage,
  IonRow,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import {
  arrowForward,
  easel,
  easelOutline,
  menu,
  tabletLandscape,
} from "ionicons/icons";
import { NumberMoney } from "../../components/NumberMoney/NumberMoney";
import { CategorieLinks } from "../../components/CategorieLinks/CategorieLinks";
import { Link } from "react-router-dom";
import styled from "styled-components";
import SubHeader from "./SubHeader";
import ToolbarIcons from "../../components/ToolbarIcons/ToolbarIcons";
import { useCart } from "../Cart/useCart";
import Add2Cart from "./Add2Cart";
import { usePictures } from "../Pictures/usePictures";
import {
  PRICE_EASEL_WOOD,
  PRICE_FRAME,
  PRICE_FRAME_ASSEMBLED,
} from "../../common/constants";

const YTContainer = styled.div`
  height: 0;
  margin-bottom: 25px;
  margin-top: 12px;
  padding-bottom: 56.25%;
  position: relative;
  width: 100%;
`;

const Video = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const array1 = () => {
  const result: string[] = [];
  for (var i = 1; i <= 4; i++) {
    result.push(i < 10 ? `0${i}` : `${i}`);
  }
  return result;
};

const array2 = () => {
  const result: string[] = [];
  for (var i = 5; i <= 10; i++) {
    result.push(i < 10 ? `0${i}` : `${i}`);
  }
  return result;
};

const PackageContent = () => {
  const {
    easelWood,
    easelAlum,
    frames,
    framesAssembled,
    changeEaselWoodNumber,
    changeEaselAlumNumber,
    changeFrameNumber,
    changeFrameAssembledNumber,
  } = useCart();

  const { equipmentState } = usePictures();

  return (
    <IonPage>
      <IonHeader>
        <IonHeader>
          <IonToolbar color="primary">
            <IonButtons slot="start">
              <IonMenuToggle>
                <IonIcon icon={menu} style={{ fontSize: 28 }} />
              </IonMenuToggle>
            </IonButtons>
            <IonTitle>Blind ram i štafelaji</IonTitle>
            <ToolbarIcons />
          </IonToolbar>
        </IonHeader>
      </IonHeader>
      <IonContent>
        <IonGrid className="ion-no-padding">
          <IonRow>
            <IonCol size="12" sizeMd="6" offsetMd="3">
              <img src="assets/BPB_logo.png" alt="" className="slide-image" />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol size="12" sizeMd="8" offsetMd="2">
              <IonCard>
                <IonCardContent>
                  <SubHeader icon={tabletLandscape} text="Blind ram" onTop>
                    <NumberMoney value={PRICE_FRAME} />
                  </SubHeader>
                  <p className="f13 ion-text-justify">
                    U ponudi imamo i BLIND ramove dimezija 40x50cm.
                    <br />
                    <br />
                    BLIND RAM SE ŠALJE RASKLOPLJEN
                    <br />
                    <br />
                    Sa BLIND ramom imate sve što vam je potrebno da obojenu
                    sliku okačite na zid.
                    <br />
                    <br />
                    Sa blind ramom imate sve što vam je potrebno da obojenu
                    sliku okačite na zid. Takođe, samo bojenje je dosta lakše
                    kada je platno zategnuito na blind ramu.
                    <br />
                    Samo sastavljanje blind rama i zatezanje platna je vrlo
                    jednostavno (možete videti video prezentaciju ispod).
                    <br />
                    <br />
                    Uz BLIND ram dobijate:
                  </p>
                  <ul className="ion-text-left">
                    <li>4 lajsne</li>
                    <li>8 kajlica za fiksiranje rama</li>
                    <li>20 rajsnedli za kačenje platna</li>
                    <li>Uputstvo za sklapanje rama i zatezanje platna</li>
                  </ul>
                  <YTContainer>
                    <Video
                      src="https://www.youtube.com/embed/VNNnJVdsOSg"
                      title="YouTube video player"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    />
                  </YTContainer>
                  <Add2Cart
                    disabled={frames > 0 || !equipmentState.frame}
                    onClick={() => changeFrameNumber(1)}
                    textAdd="Dodaj blind ram u korpu"
                    textDisabled={
                      equipmentState.frame
                        ? "U korpi"
                        : "Trenutno nema na stanju"
                    }
                  />
                </IonCardContent>
              </IonCard>
              <IonCard>
                <IonCardContent>
                  <SubHeader
                    icon={tabletLandscape}
                    text="Blind ram - sklopljen za zategnutim platnom (motivom)"
                    onTop
                  >
                    <NumberMoney value={PRICE_FRAME_ASSEMBLED} />
                  </SubHeader>
                  <p className="f13 ion-text-justify">
                    Postoji i mogućnost uslužnog sklapanja blind rama i slanja
                    platna zategnutog na blind ramu.
                    <br />
                    Cena usluge je {PRICE_FRAME_ASSEMBLED - PRICE_FRAME} din.
                    <br />
                    <br />
                  </p>
                  <Add2Cart
                    disabled={
                      framesAssembled > 0 || !equipmentState.frameAssembled
                    }
                    onClick={() => changeFrameAssembledNumber(1)}
                    textAdd="Dodaj sklopljen blind ram"
                    textDisabled={
                      equipmentState.frameAssembled
                        ? "U korpi"
                        : "Trenutno nema na stanju"
                    }
                  />
                </IonCardContent>
              </IonCard>
              <IonCard>
                <IonCardContent>
                  <SubHeader icon={easelOutline} text="Drveni štafelaj" onTop>
                    <NumberMoney value={PRICE_EASEL_WOOD} />
                  </SubHeader>

                  <p className="f13 ion-text-justify">
                    Mali drveni štafelaj pogodan je za one koji slikaju na
                    stolu, omogućava lakše slikanje i manji umor ruke.
                  </p>

                  <IonGrid className="ion-no-padding">
                    <IonRow>
                      {array1().map((n) => (
                        <IonCol
                          key={n}
                          size="12"
                          sizeMd="3"
                          className="ion-no-padding"
                        >
                          <IonCard>
                            <img
                              src={`assets/frame/${n}.jpg`}
                              alt="Drveni štafelaj"
                              style={{ width: "100%" }}
                            />
                          </IonCard>
                        </IonCol>
                      ))}
                    </IonRow>
                  </IonGrid>

                  <Add2Cart
                    disabled={easelWood > 0 || !equipmentState.easelW}
                    onClick={() => changeEaselWoodNumber(1)}
                    textAdd="Dodaj drveni štafelaj u korpu"
                    textDisabled={
                      equipmentState.easelW
                        ? "U korpi"
                        : "Trenutno nema na stanju"
                    }
                  />
                </IonCardContent>
              </IonCard>
              <IonCard>
                <IonCardContent>
                  <SubHeader icon={easel} text="Aluminijumski štafelaj" onTop>
                    <NumberMoney value={1900} />
                  </SubHeader>

                  <p className="f13 ion-text-justify">
                    Veliki aluminijumski štafelaj, sa podesivim nožicama, veoma
                    je lagan za podešavanje. Brzo i jednostavno se montira na
                    željenu visinu. Uz štafelaj dobija se i torbica za lakši
                    prenos.
                    <br />
                  </p>

                  <YTContainer>
                    <Video
                      src="https://www.youtube.com/embed/sRSf3oDfOcE"
                      title="YouTube video player"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    />
                  </YTContainer>

                  <IonGrid className="ion-no-padding">
                    <IonRow>
                      {array2().map((n) => (
                        <IonCol
                          key={n}
                          size="12"
                          sizeMd="3"
                          className="ion-no-padding"
                        >
                          <IonCard>
                            <img
                              src={`assets/frame/${n}.jpg`}
                              alt="Aluminijumski štafelaj"
                              style={{ width: "100%" }}
                            />
                          </IonCard>
                        </IonCol>
                      ))}
                    </IonRow>
                  </IonGrid>

                  <Add2Cart
                    disabled={easelAlum > 0 || !equipmentState.easelA}
                    onClick={() => changeEaselAlumNumber(1)}
                    textAdd="Dodaj aluminijumski štafelaj u korpu"
                    textDisabled={
                      equipmentState.easelA
                        ? "U korpi"
                        : "Trenutno nema na stanju"
                    }
                  />
                </IonCardContent>
              </IonCard>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <CategorieLinks />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol className="ion-text-center">
              <Link to={{ pathname: `/primeri` }}>
                <IonButton fill="clear" style={{ margin: "10px 0 20px 0" }}>
                  Primeri
                  <IonIcon slot="end" icon={arrowForward} />
                </IonButton>
              </Link>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default PackageContent;
