import { combineReducers } from "redux";
import { adminReducer } from "./Admin/admin.reducer";
import { cartReducer } from "./Cart/cart.reducer";
import { commonReducer } from "../common/common.reducer";
import { menuReducer } from "./Menu/menu.reducer";
import { picturesReducer } from "./Pictures/pictures.reducer";


export type AppState = ReturnType<typeof mainReducer>;

export const mainReducer = combineReducers({
  admin: adminReducer,
  common: commonReducer,
  cart: cartReducer,
  menu: menuReducer,
  pictures: picturesReducer,
});