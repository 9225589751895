import React, { useState } from "react";
import { IonButton, IonButtons, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonInput, IonItem, IonLabel, IonList, IonMenuToggle, IonPage, IonRow, IonTitle, IonToolbar } from "@ionic/react";
import { menu } from "ionicons/icons";
import { Link } from "react-router-dom";
import { useCommon } from "../../../common/useCommon";
import { useAdmin } from "../useAdmin";

export const Login = () => {
  useCommon();
  const [loginText, setLoginText] = useState<string>("");
  const { isLoggedIn, setIsLoggedIn } = useAdmin();

  const tryLogin = () => {
    if (btoa(loginText) === "QlBCYnBi") {
      setIsLoggedIn(true);
    }
  }

  return <IonPage>
    <IonHeader>
      <IonToolbar color="warning">
        <IonButtons slot="start">
          <IonMenuToggle>
            <IonIcon icon={menu} style={{ fontSize: 28 }} />
          </IonMenuToggle>
        </IonButtons>
        <IonTitle>Administracija</IonTitle>
      </IonToolbar>
    </IonHeader>
    <IonContent>
      <div className="vertical-center" style={{ height: "80vh" }}>
        <IonGrid>
          <IonRow>
            <IonCol sizeMd="6" offsetMd="3">
              {!isLoggedIn && <IonList>
                <IonItem>
                  <IonLabel color="medium" slot="end">Admin Login</IonLabel>
                  <IonInput
                    onIonChange={(e: any) => setLoginText(e.detail.value)}
                    type="password"
                    value={loginText}
                  />
                </IonItem>
              </IonList>}
              {!isLoggedIn && loginText.length === 0 && <IonButton
                color="primary"
                expand="block"
                disabled>Login</IonButton>
              }
              {!isLoggedIn && loginText.length > 0 && <Link to={{ pathname: "/adminOrders" }} style={{ textDecoration: "none", color: "var(--ion-color-dark)" }} >
                <IonButton
                  color="primary"
                  expand="block"
                  onClick={() => tryLogin()}>Login</IonButton>
              </Link>}
              {isLoggedIn && <IonButton
                color="danger"
                expand="block"
                onClick={() => setIsLoggedIn(false)}>
                Logout</IonButton>}
            </IonCol>
          </IonRow>
        </IonGrid>
      </div>
    </IonContent>
  </IonPage>
}
