import axios from "axios";
import appconfig from "../../../../AppConfig";
import { debug } from "../../../../common/utils";
import { ICart } from "../../../../models";
import { UPDATE_CART_SENT_2_SP } from "../../admin.actions";

export const sendCart2SPApi = async (dispatch: any, cart: ICart) => {
  const url = "cart/sendCart2SP";
  const data = { ...cart, note: `LOMLJIVO\n${cart.note}` };
  const config = {
    baseURL: appconfig.baseURL,
  };

  await axios
    .post(url, data, config)
    .then((response: any) => {
      debug("!!! API - sendCart2SPApi !!!");

      debug(response.data?.result);

      if (response && response.data?.result === "SENT-TO-SP!") {
        dispatch({
          type: UPDATE_CART_SENT_2_SP,
          cart: data,
        });
      }

      debug(response);
    })
    .catch((err) => {
      console.log(err);
      // alert(err);
    });
};
