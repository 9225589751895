import { ICart, ICartItem } from "../../models";
import { ActionType } from "../../util/types";
import { TOrdersTab } from "./admin.reducer";

export const CHANGE_TAB = "CHANGE_TAB";
export const SET_LOGGED_IN = "SET_LOGGED_IN";
export const GET_CART_ORDERS_PAGED = "GET_CART_ORDERS_PAGED";
export const GET_CART_ORDERS_PAGED_REFRESH = "GET_CART_ORDERS_PAGED_REFRESH";
export const GET_CART_ORDER_ITEMS = "GET_CART_ORDER_ITEMS";
export const SELECT_CART = "SELECT_CART";
export const UPDATE_CART_SENT = "UPDATE_CART_SENT";
export const UPDATE_CART_SENT_2_SP = "UPDATE_CART_SENT_2_SP";
export const UPDATE_CART_HANDLED = "UPDATE_CART_HANDLED";
export const UPDATE_CART = "UPDATE_CART";

export const changeTab = (tab: TOrdersTab) =>
  ({
    type: CHANGE_TAB,
    tab,
  } as const);

export const setLoggedIn = (isLoggedIn: boolean) =>
  ({
    type: SET_LOGGED_IN,
    isLoggedIn,
  } as const);

export const getCartOrdersPaged = (
  skip: number,
  take: number,
  data: ICart[],
  total: number
) =>
  ({
    type: GET_CART_ORDERS_PAGED,
    skip,
    take,
    total,
    data,
  } as const);

export const getCartOrdersPagedRefresh = (
  take: number,
  data: ICart[],
  total: number
) =>
  ({
    type: GET_CART_ORDERS_PAGED_REFRESH,
    take,
    total,
    data,
  } as const);

export const getCartOrderItems = (cartId: number, data: ICartItem[]) =>
  ({
    type: GET_CART_ORDER_ITEMS,
    cartId,
    data,
  } as const);

export const selectCart = (cart: ICart) =>
  ({
    type: SELECT_CART,
    cart,
  } as const);

export const updateCart = (editedCart: ICart) =>
  ({
    type: UPDATE_CART,
    editedCart,
  } as const);

export const updateCartSent = (cart: ICart) =>
  ({
    type: UPDATE_CART_SENT,
    cart,
  } as const);

export const updateCartSent2SP = (cart: ICart) =>
  ({
    type: UPDATE_CART_SENT_2_SP,
    cart,
  } as const);

export const updateCartHandled = (cart: ICart) =>
  ({
    type: UPDATE_CART_HANDLED,
    cart,
  } as const);

export type AdminActions =
  | ActionType<typeof changeTab>
  | ActionType<typeof setLoggedIn>
  | ActionType<typeof getCartOrdersPaged>
  | ActionType<typeof getCartOrdersPagedRefresh>
  | ActionType<typeof getCartOrderItems>
  | ActionType<typeof selectCart>
  | ActionType<typeof updateCart>
  | ActionType<typeof updateCartSent2SP>
  | ActionType<typeof updateCartSent>
  | ActionType<typeof updateCartHandled>;
