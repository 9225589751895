import { IPicture, ICategory } from "../../models";
import {
  IonCard,
  IonCardTitle,
  IonCardSubtitle,
  IonIcon,
  IonText,
  IonCardContent,
  IonGrid,
  IonRow,
  IonCol,
  IonButton,
  IonChip,
  IonLabel,
} from "@ionic/react";
import { ellipse, trash, removeCircle, addCircle } from "ionicons/icons";
import { connect } from "react-redux";
import { AppState } from "../main.reducer";
import { CHANGE_CART } from "./cart.actions";
import { SELECT_PICTURE } from "../Pictures/pictures.actions";
import { SELECT_CATEGORY } from "../Menu/menu.actions";
import { IconCategory } from "../Pictures/PictureCard";
import { Price } from "../../components/Price/Price";
import { DiscountBadge } from "../../components/DiscountBadge/DicsountBadge";

type OwnProps = {
  pic: IPicture;
  isPopup?: boolean;
  // favorites: number[];
  // setFavorites: (id: number[]) => void;
};

const mapStateToProps = (state: AppState, props: OwnProps) => ({
  // match: props.match,
  availableOnly: state.pictures.availableOnly,
  cart: state.cart.cart,
  categories: state.menu.categories as ICategory[],
  darkMode: state.common.darkMode,
});

const mapDispatchToProps = (dispatch: (action: any) => any) => ({
  selectPicture: (picture: IPicture) =>
    dispatch({ type: SELECT_PICTURE, picture }),
  selectCategory: (category: ICategory) =>
    dispatch({ type: SELECT_CATEGORY, category }),
  changeCart: (
    cart: { [pictureId: number]: number },
    pictureId: number,
    quantity: number
  ) => {
    const cartState = { ...cart, [pictureId]: quantity };
    localStorage.setItem("bpb.cart", JSON.stringify(cartState));
    dispatch({ type: CHANGE_CART, pictureId, quantity });
  },
});

type AllProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  OwnProps;

const CartItem = (props: AllProps) => {
  const { pic, cart } = props;

  const categoryName = (id: number) => {
    const category = props.categories.find((c) => c.id === id);
    return category ? category.name : "";
  };

  const removeFromCart = (id: number) => {
    if (props.cart[pic.id] > 1) {
      props.changeCart(cart, id, props.cart[id] - 1 || 0);
    }
  };

  // const findCategory = (id: number) => {
  //     return props.categories.find(c => c.id === id) || {} as ICategory;
  // }

  // const isFavorite = useMemo(() => (id: number) => favorites.indexOf(id) >= 0, [favorites]);

  return (
    <IonCard style={{ margin: "3px" }}>
      <picture>
        <source
          media="(min-width:320px)"
          srcSet={`assets/pics/${pic.code}.jpg`}
        />
        <img
          src={`assets/pics/320/${pic.code}.jpg`}
          alt={pic.fileName}
          onClick={() => props.selectPicture(pic)}
        />
      </picture>
      <DiscountBadge price={pic.price} priceNoDiscount={pic.priceNoDiscount} />

      <IonCardTitle
        className="ion-text-center f20xs"
        style={{ padding: "5px 0" }}
      >
        {pic.title}
      </IonCardTitle>

      <IonCardContent style={{ padding: 5 }}>
        <IonGrid>
          <IonRow>
            <IonCol size="8">
              <IonText>
                <IonIcon
                  icon={ellipse}
                  style={{
                    paddingRight: 5,
                    color: pic.status
                      ? "var(--ion-color-success-shade)"
                      : "var(--ion-color-danger-shade)",
                  }}
                />
                <IonText className="f15">
                  {pic.status ? "Na stanju" : "Trenutno nema na stanju"}
                </IonText>
              </IonText>
            </IonCol>
            <IonCol size="4" className="ion-text-right">
              <Price price={pic.price} priceNoDiscount={pic.priceNoDiscount} />
            </IonCol>
          </IonRow>
        </IonGrid>

        <IonCardSubtitle>
          <IonChip style={{ cursor: "default" }}>
            <IconCategory category={pic.category} />
            <IonLabel>{categoryName(pic.category)}</IonLabel>
          </IonChip>
        </IonCardSubtitle>

        <IonGrid>
          <IonRow>
            {/* <IonCol size="7">
              {pic.sizeX && pic.sizeY && <IonText>{`Dimenzije: ${pic.sizeX}cm x ${pic.sizeY}cm`}</IonText>}
            </IonCol> */}
            <IonCol size="12" className="ion-text-right">
              <IonText>{`Šifra: ${pic.code}`}</IonText>
            </IonCol>
          </IonRow>
        </IonGrid>

        <IonGrid>
          <IonRow>
            <IonCol className="ion-text-center">Količina</IonCol>
          </IonRow>
          <IonRow>
            <IonCol className="ion-text-right">
              <IonIcon
                icon={removeCircle}
                size="large"
                color={
                  props.cart[pic.id] === 1
                    ? "var(--ion-color-light)"
                    : "primary"
                }
                onClick={() => removeFromCart(pic.id)}
                className={props.cart[pic.id] === 1 ? "" : "cursorP"}
              />
            </IonCol>
            <IonCol className="ion-text-center f18 b" style={{ paddingTop: 5 }}>
              <IonLabel>{props.cart[pic.id]}</IonLabel>
            </IonCol>
            <IonCol className="ion-text-left">
              <IonIcon
                icon={addCircle}
                size="large"
                color="primary"
                onClick={() =>
                  props.changeCart(cart, pic.id, props.cart[pic.id] + 1 || 1)
                }
                className="cursorP"
              />
            </IonCol>
          </IonRow>
        </IonGrid>

        {/* <IonGrid onClick={() => favoriteToggle(pic.id)} className="cursorP">
          <IonRow>
            <IonCol size="10" style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
              <IonText>{isFavorite(pic.id) ? "Omiljena" : "Dodaj u omiljene"}</IonText>
            </IonCol>
            <IonCol size="2" className="ion-text-center" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
              <IonIcon
                color={isFavorite(pic.id) ? "danger" : "dark"}
                icon={isFavorite(pic.id) ? heart : heartOutline}
                // onClick={() => favoriteToggle(pic.id)}
                style={{ cursor: "pointer", fontSize: 26 }}
              />
            </IonCol>
          </IonRow>
        </IonGrid> */}

        <IonGrid>
          <IonRow>
            <IonCol size="12" offset="0">
              <IonButton
                color="danger"
                expand="block"
                fill="outline"
                onClick={() => props.changeCart(cart, pic.id, 0)}
              >
                <IonIcon icon={trash} size="medium" slot="start" />
                Izbaci iz korpe
              </IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonCardContent>
    </IonCard>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(CartItem);
