import React from "react";
import { IonChip, IonLabel, IonSpinner } from "@ionic/react";
import { Link } from "react-router-dom";
import { IconCategory } from "../../pages/Pictures/PictureCard";
import { useCategories } from "../../pages/Pictures/useCategories";

export const CategorieLinks = () => {
  const { categories, findCategoryById, selectCategory, categoryName } =
    useCategories();

  return (
    <div className="ion-text-center">
      <Link
        to={{ pathname: `/slike/svi` }}
        style={{ textDecoration: "none", color: "var(--ion-color-dark)" }}
        target="_self"
        onClick={() => selectCategory(findCategoryById(0))}
      >
        <IonChip>
          <IconCategory category={0} />
          <IonLabel>{categoryName(0)}</IonLabel>
        </IonChip>
      </Link>
      <br />
      {categories.map((c) => (
        <Link
          key={c.id}
          to={{ pathname: `/slike/${findCategoryById(c.id).code}` }}
          style={{ textDecoration: "none", color: "var(--ion-color-dark)" }}
          target="_self"
          onClick={() => selectCategory(findCategoryById(c.id))}
        >
          <IonChip>
            <IconCategory category={c.id} />
            <IonLabel>{categoryName(c.id)}</IonLabel>
          </IonChip>
        </Link>
      ))}
      {categories.length === 0 && <IonSpinner style={{ paddingTop: 60 }} />}
    </div>
  );
};
