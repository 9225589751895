import React from "react";
import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCol,
  IonGrid,
  IonIcon,
  IonRow,
  IonText,
  IonTitle,
} from "@ionic/react";
import {
  alarm,
  arrowForward,
  bagAdd,
  cartOutline,
  images,
} from "ionicons/icons";
import { useCart } from "./useCart";
import CartItem from "./CartItem";
import ReserveItem from "./ReserveItem";
import { useCommon } from "../../common/useCommon";
import SetInfo from "./SetInfo";
import Frame from "./Frame";
import EaselAluminium from "./EaselAluminium";
import EaselWood from "./EaselWood";
import { Link } from "react-router-dom";
import FrameAssembled from "./FrameAssembled";
import FramesNumberWarning from "./FramesNumberWarning";

const SubTitle = ({
  bg,
  icon,
  text,
}: {
  bg: string;
  icon: string;
  text: string;
}) => (
  <IonGrid style={{ backgroundColor: bg }}>
    <IonRow>
      <IonCol size="2">
        <IonIcon icon={icon} color="light" size="large" />
      </IonCol>
      <IonCol size="8" className="vertical-center">
        <IonTitle color="light" className="f18 b ion-text-center">
          {text}
        </IonTitle>
      </IonCol>
    </IonRow>
  </IonGrid>
);

export const CartInfo = () => {
  const { cartPictures, cartReservations, isCartEmpty } = useCart();
  const { zoom } = useCommon();

  return (
    <>
      {isCartEmpty && (
        <IonCard>
          <IonCardContent className="ion-text-center">
            <IonIcon icon={cartOutline} style={{ fontSize: 130 }} />
            <IonCardHeader className="ion-text-center">
              <h1>Vaša korpa je prazna</h1>
            </IonCardHeader>
            <Link to="/slike/svi">
              <IonButton>Pregled svih motiva</IonButton>
            </Link>
          </IonCardContent>
        </IonCard>
      )}

      {cartPictures.length > 0 && (
        <>
          <SubTitle
            bg="var(--ion-color-secondary)"
            icon={images}
            text="Odabrani motivi"
          />

          <IonGrid className="ion-no-padding">
            <IonRow>
              {cartPictures.map((pic) => (
                <IonCol
                  key={pic.id}
                  sizeSm={(12 / zoom).toString()}
                  sizeXs={(12 / (zoom > 2 ? 2 : zoom)).toString()}
                >
                  <CartItem pic={pic} />
                </IonCol>
              ))}
            </IonRow>
          </IonGrid>
        </>
      )}

      <SubTitle
        bg="var(--ion-color-secondary)"
        icon={bagAdd}
        text="Informacije i dodatna oprema"
      />

      <IonGrid>
        <IonRow>
          <IonCol size="12" sizeMd="12">
            <SetInfo />
            <FramesNumberWarning />
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol size="12" sizeMd="6">
            <Frame />
          </IonCol>
          <IonCol size="12" sizeMd="6">
            <FrameAssembled />
          </IonCol>
        </IonRow>
      </IonGrid>

      <IonGrid>
        <IonRow>
          <IonCol className="center">
            <Link to={{ pathname: `/ram` }}>
              <IonButton fill="clear" style={{ marginBottom: 10 }}>
                Više informacija o blind ramu
                <IonIcon slot="end" icon={arrowForward} />
              </IonButton>
            </Link>
          </IonCol>
        </IonRow>
      </IonGrid>

      <IonGrid>
        <IonRow>
          <IonCol size="12" sizeMd="6">
            <EaselWood />
          </IonCol>
          <IonCol size="12" sizeMd="6">
            <EaselAluminium />
          </IonCol>
        </IonRow>
      </IonGrid>

      <IonGrid>
        <IonRow>
          <IonCol className="center">
            <Link to={{ pathname: `/ram` }}>
              <IonButton fill="clear" style={{ marginBottom: 10 }}>
                Više informacija o štafelajima
                <IonIcon slot="end" icon={arrowForward} />
              </IonButton>
            </Link>
          </IonCol>
        </IonRow>
      </IonGrid>

      {cartReservations.length > 0 && (
        <SubTitle
          bg="var(--ion-color-warning)"
          icon={alarm}
          text="Rezervacije"
        />
      )}

      {cartReservations.length > 0 && (
        <IonCard style={{ margin: "3px" }}>
          <IonCardContent>
            <IonText>
              Kontaktiraćemo vas čim rezervisani setovi, koji trenutno nisu na
              stanju, stignu na kod nas. Nove količine nabavljamo redovno.
            </IonText>
          </IonCardContent>
        </IonCard>
      )}

      {cartReservations.length > 0 && (
        <IonGrid className="ion-no-padding">
          <IonRow>
            {cartReservations.map((pic) => (
              <IonCol
                key={pic.id}
                sizeSm={(12 / zoom).toString()}
                sizeXs={(12 / (zoom > 2 ? 2 : zoom)).toString()}
              >
                <ReserveItem pic={pic} />
              </IonCol>
            ))}
          </IonRow>
        </IonGrid>
      )}
    </>
  );
};
