import appconfig from "../AppConfig";
import { ICartItem, IPicture } from "../models";
import {
  ADDITIONAL_EQUIPMENT_IDs,
  ID_EASEL_ALUM,
  ID_EASEL_WOOD,
  ID_FRAME,
  ID_FRAME_ASSEMBLED,
} from "./constants";

export const isEmpty = (obj: any) => Object.keys(obj).length === 0;

export const debug = (obj: any, sender?: string) => {
  if (appconfig.debug) {
    if (sender) {
      console.log("====" + sender + "====");
    }
    console.log(obj);
  }
};

export const getLocalBool = (key: string): boolean => {
  const storageString = localStorage.getItem(key);
  return storageString ? JSON.parse(storageString) : false;
};

export const getLocalObject = (key: string) => {
  const storageString = localStorage.getItem(key);
  let result = undefined;
  try {
    result = storageString ? JSON.parse(storageString) : undefined;
  } catch (e) {
    console.log(e);
  }
  return result ? result : undefined;
};

export const isAdditionalEquipment = (pictureId: number): boolean => {
  return ADDITIONAL_EQUIPMENT_IDs.includes(pictureId);
};

export const getEquipmentFileName = (pictureId: number): string => {
  switch (pictureId) {
    case ID_FRAME:
      return "FRAME";
    case ID_FRAME_ASSEMBLED:
      return "FRAME";
    case ID_EASEL_WOOD:
      return "EASEL-WOOD";
    case ID_EASEL_ALUM:
      return "EASEL-ALUMINIUM";
    default:
      return "ERROR";
  }
};

export const calcTotal = (items: ICartItem[]): number => {
  if (!items || items.length === 0) {
    return 0;
  }
  let sum = 0;
  items.forEach((item) => {
    if (!item.isReservation) {
      sum = sum + item.price * item.quantity;
    }
  });
  return sum;
};

export const pic = (id: number, allPictures: IPicture[]): IPicture =>
  (allPictures && allPictures.find((p) => p.id === id)) || ({} as IPicture);

export const getCode = (picture_id: number, allPictures: IPicture[]) => {
  if (picture_id === ID_FRAME) {
    return "RAM";
  } else if (picture_id === ID_FRAME_ASSEMBLED) {
    return "RAM - SKLOPLJEN";
  } else if (picture_id === ID_EASEL_WOOD) {
    return "DRVENI ŠTAFELAJ";
  } else if (picture_id === ID_EASEL_ALUM) {
    return "ALUMINIJUMSKI ŠTAFELAJ";
  }
  return pic(picture_id, allPictures).code;
};
