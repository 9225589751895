import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonMenuToggle,
  IonPage,
  IonRow,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { arrowForward, menu } from "ionicons/icons";
import { CategorieLinks } from "../../components/CategorieLinks/CategorieLinks";
import { Link } from "react-router-dom";
import { PackageContent, PackageDelivery } from "./Content";

const PackageContentPage = () => (
  <IonPage>
    <IonHeader>
      <IonHeader>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonMenuToggle>
              <IonIcon icon={menu} style={{ fontSize: 28 }} />
            </IonMenuToggle>
          </IonButtons>
          <IonTitle>Sadržina seta</IonTitle>
          <IonButtons slot="end">
            <Link to={{ pathname: "/slike" }}>
              <IonButton color="dark">Motivi</IonButton>
            </Link>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
    </IonHeader>
    <IonContent>
      <IonGrid className="ion-no-padding">
        <IonRow>
          <IonCol size="12" sizeMd="6" offsetMd="3">
            <img src="assets/BPB_logo.png" alt="" className="slide-image" />
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol size="12" sizeMd="8" offsetMd="2">
            <IonCard>
              <IonCardContent>
                <PackageContent />
                <PackageDelivery />
              </IonCardContent>
            </IonCard>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <CategorieLinks />
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol className="ion-text-center">
            <Link to={{ pathname: `/ram` }}>
              <IonButton fill="clear" style={{ margin: "10px 0 20px 0" }}>
                Ram i štafelaj
                <IonIcon slot="end" icon={arrowForward} />
              </IonButton>
            </Link>
          </IonCol>
        </IonRow>
      </IonGrid>
    </IonContent>
  </IonPage>
);

export default PackageContentPage;
