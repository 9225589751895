const LOCAL_DB = false; // !!!
const IS_DEBUG = false;
const ADMIN = false; // !!!

const debugGA = false;
const showDeliveryInfoNotification = true;

interface IAppConfig {
  baseURL: string;
  debug: boolean;
  admin: boolean;
  debugGA: boolean;
  showDeliveryInfoNotification: boolean;
}

const appconfig: IAppConfig = {
  // baseURL: LOCAL_DB ? "http://localhost:7585" : "http://api.bojenjepobrojevima.com",
  // baseURL: LOCAL_DB ? "http://localhost:7585" : "http://77.105.36.76/plesk-site-preview/api.bojenjepobrojevima.rs",
  // baseURL: LOCAL_DB ? "http://localhost:7585" : "https://api.bojenjepobrojevima.rs",
  baseURL: LOCAL_DB ? "http://localhost:7585" : "https://bojenjepobrojevima.rs",
  debug: IS_DEBUG,
  admin: ADMIN,
  debugGA,
  showDeliveryInfoNotification,
};

export default appconfig;
