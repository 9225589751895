import {
  IonButtons,
  IonCard,
  IonCheckbox,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonLabel,
  IonMenuToggle,
  IonPage,
  IonRow,
  IonSegment,
  IonSegmentButton,
  IonSpinner,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import {
  albums,
  checkmarkDoneCircle,
  mailUnreadOutline,
  menu,
  notifications,
} from "ionicons/icons";
import React, { useEffect, useState } from "react";
import Moment from "react-moment";
import { useCommon } from "../../../common/useCommon";
import { ICart } from "../../../models";
import { TOrdersTab } from "../admin.reducer";
import { useAdmin } from "../useAdmin";
import OrderDetails from "./OrderDetails";
import { Flex } from "../../../components/Flex/Flex";
import { ID_FRAME_ASSEMBLED } from "../../../common/constants";

const TAKE = 10;

const CartOrders = () => {
  useCommon();
  const {
    cartTab,
    isLoadingTab,
    cartOrders,
    hasMore,
    total,
    changeTab,
    getCartOrdersPaged,
    setSelectedCart,
    toggleCartHandled,
  } = useAdmin();

  const [isLoadingHandleID, setIsLoadingHandleID] = useState<
    number | undefined
  >();

  const isHandledToggle = async (e: any, cart: ICart) => {
    e.stopPropagation();
    setIsLoadingHandleID(cart.id);
    await toggleCartHandled(cart);
    setIsLoadingHandleID(undefined);
  };

  useEffect(() => {
    getCartOrdersPaged(cartTab, 0, TAKE);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartTab]);

  const showOrderItems = (cart?: ICart) => {
    if (cart) {
      setSelectedCart(cart);
    }
  };

  const searchNext = async ($event: CustomEvent<void>) => {
    await getCartOrdersPaged(cartTab, cartOrders.length, TAKE);
    ($event.target as HTMLIonInfiniteScrollElement).complete();
  };

  return (
    <IonPage id="cartOrders">
      <IonHeader>
        <IonToolbar color="warning">
          <IonButtons slot="start">
            <IonMenuToggle>
              <IonIcon icon={menu} style={{ fontSize: 28 }} />
            </IonMenuToggle>
          </IonButtons>
          <IonTitle>Pregled porudžbina</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonSegment
          value={cartTab}
          onIonChange={(e) => changeTab(e.detail.value as TOrdersTab)}
        >
          <IonSegmentButton value="new-orders">
            <IonIcon
              icon={notifications}
              color={cartTab === "new-orders" ? "primary" : "medium"}
            />
            <IonLabel>
              {cartTab === "new-orders" && !isLoadingTab
                ? `Nove (${total})`
                : "Nove"}
            </IonLabel>
          </IonSegmentButton>
          <IonSegmentButton value="done-manual">
            <IonIcon
              icon={checkmarkDoneCircle}
              color={cartTab === "done-manual" ? "primary" : "medium"}
            />
            <IonLabel>Preuzete</IonLabel>
          </IonSegmentButton>
          <IonSegmentButton value="slanje-paketa">
            <IonIcon
              icon={mailUnreadOutline}
              color={cartTab === "slanje-paketa" ? "primary" : "medium"}
            />
            <IonLabel>
              Slanje
              <br />
              paketa
            </IonLabel>
          </IonSegmentButton>
        </IonSegment>
        {isLoadingTab && (
          <div className="center">
            <IonSpinner
              name="dots"
              color="warning"
              style={{ width: 60, height: 60 }}
            />
          </div>
        )}
        {!isLoadingTab &&
          cartOrders.map((c) => (
            <IonCard key={c.id}>
              {/* <IonCardContent> */}
              <IonGrid onClick={() => showOrderItems(c)} className="cursorP">
                <IonRow>
                  <IonCol>ID</IonCol>
                  <IonCol className="b vertical-center">
                    <Flex
                      css={{ justifyContent: "space-between", width: "100%" }}
                    >
                      {5000 + (c.id || 0)}
                      <IonCheckbox
                        checked={!!c.is_handled}
                        disabled={isLoadingHandleID === c.id}
                        onClick={(e) => isHandledToggle(e, c)}
                      />
                    </Flex>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>Vreme porudžbine</IonCol>
                  <IonCol className="b">
                    <Moment format="DD.MM.YYYY. HH:mm">{c.date}</Moment>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>Ime i prezime</IonCol>
                  <IonCol className="b">{c.name}</IonCol>
                </IonRow>
                {cartTab !== "new-orders" && (
                  <IonRow>
                    <IonCol>Adresa</IonCol>
                    <IonCol className="b">{c.address}</IonCol>
                  </IonRow>
                )}
                <IonRow>
                  <IonCol>Mesto</IonCol>
                  <IonCol className="b">{c.place}</IonCol>
                </IonRow>
                <IonRow className="vertical-center">
                  <IonCol>Telefon</IonCol>
                  <IonCol className="b">{c.phone}</IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>Broj slika</IonCol>
                  <IonCol className="b">{c.cartItemsCount}</IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    {c.cartItems?.find(
                      (c) => c.picture_id === ID_FRAME_ASSEMBLED
                    ) !== undefined && (
                      <IonIcon icon={albums} color="warning" />
                    )}
                  </IonCol>
                  <IonCol
                    className="b"
                    style={{ color: "var(--ion-color-success)" }}
                  >
                    {c.package_sent
                      ? "PORUDŽBINA PREUZETA"
                      : c.package_sent_SP
                      ? "SLANJE PAKETA"
                      : ""}
                  </IonCol>
                </IonRow>
              </IonGrid>
              {/* </IonCardContent> */}
            </IonCard>
          ))}
        <IonInfiniteScroll
          threshold="100px"
          disabled={!hasMore}
          onIonInfinite={(e: CustomEvent<void>) => searchNext(e)}
        >
          <IonInfiniteScrollContent loadingText="Učitavanje..."></IonInfiniteScrollContent>
        </IonInfiniteScroll>
        <OrderDetails />
      </IonContent>
    </IonPage>
  );
};

export default CartOrders;
