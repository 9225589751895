import { IonIcon, IonText } from "@ionic/react";
import { informationCircle } from "ionicons/icons";
import styled from "styled-components";

const NotificationWrap = styled.div`
  margin: 5px auto 0px;
  padding: 4px;
  text-align: center;
`;

const MyChip = styled.div`
  background-color: var(--ion-color-light-shade);
  /* background-color: var(--ion-color-warning-tint); */
  /* background-color: #ffd948; */
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
`;

const Text = styled(IonText)`
  margin-left: 10px;
`;

interface Props {
  text?: string;
  content?: React.ReactNode;
}

const InfoMessage: React.FC<Props> = ({ text, content }) => (
  <NotificationWrap>
    <MyChip>
      {!content && (
        <>
          <IonIcon
            icon={informationCircle}
            color="primary"
            style={{ fontSize: 23 }}
          />
          {text && <Text>{text}</Text>}
        </>
      )}
      {content}
    </MyChip>
  </NotificationWrap>
);

export default InfoMessage;
