import React from "react";
import { IonCard, IonCol, IonGrid, IonRow, IonText } from "@ionic/react";
import {
  NumberDecimal,
  NumberMoney,
} from "../../components/NumberMoney/NumberMoney";
import { useCart } from "./useCart";
import {
  PRICE_EASEL_ALUMINIUM,
  PRICE_EASEL_WOOD,
  PRICE_FRAME,
  PRICE_FRAME_ASSEMBLED,
  PRICE_SHIPPING,
} from "../../common/constants";

interface IOrderPrice {
  deliveryInfo?: boolean;
}
export const OrderPriceDetails = (props: IOrderPrice) => {
  const {
    cartWeight,
    easelWood,
    easelAlum,
    frames,
    framesAssembled,
    reserveCount,
    pricePictures,
    minMaxPicturePrice,
    pricePicturesKids,
    minMaxPicturePriceKids,
    priceEaselsWood,
    priceEaselsAlum,
    priceFrames,
    priceFramesAssembled,
    priceTotal,
    countPrice,
    countPriceKids,
  } = useCart();

  return (
    <IonCard>
      <IonGrid style={{ padding: "10px 10px 0 10px" }}>
        <IonRow
          style={{
            backgroundColor: "var(--ion-color-light-tint)",
            borderRadius: "5px",
          }}
        >
          <IonCol size="3">OPIS</IonCol>
          <IonCol size="4" className="ion-text-right">
            CENA
          </IonCol>
          <IonCol size="2" className="ion-text-center">
            KOL.
          </IonCol>
          <IonCol size="3" className="ion-text-right">
            UKUPNO
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol size="5">Set za bojenje 40x50cm</IonCol>
          <IonCol size="2" className="ion-text-right">
            <NumberMoney
              minValue={minMaxPicturePrice().min}
              value={minMaxPicturePrice().max}
            />
          </IonCol>
          <IonCol size="2" className="ion-text-center">
            {countPrice}
          </IonCol>
          <IonCol size="3" className="ion-text-right">
            <NumberMoney value={pricePictures()} />
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol size="5">Set za bojenje 20x20cm sa blind ramom</IonCol>
          <IonCol size="2" className="ion-text-right">
            <NumberMoney
              minValue={minMaxPicturePriceKids().min}
              value={minMaxPicturePriceKids().max}
            />
          </IonCol>
          <IonCol size="2" className="ion-text-center">
            {countPriceKids}
          </IonCol>
          <IonCol size="3" className="ion-text-right">
            <NumberMoney value={pricePicturesKids()} />
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol size="5">Drveni (blind) ram 40x50cm</IonCol>
          <IonCol size="2" className="ion-text-right">
            <NumberMoney value={PRICE_FRAME} />
          </IonCol>
          <IonCol size="2" className="ion-text-center">
            {frames}
          </IonCol>
          <IonCol size="3" className="ion-text-right">
            <NumberMoney value={priceFrames()} />
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol size="5">Drveni (blind) ram 40x50cm - sklopljen</IonCol>
          <IonCol size="2" className="ion-text-right">
            <NumberMoney value={PRICE_FRAME_ASSEMBLED} />
          </IonCol>
          <IonCol size="2" className="ion-text-center">
            {framesAssembled}
          </IonCol>
          <IonCol size="3" className="ion-text-right">
            <NumberMoney value={priceFramesAssembled()} />
          </IonCol>
        </IonRow>
        {/* TO DO !!! */}
        {/* !!! PRICE_FRAME_PUT_TOGETHER */}
        {/* !!! fames_put_together */}
        {/* !!! priceFramesPutTogether() */}
        {/* <IonRow>
          <IonCol size="5">Usluga montiranja (blind) rama</IonCol>
          <IonCol size="2" className="ion-text-right">
            <NumberMoney value={PRICE_FRAME} />
          </IonCol>
          <IonCol size="2" className="ion-text-center">
            {frames}
          </IonCol>
          <IonCol size="3" className="ion-text-right">
            <NumberMoney value={priceFrames()} />
          </IonCol>
        </IonRow> */}
        <IonRow>
          <IonCol size="5">Drveni štafelaj</IonCol>
          <IonCol size="2" className="ion-text-right">
            <NumberMoney value={PRICE_EASEL_WOOD} />
          </IonCol>
          <IonCol size="2" className="ion-text-center">
            {easelWood}
          </IonCol>
          <IonCol size="3" className="ion-text-right">
            <NumberMoney value={priceEaselsWood()} />
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol size="5">Aluminijumski štafelaj</IonCol>
          <IonCol size="2" className="ion-text-right">
            <NumberMoney value={PRICE_EASEL_ALUMINIUM} />
          </IonCol>
          <IonCol size="2" className="ion-text-center">
            {easelAlum}
          </IonCol>
          <IonCol size="3" className="ion-text-right">
            <NumberMoney value={priceEaselsAlum()} />
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol size="5">
            <strong>Poštarina</strong>
          </IonCol>
          <IonCol size="2" className="ion-text-right">
            <NumberMoney value={PRICE_SHIPPING} />
          </IonCol>
          <IonCol size="2" className="ion-text-center">
            1
          </IonCol>
          <IonCol size="3" className="ion-text-right">
            <NumberMoney value={PRICE_SHIPPING} />
          </IonCol>
        </IonRow>
      </IonGrid>
      <IonGrid style={{ padding: "0 10px 10px 10px" }}>
        <IonRow
          style={{
            backgroundColor: "var(--ion-color-light-tint)",
            borderRadius: "5px",
          }}
        >
          <IonCol size="8">
            <span className="b">SVE UKUPNO (sa poštarinom)</span>
          </IonCol>
          <IonCol size="4" className="ion-text-right">
            <span className="b">
              <NumberMoney value={priceTotal()} />
            </span>
          </IonCol>
        </IonRow>
        {props.deliveryInfo && (
          <IonRow>
            <IonCol style={{ paddingTop: 15 }}>
              <IonText>
                Težina vaše porudžbine je oko:{" "}
                <span className="b">
                  <NumberDecimal value={cartWeight} /> kg
                </span>
              </IonText>
            </IonCol>
          </IonRow>
        )}
        <IonRow>
          <IonCol size="12">Broj rezervacija: {reserveCount}</IonCol>
        </IonRow>
      </IonGrid>
    </IonCard>
  );
};
