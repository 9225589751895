import React, { useEffect, useState } from "react";
import {
  IonButton,
  IonButtons,
  IonCard,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonLabel,
  IonModal,
  IonRow,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { alarm, arrowBack, copy, create, image, images } from "ionicons/icons";
import Moment from "react-moment";
import {
  calcTotal,
  getCode,
  getEquipmentFileName,
  isAdditionalEquipment,
  pic,
} from "../../../common/utils";
import { NumberMoney } from "../../../components/NumberMoney/NumberMoney";
import { ICart, ICartItem } from "../../../models";
import { usePictures } from "../../Pictures/usePictures";
import { useAdmin } from "../useAdmin";
import { PRICE_SHIPPING } from "../../../common/constants";
import EditOrder from "./EditOrder";
import { useSP } from "../SlanjePaketa/useSP";
import { EnrichCartItems4SP } from "../SlanjePaketa/mapper";

const OrderDetails: React.FC = () => {
  const {
    getOrderItems,
    selectedCart,
    setSelectedCart,
    selectedOrderItems,
    toggleCartSent,
  } = useAdmin();

  const { allPictures, getAllPictures } = usePictures();

  const [showEdit, setShowEdit] = useState(false);

  const { sendOrder } = useSP();

  useEffect(() => {
    if (allPictures.length === 0) {
      getAllPictures();
    }
  }, []);

  useEffect(() => {
    if (selectedCart && selectedCart.id > 0) {
      getOrderItems();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCart]);

  const PicNumber = (n: number) => (
    <>
      {n > 5 ? (
        <IonIcon
          icon={images}
          color="dark"
          style={{ fontSize: "20px", paddingLeft: 5 }}
        />
      ) : (
        [...Array(n)].map((i, index) => (
          <IonIcon
            key={index}
            icon={image}
            color={n === 1 ? "medium" : "dark"}
            style={{ fontSize: "20px", paddingLeft: 3 }}
          />
        ))
      )}
    </>
  );

  const send2SP = () => {
    if (selectedCart && !selectedCart.package_sent_SP) {
      // Add missing code(s) in ICartItem
      const selectedOrderItemsWithCodes = selectedOrderItems.map((item) => {
        const pic = allPictures.find((p) => p.id === item.picture_id);
        return {
          ...item,
          code: pic?.codeSP,
          category: pic?.category,
        } as ICartItem;
      });

      // Calculate item (total) price; Remove reservations
      // Enrich order with COLOR and MANUAL items; Remove reservations - DEPRICATED
      const cartItems = EnrichCartItems4SP(selectedOrderItemsWithCodes);

      // Add ICartItems to ICart (cartItems)
      const cart2sent = {
        ...selectedCart,
        cartItems,
      } as ICart;
      sendOrder(cart2sent);
    }
  };

  return (
    <IonModal
      isOpen={selectedCart !== undefined}
      onDidDismiss={() => setSelectedCart(undefined)}
      showBackdrop={true}
    >
      <IonHeader>
        <IonToolbar color="danger">
          <IonButtons slot="start">
            <IonIcon
              className="f28"
              icon={arrowBack}
              onClick={() => setSelectedCart(undefined)}
              style={{ paddingLeft: "5px" }}
            />
          </IonButtons>
          <IonTitle>Detalji</IonTitle>
          <IonButtons slot="end" style={{ paddingRight: 15 }}>
            <IonIcon
              icon={create}
              style={{ fontSize: 28, cursor: "pointer" }}
              onClick={() => setShowEdit(true)}
            />
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <IonCard color="light-shade">
          {/* <IonCardContent> */}
          {selectedCart && (
            <IonGrid>
              <IonRow className="vertical-center">
                <IonCol>Slanje paketa</IonCol>
                <IonCol>
                  <IonButton
                    color={selectedCart.package_sent_SP ? "success" : "primary"}
                    disabled={
                      selectedCart.package_sent || selectedCart.package_sent_SP
                    }
                    onClick={send2SP}
                  >
                    {selectedCart.package_sent_SP ? "Poslato" : "Slanje paketa"}
                  </IonButton>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>ID</IonCol>
                <IonCol className="b">{5000 + (selectedCart.id || 0)}</IonCol>
              </IonRow>
              <IonRow>
                <IonCol>Vreme porudžbine</IonCol>
                <IonCol className="b">
                  <Moment format="DD.MM.YYYY. HH:mm">
                    {selectedCart.date}
                  </Moment>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <span style={{ lineHeight: "25px" }}>
                    Ime i prezime
                    <br />
                    Adresa
                    <br />
                    Poš. br. i mesto
                    <br />
                    Telefon
                    <br />
                    e-mail
                  </span>
                </IonCol>
                <IonCol className="b vertical-center">
                  <span style={{ lineHeight: "25px" }}>
                    {selectedCart.name}
                    <br />
                    {selectedCart.address}
                    <br />
                    {selectedCart.zip} {selectedCart.place}
                    <br />
                    <a href={`tel:${selectedCart.phone}`}>
                      {selectedCart.phone}
                    </a>
                    <br />
                    <a href={`mailto:${selectedCart.email}`}>
                      {selectedCart.email}
                    </a>
                  </span>
                  <IonButton
                    color="light"
                    size="small"
                    onClick={() => {
                      navigator.clipboard.writeText(
                        `${selectedCart.name}\n${selectedCart.address}\n${selectedCart.zip} ${selectedCart.place}\n${selectedCart.phone}\n${selectedCart.email}`
                      );
                    }}
                  >
                    <IonIcon icon={copy} />
                  </IonButton>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>Napomena</IonCol>
                <IonCol className="b">{selectedCart.note}</IonCol>
              </IonRow>
              <IonRow>
                <IonCol>Broj slika</IonCol>
                <IonCol className="b">{selectedCart.cartItemsCount}</IonCol>
              </IonRow>
              <IonRow>
                <IonCol>Ukupan iznos</IonCol>
                <IonCol className="b">
                  <NumberMoney
                    value={calcTotal(selectedOrderItems)}
                  ></NumberMoney>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>Ukupan iznos sa poštarinom</IonCol>
                <IonCol className="b">
                  <NumberMoney
                    value={calcTotal(selectedOrderItems) + PRICE_SHIPPING}
                    style={{ fontSize: 18, color: "#f00" }}
                  ></NumberMoney>
                </IonCol>
              </IonRow>
              <IonRow className="vertical-center">
                <IonCol>Preuzimanje porudžbine</IonCol>
                <IonCol className="ion-no-padding">
                  <IonButton
                    color={selectedCart.package_sent ? "success" : "primary"}
                    disabled={
                      selectedCart.package_sent || selectedCart.package_sent_SP
                    }
                    onClick={() => toggleCartSent(selectedCart)}
                  >
                    {selectedCart.package_sent ? "Preuzeto" : "Preuzmi"}
                  </IonButton>
                </IonCol>
              </IonRow>
              {/* -- DEPRICIATED --
              <IonRow>
                <IonCol>Račun</IonCol>
                <IonCol>
                  <a
                    href={`${appconfig.baseURL}/cart/getInvoice/?orderId=${selectedCart.id}&templateId=1`}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <IonButton color="light" size="small">
                      Račun - Vlada
                    </IonButton>
                  </a>
                  <a
                    href={`${appconfig.baseURL}/cart/getInvoice/?orderId=${selectedCart.id}&templateId=2`}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <IonButton color="light" size="small">
                      Račun - Caka
                    </IonButton>
                  </a>
                </IonCol>
              </IonRow> */}
            </IonGrid>
          )}
          {/* </IonCardContent> */}
        </IonCard>

        {allPictures.length > 0 &&
          selectedOrderItems &&
          selectedOrderItems.map((c: ICartItem, i: number) => (
            <IonCard
              key={c.picture_id}
              color={c.isReservation ? "dark" : "light"}
            >
              <IonGrid>
                <IonRow className="vertical-center">
                  <IonCol size="1" className="ion-text-center">
                    <div>{`${i + 1}.`}</div>
                  </IonCol>
                  <IonCol size="8">
                    <IonGrid className="ion-no-padding">
                      <IonRow
                        className="vertical-center"
                        style={{ padding: "5px 0" }}
                      >
                        <IonCol size="4" className="ion-text-right">
                          Šifra:
                        </IonCol>
                        <IonCol style={{ paddingLeft: 5 }}>
                          <div className="b">
                            {getCode(c.picture_id, allPictures)}
                          </div>
                          <IonLabel color={"medium"}>
                            {pic(c.picture_id, allPictures).codeSP}
                          </IonLabel>
                        </IonCol>
                      </IonRow>
                      <IonRow
                        className="vertical-center"
                        style={{ padding: "5px 0" }}
                      >
                        <IonCol size="4" className="ion-text-right">
                          Naziv:
                        </IonCol>
                        <IonCol style={{ paddingLeft: 5 }}>
                          <div className="b">
                            {pic(c.picture_id, allPictures).title}
                          </div>
                        </IonCol>
                      </IonRow>
                      <IonRow
                        className="vertical-center"
                        style={{ padding: "5px 0" }}
                      >
                        <IonCol size="4" className="ion-text-right">
                          Cena:
                        </IonCol>
                        <IonCol style={{ paddingLeft: 5 }}>
                          <div className="b">
                            <NumberMoney value={c.price} />
                          </div>
                        </IonCol>
                      </IonRow>
                      <IonRow
                        className="vertical-center"
                        style={{ padding: "5px 0" }}
                      >
                        <IonCol size="4" className="ion-text-right">
                          Količina:
                        </IonCol>
                        <IonCol style={{ paddingLeft: 5 }}>
                          <div className="b vertical-center">
                            <span style={{ paddingRight: 10 }}>
                              {c.quantity}
                            </span>
                            {PicNumber(c.quantity)}
                          </div>
                        </IonCol>
                      </IonRow>
                      {c.isReservation && (
                        <IonRow className="vertical-center">
                          <IonCol size="4" className="ion-text-right">
                            <IonIcon icon={alarm} style={{ fontSize: 15 }} />
                          </IonCol>
                          <IonCol style={{ paddingLeft: 5 }}>
                            <div className="b">
                              {c.isReservation ? "REZERVACIJA" : ""}
                            </div>
                          </IonCol>
                        </IonRow>
                      )}
                    </IonGrid>
                  </IonCol>
                  <IonCol size="3" className="ion-text-center">
                    {!isAdditionalEquipment(c.picture_id) && (
                      <img
                        src={`assets/pics/320/${
                          pic(c.picture_id, allPictures).code
                        }.jpg`}
                        alt={pic(c.picture_id, allPictures).title}
                        style={{ maxHeight: "80px" }}
                      />
                    )}
                    {isAdditionalEquipment(c.picture_id) && (
                      <img
                        src={`assets/pics/320/${getEquipmentFileName(
                          c.picture_id
                        )}.jpg`}
                        alt={pic(c.picture_id, allPictures).title}
                        style={{ maxHeight: "80px" }}
                      />
                    )}
                  </IonCol>
                </IonRow>
              </IonGrid>
            </IonCard>
          ))}
        <div style={{ padding: "30px" }}>&nbsp;</div>
        <EditOrder isOpen={showEdit} close={() => setShowEdit(false)} />
      </IonContent>
    </IonModal>
  );
};

export default OrderDetails;
