import { ICartItem } from "../../../models";

export const EnrichCartItems4SP = (cartItems: ICartItem[]) => {
  const products: ICartItem[] = [];
  cartItems.forEach((c) => {
    if (c.isReservation || !c.quantity) {
      return;
    }
    let enrichTotalPrice = 0;

    // ADD COLORS & KL (Kontrolni list)
    // if (c.code && c.category !== ADDITIONAL_EQUIPMENT_CATEGORY) {
    //   products.push({
    //     code: `${c.code} B`,
    //     price: 1 * c.quantity,
    //     quantity: c.quantity,
    //   } as ICartItem);
    //   enrichTotalPrice += 1 * c.quantity;

    // TEMPORARELY - UNTIL KL ARRIVES!!!
    // products.push({
    //   code: `${c.code} KL`,
    //   price: 1 * c.quantity,
    //   quantity: c.quantity,
    // } as ICartItem);
    // enrichTotalPrice += 1 * c.quantity;
    // }

    // ADD MANUALS
    // if (c.code === CODE_FRAME) {
    //   products.push({
    //     code: "MANUAL-FRAME",
    //     price: 1 * c.quantity,
    //     quantity: c.quantity,
    //   } as ICartItem);
    //   enrichTotalPrice += 1 * c.quantity;
    // } else if (c.code && c.category === KIDS_CATEGORY) {
    //   products.push({
    //     code: "MANUAL-KIDS",
    //     price: 1 * c.quantity,
    //     quantity: c.quantity,
    //   } as ICartItem);
    //   enrichTotalPrice += 1 * c.quantity;
    // } else if (c.category !== ADDITIONAL_EQUIPMENT_CATEGORY) {
    //   products.push({
    //     code: "MANUAL",
    //     price: 1 * c.quantity,
    //     quantity: c.quantity,
    //   } as ICartItem);
    //   enrichTotalPrice += 1 * c.quantity;
    // }

    // ADD PRODUCT (Picture)
    if (c.code) {
      products.push({
        code: c.code,
        price: c.price * c.quantity - enrichTotalPrice,
        quantity: c.quantity,
      } as ICartItem);
    }
  });

  // const customer = {
  //   name: cart.name,
  //   country: "Srbija",
  //   city: cart.place,
  //   address: cart.address,
  //   postal_code: cart.zip,
  //   phone: cart.phone,
  //   note: cart.note,
  //   email: cart.email,
  // } as ICustomerSP;

  // const courier_details = {
  //   pay: "RECIPIENT",
  //   recipient_method: "INDIVIDUAL",
  // } as ICourierDetails;

  // const cartSP: ICartSP = {
  //   custom_number: cart.id.toString(),
  //   // additional_price?: number;
  //   products,
  //   customer,
  //   courier_details,
  // };

  // return cartSP;

  return products;
};
