import React from "react";
import {
  IonModal,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonIcon,
  IonTitle,
  IonContent,
} from "@ionic/react";
import PictureCard from "./PictureCard";
import { arrowBackCircle } from "ionicons/icons";
import { usePictures } from "./usePictures";
import "./PicturePreview.css";

const PicturePreview: React.FC = () => {
  const { selectedPicture, selectPicture } = usePictures();

  const dismiss = () => {
    selectPicture(undefined);
  };

  return (
    <IonModal
      isOpen={selectedPicture !== undefined}
      onDidDismiss={dismiss}
      showBackdrop={true}
    >
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonIcon
              color="primary"
              className="f36"
              icon={arrowBackCircle}
              onClick={dismiss}
              style={{ paddingLeft: "5px" }}
            />
          </IonButtons>
          <IonTitle>{selectedPicture?.title}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent style={{ minHeight: "100vh" }}>
        <PictureCard pic={selectedPicture} isPopup={true} />
        <div style={{ padding: "20px" }}>&nbsp;</div>
      </IonContent>
    </IonModal>
  );
};

export default PicturePreview;
