import {
  IonButton,
  IonCard,
  IonCol,
  IonGrid,
  IonIcon,
  IonLabel,
  IonRow,
} from "@ionic/react";
import {
  addCircle,
  checkbox,
  removeCircle,
  squareOutline,
  tabletLandscape,
} from "ionicons/icons";
import { useCart } from "./useCart";
import { NumberMoney } from "../../components/NumberMoney/NumberMoney";
import SubHeader from "../FrameAndEasel/SubHeader";
import { PRICE_FRAME_ASSEMBLED } from "../../common/constants";
import { usePictures } from "../Pictures/usePictures";

const FrameAssembled = () => {
  const {
    framesAssembled,
    cartPictures,
    changeFrameAssembledNumber,
    framesAssembledAsPictures,
    framesAssembledAsPicturesToggle,
  } = useCart();

  const { equipmentState } = usePictures();
  const disabled = !equipmentState.frameAssembled;
  const allowAdd = !framesAssembledAsPictures && cartPictures.length;
  const allowRemove = framesAssembled > 0 && !framesAssembledAsPictures;

  return (
    <IonCard style={{ margin: "3px", padding: "13px 16px 0 16px" }}>
      <SubHeader
        icon={tabletLandscape}
        text="Drveni (blind) ram - sklopljen sa postavljenim platnom"
        onTop
        disabled={disabled}
      >
        <NumberMoney value={PRICE_FRAME_ASSEMBLED} />
      </SubHeader>

      {!disabled && (
        <IonGrid>
          <IonRow>
            <IonCol className="ion-text-right">
              <IonIcon
                className={allowRemove ? "cursorP" : "op5"}
                icon={removeCircle}
                size="large"
                color={allowRemove ? "primary" : "var(--ion-color-light)"}
                onClick={() => {
                  if (allowRemove) {
                    changeFrameAssembledNumber(framesAssembled - 1);
                  }
                }}
              />
            </IonCol>
            <IonCol className="ion-text-center f20 b" style={{ paddingTop: 7 }}>
              <IonLabel>{framesAssembled}</IonLabel>
            </IonCol>
            <IonCol className="ion-text-left">
              <IonIcon
                className={allowAdd ? "cursorP" : "op5"}
                color={allowAdd ? "primary" : "var(--ion-color-light)"}
                icon={addCircle}
                size="large"
                onClick={() => {
                  if (allowAdd) {
                    changeFrameAssembledNumber(framesAssembled + 1);
                  }
                }}
              />
            </IonCol>
          </IonRow>
          {cartPictures.length > 0 && (
            <IonRow>
              <IonCol className="center" style={{ justifyContent: "center" }}>
                <IonButton
                  color="success"
                  onClick={framesAssembledAsPicturesToggle}
                >
                  <IonIcon
                    icon={framesAssembledAsPictures ? checkbox : squareOutline}
                    color="light"
                    size="large"
                    slot="start"
                  />
                  Sklopljen ram za svaku sliku
                </IonButton>
              </IonCol>
            </IonRow>
          )}
        </IonGrid>
      )}
      {disabled && (
        <div className="b f15 ion-text-center" style={{ marginBottom: 12 }}>
          <IonLabel>Trenutno nema na stanju</IonLabel>
        </div>
      )}
    </IonCard>
  );
};

export default FrameAssembled;
