import { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ICart, ICartItem, IPicture } from "../../models";
import { AppState } from "../main.reducer";
import { IPictureQuantity } from "./cart.reducer";
import { orderApi } from "./api/orderApi";
import { resetCartThunk } from "./thunk/resetCartThunk";
import {
  CHANGE_EASEL_ALUM_NUMBER,
  CHANGE_EASEL_WOOD_NUMBER,
  CHANGE_FRAME_ASSEMBLED_NUMBER,
  CHANGE_FRAME_NUMBER,
  FRAMES_ASSEMBLED_AS_PICTURES,
  FRAMES_AS_PICTURES,
  HIDE_TOAST_CART_NOTIFICATION,
  LOAD_CART,
  LOAD_EASEL_ALUM,
  LOAD_EASEL_WOOD,
  LOAD_FRAMES,
  LOAD_FRAMES_ASSEMBLED,
  LOAD_RESERVE,
  SET_CART_PAGE,
} from "./cart.actions";
import {
  ADDITIONAL_EQUIPMENT_CATEGORY,
  CODE_EASEL_ALUM,
  CODE_EASEL_WOOD,
  CODE_FRAME,
  CODE_FRAME_ASSEMBLED,
  ID_EASEL_ALUM,
  ID_EASEL_WOOD,
  ID_FRAME_ASSEMBLED,
  KIDS_CATEGORY,
  PRICE_EASEL_ALUMINIUM,
  PRICE_EASEL_WOOD,
  PRICE_FRAME,
  PRICE_FRAME_ASSEMBLED,
  PRICE_PICTURE,
  PRICE_PICTURE_KIDS,
  PRICE_SHIPPING,
  SPEC_CATEGORIES,
  WEIGHT_EASEL_ALUMINIUM,
  WEIGHT_EASEL_WOOD,
  WEIGHT_FRAME,
  WEIGHT_PICTURE,
} from "../../common/constants";

export const useCart = () => {
  const page = useSelector<AppState, number>((store) => store.cart.page);
  const cart = useSelector<AppState, IPictureQuantity>(
    (store) => store.cart.cart
  );
  const easelWood = useSelector<AppState, number>(
    (store) => store.cart.easelWood
  );
  const easelAlum = useSelector<AppState, number>(
    (store) => store.cart.easelAlum
  );
  const frames = useSelector<AppState, number>((store) => store.cart.frames);
  const framesAssembled = useSelector<AppState, number>(
    (store) => store.cart.framesAssembled
  );
  const framesAsPictures = useSelector<AppState, boolean>(
    (store) => store.cart.framesAsPictures
  );
  const framesAssembledAsPictures = useSelector<AppState, boolean>(
    (store) => store.cart.framesAssembledAsPictures
  );
  const reserve = useSelector<AppState, IPictureQuantity>(
    (store) => store.cart.reserve
  );
  const notifyCart = useSelector<AppState, boolean>(
    (store) => store.cart.notifyCart
  );

  const allPictures = useSelector<AppState, IPicture[]>(
    (store) => store.pictures.allPictures
  );

  const dispatch = useDispatch();

  const setPage = (page: number) => dispatch({ type: SET_CART_PAGE, page });
  const orderSubmit = (newCart: ICart, result: (id: number) => void) =>
    orderApi(dispatch, newCart, result);
  const resetCart = () => resetCartThunk(dispatch);

  const loadCart = (cart: { [pictureId: number]: number }) =>
    dispatch({ type: LOAD_CART, cart });
  const loadReserve = (reserve: { [pictureId: number]: number }) =>
    dispatch({ type: LOAD_RESERVE, reserve });
  const loadEaselWood = (number: number) =>
    dispatch({ type: LOAD_EASEL_WOOD, number });
  const loadEaselAlum = (number: number) =>
    dispatch({ type: LOAD_EASEL_ALUM, number });
  const loadFrames = (frames: number) =>
    dispatch({ type: LOAD_FRAMES, frames });
  const loadFramesAssembled = (framesAssembled: number) =>
    dispatch({ type: LOAD_FRAMES_ASSEMBLED, framesAssembled });
  const loadFramesAsPictures = (flag: boolean) =>
    dispatch({ type: FRAMES_AS_PICTURES, flag });
  const loadFramesAssembledAsPictures = (flag: boolean) =>
    dispatch({ type: FRAMES_ASSEMBLED_AS_PICTURES, flag });

  const framesAsPicturesToggle = () => {
    dispatch({ type: FRAMES_AS_PICTURES, flag: !framesAsPictures });
    localStorage.setItem(
      "bpb.framesAsPictures",
      JSON.stringify(!framesAsPictures)
    );
  };
  const framesAssembledAsPicturesToggle = () => {
    dispatch({
      type: FRAMES_ASSEMBLED_AS_PICTURES,
      flag: !framesAssembledAsPictures,
    });
    localStorage.setItem(
      "bpb.framesAssembledAsPictures",
      JSON.stringify(!framesAssembledAsPictures)
    );
  };

  const cartCount = useMemo(() => {
    if (cart) {
      return (
        Object.values(cart).reduce((partialSum, a) => partialSum + a, 0) +
        frames +
        framesAssembled +
        easelWood +
        easelAlum
      );
    }
    return 0;
  }, [cart, frames, framesAssembled, easelWood, easelAlum]);

  const reserveCount = () => {
    let q = 0;
    if (reserve) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      for (const [key, value] of Object.entries(reserve)) {
        q += value;
      }
    }
    return q;
  };

  const cartPictures = useCallback(
    () => allPictures.filter((pic) => cart && cart[pic.id] && cart[pic.id] > 0),
    [allPictures, cart]
  );
  const cartReservations = useCallback(
    () =>
      allPictures.filter(
        (pic) => reserve && reserve[pic.id] && reserve[pic.id] > 0
      ),
    [allPictures, reserve]
  );

  const isCartEmpty = () => {
    return (
      cartPictures().length === 0 &&
      frames === 0 &&
      framesAssembled === 0 &&
      easelWood === 0 &&
      easelAlum === 0 &&
      reserveCount() === 0
    );
  };

  // DEPRICIATED
  // const pricePictures = () => {
  //   let total = 0;
  //   cartPictures().forEach((p) => (total += p.price * cart[p.id]));
  //   return total;
  // };

  const pricePictures = () => {
    let total = 0;
    cartPictures()
      .filter((p) => !SPEC_CATEGORIES.includes(p.category)) // ONLY REGULAR PICTURES (CATEGORIES)
      .forEach((p) => (total += p.price * cart[p.id]));
    return total;
  };

  const minMaxPicturePrice = () => {
    const picPrices = cartPictures()
      .filter((p) => !SPEC_CATEGORIES.includes(p.category))
      .map((p) => p.price);

    const min = picPrices.length ? Math.min(...picPrices) : PRICE_PICTURE;
    const max = picPrices.length ? Math.max(...picPrices) : PRICE_PICTURE;

    return { min: min || 1800, max };
  };

  const pricePicturesKids = () => {
    let total = 0;
    cartPictures()
      .filter((p) => p.category === KIDS_CATEGORY)
      .forEach((p) => (total += p.price * cart[p.id]));
    return total;
  };

  const minMaxPicturePriceKids = () => {
    const picPrices = cartPictures()
      .filter((p) => p.category === KIDS_CATEGORY)
      .map((p) => p.price);

    const min = picPrices.length ? Math.min(...picPrices) : PRICE_PICTURE_KIDS;
    const max = picPrices.length ? Math.max(...picPrices) : PRICE_PICTURE_KIDS;

    return { min, max };
  };

  const priceEaselsWood = () => {
    let total = 0;
    total += easelWood * PRICE_EASEL_WOOD;
    return total;
  };
  const priceEaselsAlum = () => {
    let total = 0;
    total += easelAlum * PRICE_EASEL_ALUMINIUM;
    return total;
  };
  const priceFrames = () => {
    let total = 0;
    total += frames * PRICE_FRAME;
    return total;
  };
  const priceFramesAssembled = () => {
    let total = 0;
    total += framesAssembled * PRICE_FRAME_ASSEMBLED;
    return total;
  };
  const priceTotal = () => {
    let total = 0;
    cartPictures().forEach((p) => (total += p.price * cart[p.id]));
    total +=
      frames * PRICE_FRAME +
      framesAssembled * PRICE_FRAME_ASSEMBLED +
      easelWood * PRICE_EASEL_WOOD +
      easelAlum * PRICE_EASEL_ALUMINIUM +
      PRICE_SHIPPING;
    return total;
  };

  const countPrice = useMemo(() => {
    let total = 0;
    cartPictures()
      .filter((p) => !SPEC_CATEGORIES.includes(p.category))
      .map((p) => (total += cart[p.id]));
    return total;
  }, [cartPictures, cart]);

  const countPriceKids = useMemo(() => {
    let total = 0;
    cartPictures()
      .filter((p) => p.category === KIDS_CATEGORY)
      .map((p) => (total += cart[p.id]));
    return total;
  }, [cartPictures, cart]);

  const cartWeight =
    cartCount * WEIGHT_PICTURE +
    frames * WEIGHT_FRAME +
    easelWood * WEIGHT_EASEL_WOOD +
    easelAlum * WEIGHT_EASEL_ALUMINIUM;

  const cartItems: ICartItem[] = useMemo(() => {
    const cartItemPictures = cartPictures().map(
      (p) =>
        ({
          picture_id: p.id,
          code: p.code,
          price: p.price,
          quantity: cart[p.id],
          isReservation: false,
          category: p.category,
          codeSP: p.codeSP,
        } as ICartItem)
    );
    const cartItemReservations = cartReservations().map(
      (r) =>
        ({
          picture_id: r.id,
          code: r.code,
          price: r.price,
          quantity: cart[r.id],
          isReservation: true,
          category: r.category,
          codeSP: r.codeSP,
        } as ICartItem)
    );
    let cartAditionalItems = [] as ICartItem[];
    if (frames > 0) {
      cartAditionalItems.push({
        picture_id: 0,
        code: CODE_FRAME,
        price: PRICE_FRAME,
        quantity: frames,
        isReservation: false,
        category: ADDITIONAL_EQUIPMENT_CATEGORY,
        codeSP: CODE_FRAME,
      } as ICartItem);
    }
    if (framesAssembled > 0) {
      cartAditionalItems.push({
        picture_id: ID_FRAME_ASSEMBLED,
        code: CODE_FRAME_ASSEMBLED,
        price: PRICE_FRAME_ASSEMBLED,
        quantity: framesAssembled,
        isReservation: false,
        category: ADDITIONAL_EQUIPMENT_CATEGORY,
        codeSP: undefined,
      } as ICartItem);
    }
    if (easelWood > 0) {
      cartAditionalItems.push({
        picture_id: ID_EASEL_WOOD,
        code: CODE_EASEL_WOOD,
        price: PRICE_EASEL_WOOD,
        quantity: easelWood,
        isReservation: false,
        category: ADDITIONAL_EQUIPMENT_CATEGORY,
        codeSP: CODE_EASEL_WOOD,
      } as ICartItem);
    }
    if (easelAlum > 0) {
      cartAditionalItems.push({
        picture_id: ID_EASEL_ALUM,
        code: CODE_EASEL_ALUM,
        price: PRICE_EASEL_ALUMINIUM,
        quantity: easelAlum,
        isReservation: false,
        category: ADDITIONAL_EQUIPMENT_CATEGORY,
        codeSP: CODE_EASEL_ALUM,
      } as ICartItem);
    }
    return [
      ...cartItemPictures,
      ...cartAditionalItems,
      ...cartItemReservations,
    ];
  }, [
    cart,
    cartPictures,
    cartReservations,
    easelAlum,
    easelWood,
    frames,
    framesAssembled,
  ]);

  const changeEaselWoodNumber = (quantity: number) => {
    localStorage.setItem("bpb.easelWood", JSON.stringify(quantity));
    dispatch({ type: CHANGE_EASEL_WOOD_NUMBER, quantity });
  };

  const changeEaselAlumNumber = (quantity: number) => {
    localStorage.setItem("bpb.easelAlum", JSON.stringify(quantity));
    dispatch({ type: CHANGE_EASEL_ALUM_NUMBER, quantity });
  };

  const changeFrameNumber = (quantity: number) => {
    if (frames !== quantity) {
      localStorage.setItem("bpb.frames", JSON.stringify(quantity));
      dispatch({ type: CHANGE_FRAME_NUMBER, quantity });
    }
  };

  const changeFrameAssembledNumber = (quantity: number) => {
    if (framesAssembled !== quantity) {
      localStorage.setItem("bpb.framesAssembled", JSON.stringify(quantity));
      dispatch({ type: CHANGE_FRAME_ASSEMBLED_NUMBER, quantity });
    }
  };

  const setFrameAsCart = () => {
    let sum = 0;
    cartPictures()
      .filter((pic) => !SPEC_CATEGORIES.includes(pic.category))
      .forEach((pic) => (sum += cart[pic.id]));
    changeFrameNumber(sum);
  };

  const setFrameAssembledAsCart = () => {
    let sum = 0;
    cartPictures()
      .filter((pic) => !SPEC_CATEGORIES.includes(pic.category))
      .forEach((pic) => (sum += cart[pic.id]));
    changeFrameAssembledNumber(sum);
  };

  const hideNotificationCart = () => {
    dispatch({ type: HIDE_TOAST_CART_NOTIFICATION });
  };

  return {
    loadCart,
    loadReserve,
    loadEaselWood,
    loadEaselAlum,
    loadFrames,
    loadFramesAssembled,
    loadFramesAsPictures,
    loadFramesAssembledAsPictures,
    page,
    setPage,
    cart,
    easelWood,
    easelAlum,
    frames,
    framesAssembled,
    reserve,
    notifyCart,
    cartPictures: cartPictures(),
    cartReservations: cartReservations(),
    cartCount,
    reserveCount: reserveCount(),
    isCartEmpty: isCartEmpty(),
    // pricePictures,
    pricePictures,
    minMaxPicturePrice,
    pricePicturesKids,
    minMaxPicturePriceKids,
    priceFrames,
    priceFramesAssembled,
    priceEaselsWood,
    priceEaselsAlum,
    priceTotal,
    countPrice,
    countPriceKids,
    cartWeight,
    cartItems,
    orderSubmit,
    resetCart,
    changeEaselWoodNumber,
    changeEaselAlumNumber,
    changeFrameNumber,
    changeFrameAssembledNumber,
    setFrameAsCart,
    setFrameAssembledAsCart,
    framesAsPictures,
    framesAssembledAsPictures,
    framesAsPicturesToggle,
    framesAssembledAsPicturesToggle,
    hideNotificationCart,
  };
};
