import { ICategory } from "../../models";
import { ActionType } from "../../util/types";

export const LOAD_CATEGORIES = "LOAD_CATEGORIES";
export const SELECT_CATEGORY = "SELECT_CATEGORY";

export const getCategories = (categories: ICategory[]) => ({
  type: LOAD_CATEGORIES,
  categories,
} as const);

export const selectCategory = (category: ICategory) => ({
  type: SELECT_CATEGORY,
  category,
} as const);

export type MenuActions =
  | ActionType<typeof getCategories>
  | ActionType<typeof selectCategory>
