import axios from "axios";
import appconfig from '../../../AppConfig';
import { debug } from "../../../common/utils";

export const updatePictureOrdersApi = async (
    dispatch: any,
    codes: string[],
) => {
    const url = "/products/updatePictureOrders";
    const data = codes;
    const config = {
        baseURL: appconfig.baseURL,
    }

    await axios.post(url, data, config)
        .then(response => {
            debug("!!! API - updatePictureOrders !!!");

            // dispatch({
            //     type: ADD_COMPETITOR,
            // });

            debug(response);
        })
        .catch(err => {
            console.log(err);
        });
};