import { RESET_CART } from "../cart.actions";

export const resetCartThunk = (dispatch: any) => {
  dispatch({
    type: RESET_CART,
  });
  localStorage.setItem("bpb.cart", JSON.stringify({}));
  localStorage.setItem("bpb.reserve", JSON.stringify({}));
  localStorage.setItem("bpb.easelWood", JSON.stringify(0));
  localStorage.setItem("bpb.easelAlum", JSON.stringify(0));
  localStorage.setItem("bpb.frames", JSON.stringify(0));
  localStorage.setItem("bpb.framesAssembled", JSON.stringify(0));
  localStorage.setItem("bpb.framesAsPictures", JSON.stringify(false));
  localStorage.setItem("bpb.framesAssembledAsPictures", JSON.stringify(false));
};
