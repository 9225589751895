import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  ADDITIONAL_EQUIPMENT_CATEGORY,
  ID_EASEL_ALUM,
  ID_EASEL_WOOD,
  ID_FRAME,
  ID_FRAME_ASSEMBLED,
} from "../../common/constants";
import { IPicture } from "../../models";
import { AppState } from "../main.reducer";
import { getAllPicturesAdminApi } from "./api/getPicturesAdminApi";
import { getAllPicturesApi } from "./api/getPicturesApi";
import { updatePictureIsDeletedApi } from "./api/updatePictureIsDeletedApi";
import { updatePictureOrdersApi } from "./api/updatePictureOrdersApi";
import { updatePicturePricesApi } from "./api/updatePicturePricesApi";
import { updatePictureStateApi } from "./api/updatePictureStateApi";
import {
  HIDE_TOAST_FAV_NOTIFICATION,
  LOAD_FAVORITES,
  SELECT_PICTURE,
} from "./pictures.actions";

export const usePictures = () => {
  const allPictures = useSelector<AppState, IPicture[]>(
    (store) => store.pictures.allPictures
  );
  const allPicturesAdmin = useSelector<AppState, IPicture[]>(
    (store) => store.pictures.allPicturesAdmin
  );
  const favorites = useSelector<AppState, number[]>(
    (store) => store.pictures.favorites
  );
  const selectedPicture = useSelector<AppState, IPicture | undefined>(
    (store) => store.pictures.selectedPicture
  );
  const availableOnly = useSelector<AppState, boolean>(
    (store) => store.pictures.availableOnly
  );
  const notifyFavorites = useSelector<AppState, boolean>(
    (store) => store.pictures.notifyFavorites
  );

  const equipmentState = useMemo(() => {
    const eqPics = allPictures.filter(
      (p) => p.category === ADDITIONAL_EQUIPMENT_CATEGORY
    );
    return {
      frame: eqPics.filter((p) => p.id === ID_FRAME)[0]?.status,
      frameAssembled: eqPics.filter((p) => p.id === ID_FRAME_ASSEMBLED)[0]
        ?.status,
      easelA: eqPics.filter((p) => p.id === ID_EASEL_ALUM)[0]?.status,
      easelW: eqPics.filter((p) => p.id === ID_EASEL_WOOD)[0]?.status,
    };
  }, [allPictures]);

  const dispatch = useDispatch();

  const getAllPictures = () => getAllPicturesApi(dispatch);
  const getAllPicturesAdmin = () => getAllPicturesAdminApi(dispatch);

  const loadFavorites = (favorites: number[]) =>
    dispatch({ type: LOAD_FAVORITES, favorites });

  const selectPicture = (picture: IPicture | undefined) =>
    dispatch({ type: SELECT_PICTURE, picture });

  const updatePictureState = (pictureId: number, status: boolean) =>
    updatePictureStateApi(dispatch, pictureId, !status);

  const updatePictureIsDeleted = (pictureId: number, isDeleted: boolean) =>
    updatePictureIsDeletedApi(dispatch, pictureId, !isDeleted);

  const updatePictureOrders = (codes: string[]) =>
    updatePictureOrdersApi(dispatch, codes);

  const updatePicturePrices = (
    pictureId: number,
    price: number,
    priceNoDiscount: number | null
  ): Promise<boolean> =>
    updatePicturePricesApi(dispatch, pictureId, price, priceNoDiscount);

  const hideNotificationFav = () => {
    dispatch({ type: HIDE_TOAST_FAV_NOTIFICATION });
  };

  // React.useEffect(() => {
  // //   if (allPictures.length === 0) getAllPictures();
  // //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // // }, []);

  return {
    allPictures,
    allPicturesAdmin,
    equipmentState,
    favorites,
    selectedPicture,
    availableOnly,
    notifyFavorites,
    getAllPictures,
    getAllPicturesAdmin,
    loadFavorites,
    selectPicture,
    updatePictureState,
    updatePictureIsDeleted,
    updatePictureOrders,
    hideNotificationFav,
    updatePicturePrices,
  };
};
