import axios from "axios";
import appconfig from "../../../../AppConfig";
import { debug } from "../../../../common/utils";
import { ICart } from "../../../../models";
import { UPDATE_CART } from "../../admin.actions";

export const updateCartApi = async (
  dispatch: any,
  editedCart: ICart
): Promise<boolean> => {
  const url = "cart/updateCart";
  const data = editedCart;
  const config = {
    baseURL: appconfig.baseURL,
  };

  await axios
    .post(url, data, config)
    .then((response) => {
      debug("!!! API - updateCartApi !!!");

      debug(response);

      if (response) {
        dispatch({ type: UPDATE_CART, editedCart });
      }

      return true;
    })
    .catch((err) => {
      console.log(err);
      // alert(err);

      return false;
    });
  return false;
};
