import axios, { AxiosRequestConfig } from "axios";
import appconfig from '../../../../AppConfig';
import { debug } from "../../../../common/utils";
import { GET_CART_ORDER_ITEMS } from "../../admin.actions";

export const getCartOrderItemsApi = async (
    dispatch: any,
    cartId: number,
) => {
    const config = {
        baseURL: appconfig.baseURL,
        params: { cartId },
    } as AxiosRequestConfig;

    await axios.get("/cart/orderItem", config)
        .then((response: any) => {
            debug("!!! API - getCartOrderItemsApi !!!");

            dispatch({
                type: GET_CART_ORDER_ITEMS,
                cartId,
                data: response.data
            });

            debug(response);
        })
        .catch((err: any) => {
            console.log(err);
            // dispatch({ type: LOAD_STATUS, newStatus: "error" });
        });
};