import axios from "axios";
import appconfig from '../../../AppConfig';
import { debug } from "../../../common/utils";
import { UPDATE_PICTURE_STATE } from "../pictures.actions";

export const updatePictureStateApi = async (
    dispatch: any,
    pictureId: number,
    status: boolean,
) => {
    const url = `products/updatePictureStatus/${pictureId}/${status}`;
    const config = {
        baseURL: appconfig.baseURL,
    }

    await axios.post(url, null, config)
        .then(response => {
            debug("!!! API - updatePictureStatusApi !!!");

            if (response && response.data) {
                dispatch({
                    type: UPDATE_PICTURE_STATE,
                    pictureId,
                    status,
                });
            }

            debug(response);
        })
        .catch(err => {
            console.log(err);
            // alert(err);
        });
};