import React from "react";
import {
  IonCard,
  IonCol,
  IonGrid,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonRow,
  IonTextarea,
} from "@ionic/react";
import { ICart } from "../../models";
import { OrderPriceDetails } from "./OrderPriceDetails";

interface Props {
  cart: ICart;
  updateCart: (cart: ICart) => void;
}

export const CartOrder: React.FC<Props> = ({ cart, updateCart }) => {
  return (
    <>
      <IonGrid style={{ margin: 0, padding: 0 }}>
        <IonRow>
          <IonCol
            size="12"
            sizeMd="6"
            offsetMd="3"
            style={{ margin: 0, padding: 0 }}
          >
            <OrderPriceDetails deliveryInfo />

            <IonCard>
              <IonList>
                <IonItem>
                  <IonLabel
                    color="medium"
                    position="stacked"
                    className={
                      !!cart.name && cart.name.length > 3 ? "" : "required"
                    }
                  >
                    Ime i prezime
                  </IonLabel>
                  <IonInput
                    type="text"
                    // name="name"
                    // autocomplete="name"
                    required
                    value={cart.name}
                    onIonInput={(e: any) =>
                      updateCart({ ...cart, name: e.detail.value })
                    }
                  />
                </IonItem>
                <IonItem aria-required={true}>
                  <IonLabel
                    color="medium"
                    position="stacked"
                    className={
                      !!cart.address && cart.address.length > 5
                        ? ""
                        : "required"
                    }
                  >
                    Adresa
                  </IonLabel>
                  <IonInput
                    type="text"
                    // name="street-address"
                    // autocomplete="street-address"
                    required
                    value={cart.address}
                    onIonInput={(e: any) =>
                      updateCart({ ...cart, address: e.detail.value })
                    }
                  />
                </IonItem>
                <IonItem>
                  <IonLabel
                    color="medium"
                    position="stacked"
                    className={
                      !!cart.zip && cart.zip.length > 3 ? "" : "required"
                    }
                  >
                    Poštanski broj
                  </IonLabel>
                  <IonInput
                    type="text"
                    // name="postal-code"
                    // autocomplete="postal-code"
                    required
                    value={cart.zip}
                    onIonInput={(e: any) =>
                      updateCart({ ...cart, zip: e.detail.value })
                    }
                  />
                </IonItem>
                <IonItem>
                  <IonLabel
                    color="medium"
                    position="stacked"
                    className={
                      !!cart.place && cart.place.length > 1 ? "" : "required"
                    }
                  >
                    Mesto
                  </IonLabel>
                  <IonInput
                    type="text"
                    // name="city"
                    // autocomplete="address-level2"
                    required
                    value={cart.place}
                    onIonInput={(e: any) =>
                      updateCart({ ...cart, place: e.detail.value })
                    }
                  />
                </IonItem>
                <IonItem>
                  <IonLabel
                    color="medium"
                    position="stacked"
                    className={
                      !!cart.phone && cart.phone.length > 6 ? "" : "required"
                    }
                  >
                    Kontakt telefon
                  </IonLabel>
                  <IonInput
                    type="text"
                    // name="tel"
                    // autocomplete="tel"
                    required={!!cart.phone && cart.phone.length <= 6}
                    value={cart.phone}
                    onIonInput={(e: any) =>
                      updateCart({ ...cart, phone: e.detail.value })
                    }
                  />
                </IonItem>
                <IonItem>
                  <IonLabel color="medium" position="stacked">
                    Email
                  </IonLabel>
                  <IonInput
                    type="text"
                    // name="email"
                    // autocomplete="email"
                    value={cart.email}
                    onIonInput={(e: any) =>
                      updateCart({ ...cart, email: e.detail.value })
                    }
                  />
                </IonItem>
                <IonItem>
                  <IonLabel color="medium" position="stacked">
                    Napomena
                  </IonLabel>
                  <IonTextarea
                    rows={3}
                    value={cart.note}
                    onIonInput={(e: any) =>
                      updateCart({ ...cart, note: e.detail.value })
                    }
                  />
                </IonItem>
              </IonList>
            </IonCard>
          </IonCol>
        </IonRow>
      </IonGrid>
    </>
  );
};
