import React from "react";
import { IonChip, IonIcon, IonLabel } from "@ionic/react";

interface Props {
  text: string;
  center?: boolean;
  disabled?: boolean;
  icon?: string;
  onTop?: boolean;
}

const SubHeader: React.FC<Props> = ({
  text,
  children,
  disabled,
  icon,
  onTop,
}) => (
  <IonChip
    style={{
      display: "flex",
      padding: "25px 20px",
      marginBottom: 10,
      marginTop: onTop ? 5 : 20,
    }}
    disabled={disabled}
  >
    <IonIcon icon={icon} size="large" color="dark" />
    <IonLabel className="f15">{text}</IonLabel>
    {children && (
      <>
        <div style={{ flexGrow: 1 }}></div>
        {children}
      </>
    )}
  </IonChip>
);

export default SubHeader;
