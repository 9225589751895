import { IonButtons, IonIcon, IonText } from "@ionic/react";
import { cart, cartOutline, heart, heartOutline } from "ionicons/icons";
import { Link } from "react-router-dom";
import { useCart } from "../../pages/Cart/useCart";
import { usePictures } from "../../pages/Pictures/usePictures";

const ToolbarIcons = () => {
  const { favorites } = usePictures();
  const { cartCount } = useCart();

  return (
    <>
      <IonButtons slot="end" style={{ paddingRight: 15 }}>
        <Link
          to={{ pathname: "/omiljene" }}
          style={{ textDecoration: "none", color: "#fff" }}
        >
          <IonIcon
            icon={favorites.length > 0 ? heart : heartOutline}
            style={{ fontSize: 28 }}
          />
        </Link>
        <IonText style={{ paddingLeft: 3 }}>{favorites.length}</IonText>
      </IonButtons>
      <IonButtons slot="end" style={{ paddingRight: 15 }}>
        <Link
          to={{ pathname: "/korpa" }}
          style={{ textDecoration: "none", color: "#fff" }}
        >
          <IonIcon
            icon={cartCount > 0 ? cart : cartOutline}
            style={{ fontSize: 28 }}
          />
        </Link>
        <IonText style={{ paddingLeft: 3 }}>{cartCount}</IonText>
      </IonButtons>
    </>
  );
};

export default ToolbarIcons;
