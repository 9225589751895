import axios from "axios";
import appconfig from "../../../../AppConfig";
import { debug } from "../../../../common/utils";
import { ICart } from "../../../../models";
import { UPDATE_CART_HANDLED } from "../../admin.actions";

export const updateCartHandledApi = async (
  dispatch: any,
  cart: ICart,
  isHandled: boolean = false
) => {
  const url = `cart/updateCartHandled/${cart.id}/${isHandled}`;
  const config = {
    baseURL: appconfig.baseURL,
  };

  await axios
    .post(url, null, config)
    .then((response) => {
      debug("!!! API - updateCartHandledApi !!!");

      if (response && response.data) {
        dispatch({
          type: UPDATE_CART_HANDLED,
          cart,
        });
      }

      debug(response);
    })
    .catch((err) => {
      console.log(err);
      // alert(err);
    });
};
