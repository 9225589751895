import { FC } from "react";
import { useParams } from "react-router";
import { IonContent, IonPage } from "@ionic/react";
import { usePictures } from "../Pictures/usePictures";
import PictureCard from "../Pictures/PictureCard";
import { IPicture } from "../../models";

const Picture: FC = () => {
  const { id } = useParams<{ id: string }>();
  const { allPictures } = usePictures();
  const pic: IPicture = allPictures.filter((p) => p.code === id)[0];

  return (
    <IonPage id="picture">
      <IonContent>
        <PictureCard pic={pic} />
      </IonContent>
    </IonPage>
  );
};

export default Picture;
