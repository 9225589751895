import axios, { AxiosRequestConfig } from "axios";
import appconfig from "../../../../AppConfig";
import { debug } from "../../../../common/utils";
import {
  GET_CART_ORDERS_PAGED,
  GET_CART_ORDERS_PAGED_REFRESH,
} from "../../admin.actions";

export const getCartOrdersPagedApi = async (
  dispatch: any,
  tab: string,
  skip: number,
  take: number,
  refresh?: boolean
) => {
  const config = {
    baseURL: appconfig.baseURL,
    params: { tab, skip, take },
  } as AxiosRequestConfig;

  await axios
    .get("/cart/ordersPaged", config)
    .then((response: any) => {
      debug("!!! API - getCartOrdersPaged !!!");

      if (refresh) {
        dispatch({
          type: GET_CART_ORDERS_PAGED_REFRESH,
          data: response.data.items,
          take,
          total: response.data.total,
        });
      } else {
        dispatch({
          type: GET_CART_ORDERS_PAGED,
          data: response.data.items,
          take,
          total: response.data.total,
        });
      }

      debug(response);
    })
    .catch((err: any) => {
      console.log(err);
    });
};
