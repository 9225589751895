import * as actions from "./common.actions";

interface ICommonState {
  darkMode: boolean;
  zoom: number;
}

const initialState: ICommonState = {
  darkMode: false,
  zoom: 1,
} as ICommonState;

export const commonReducer = (
  state: ICommonState = initialState,
  action: any
): ICommonState => {
  switch (action.type) {
    case actions.CHANGE_DARK_MODE:
      return {
        ...state,
        darkMode: action.darkMode,
      };

    case actions.SET_ZOOM:
      return {
        ...state,
        zoom: action.zoom,
      };

    default:
      return state;
  }
};
