import React, { CSSProperties } from "react";
import NumberFormat from "react-number-format";

export const NumberMoney: React.FC<{
  value: number;
  minValue?: number;
  style?: CSSProperties;
}> = ({ value, minValue, style }) => (
  <span style={{ whiteSpace: "nowrap", ...style }}>
    {minValue && minValue < value && (
      <>
        <NumberFormat
          value={minValue}
          displayType="text"
          thousandSeparator="."
          decimalSeparator=","
          decimalScale={0}
          fixedDecimalScale={true}
        />
        {" - "}
      </>
    )}
    <NumberFormat
      value={value}
      displayType="text"
      thousandSeparator="."
      decimalSeparator=","
      decimalScale={0}
      fixedDecimalScale={true}
    />
    &nbsp;<sup style={{ fontSize: "70%" }}>din</sup>
  </span>
);

export const NumberDecimal: React.FC<{ value: number }> = ({ value }) => (
  <NumberFormat
    value={value}
    displayType="text"
    thousandSeparator="."
    decimalSeparator=","
    decimalScale={2}
    fixedDecimalScale={true}
  />
);
