import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonChip,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonLabel,
  IonMenuToggle,
  IonPage,
  IonRow,
  IonText,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { Link } from "react-router-dom";
import { arrowForward, documentText, menu } from "ionicons/icons";
import { CategorieLinks } from "../../components/CategorieLinks/CategorieLinks";

interface DocLinkProps {
  link: string;
}

const DocLink: React.FC<DocLinkProps> = ({ children, link }) => (
  <div>
    <a
      href={`https://bojenjepobrojevima.rs/assets/docs/${link}`}
      style={{
        textDecoration: "none",
        color: "var(--ion-color-dark)",
      }}
      target="_blank"
      rel="noreferrer"
    >
      {children}
    </a>
  </div>
);

const AboutUs = () => (
  <IonPage id="start-page">
    <IonHeader no-border>
      <IonToolbar color="primary">
        <IonButtons slot="start">
          <IonMenuToggle>
            <IonIcon icon={menu} style={{ fontSize: 28 }} />
          </IonMenuToggle>
        </IonButtons>
        <IonTitle>Bojenje po brojevima</IonTitle>
        <IonButtons slot="end">
          <Link to={{ pathname: "/slike" }}>
            <IonButton color="dark">Motivi</IonButton>
          </Link>
        </IonButtons>
      </IonToolbar>
    </IonHeader>
    <IonContent fullscreen>
      <IonGrid className="ion-no-padding">
        <IonRow>
          <IonCol size="12" sizeMd="6" offsetMd="3">
            <img src="assets/BPB_logo.png" alt="" className="slide-image" />
          </IonCol>
        </IonRow>
      </IonGrid>

      <IonGrid className="ion-no-padding">
        <IonRow>
          <IonCol size="12" sizeMd="8" offsetMd="2">
            <IonCard>
              <IonCardHeader
                className="b f15 ion-text-center"
                style={{ color: "var(--ion-color-dark)" }}
              >
                O nama
              </IonCardHeader>
              <IonCardContent>
                <div
                  className="ion-text-justify"
                  style={{ paddingTop: "10px" }}
                >
                  <IonText>
                    Bojenje po brojevima je zasnovano na tehnici usmeravanja
                    pažnje koja je izuzetno korisna vežba za ovladavanje mislima
                    i osećanjima, kao i za prevazilaženje stresa. Zbog toga je
                    terapija umetnošću stekla veliku popularnost i dragocen je
                    pomoćnik u postizanju unutrašnjeg mira i opšte harmonije.
                    <br />
                    <br />
                    Na našem sajtu možete izvršiti porudžbinu izabranih motiva
                    ili izvršiti rezervaciju ukoliko odabrani modeli nisu odmah
                    na stanju. Neko iz našeg tima će vas kontaktirati radi
                    potvrde porudžbine i obavestiti vas o tačnom terminu
                    isporuke.
                    <br />
                    <br />
                    Na sajtu se ne vrši plaćanje, platićete kuriru pri dostavi
                    paketa. Dostupni motivi mogu biti na vašoj adresi za 2-3
                    radna dan.
                  </IonText>
                </div>
                <br />

                <DocLink link="Obavestenje-o-politici-privatnosti.pdf">
                  <IonChip>
                    <IonIcon icon={documentText} color="dark" />
                    <IonLabel style={{ textDecoration: "none" }}>
                      Obaveštenje o politici privatnosti
                    </IonLabel>
                  </IonChip>
                </DocLink>
                <DocLink link="Pravilnik-o-kataloškom-poručivanju-proizvoda.pdf">
                  <IonChip>
                    <IonIcon icon={documentText} color="dark" />
                    <IonLabel>
                      Pravilnik o kataloškom poručivanju proizvoda
                    </IonLabel>
                  </IonChip>
                </DocLink>
                <DocLink link="Pravilnik-o-zaštiti-podataka-ličnosti.pdf">
                  <IonChip>
                    <IonIcon icon={documentText} color="dark" />
                    <IonLabel>Pravilnik o zaštiti podataka ličnosti</IonLabel>
                  </IonChip>
                </DocLink>
                <DocLink link="Pravo-na-odustajanje.pdf">
                  <IonChip>
                    <IonIcon icon={documentText} color="dark" />
                    <IonLabel>Pravo na odustajanje</IonLabel>
                  </IonChip>
                </DocLink>
                <DocLink link="Reklamacioni-list.pdf">
                  <IonChip>
                    <IonIcon icon={documentText} color="dark" />
                    <IonLabel>Reklamacioni list</IonLabel>
                  </IonChip>
                </DocLink>
              </IonCardContent>
            </IonCard>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <CategorieLinks />
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol className="ion-text-center">
            <Link to={{ pathname: `/paket` }}>
              <IonButton fill="clear" style={{ marginBottom: 10 }}>
                Sadržaj seta
                <IonIcon slot="end" icon={arrowForward} />
              </IonButton>
            </Link>
          </IonCol>
        </IonRow>
      </IonGrid>
    </IonContent>
  </IonPage>
);

export default AboutUs;
