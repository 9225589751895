import { ActionType } from "../util/types";

export const CHANGE_DARK_MODE = "CHANGE_DARK_MODE";
export const SET_ZOOM = "SET_ZOOM";

export const changeDarkMode = (darkMode: boolean) => ({
  type: CHANGE_DARK_MODE,
  darkMode
} as const);

export const setZoom = (zoom: number) => ({
  type: SET_ZOOM,
  zoom
} as const);

export type CommonActions =
  | ActionType<typeof changeDarkMode>
  | ActionType<typeof setZoom>