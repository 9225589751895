import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonModal,
  IonRow,
  IonSpinner,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { FC } from "react";
import { OrderPriceDetails } from "./OrderPriceDetails";
import { ICart } from "../../models";
import { Flex } from "../../components/Flex/Flex";

const Row: FC<{ label: string; value?: string }> = ({ label, value }) => (
  <IonRow>
    <IonCol
      style={{
        textAlign: "right",
        color: "var(--ion-color-dark-shade)",
      }}
    >
      <span style={{ lineHeight: "25px" }}>{label}</span>
    </IonCol>
    <IonCol className="b vertical-center">
      <span style={{ lineHeight: "25px" }}>{value}</span>
    </IonCol>
  </IonRow>
);

interface Props {
  isOpen: boolean;
  cart: ICart;
  onCancel: () => void;
  onSubmit: () => void;
  isSubmitting?: boolean;
}

export const OrderConfirmation: FC<Props> = ({
  isOpen,
  cart,
  onCancel,
  onSubmit,
  isSubmitting,
}) => {
  return (
    <IonModal isOpen={isOpen}>
      <IonHeader>
        <IonToolbar color="success">
          <IonTitle>Potvrda porudžbine</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonGrid style={{ width: "100%" }}>
          <Row label="Ime i prezime" value={cart.name} />
          <Row label="Adresa" value={cart.address} />
          <Row label="Poš. br. i mesto" value={`${cart.zip} ${cart.place}`} />
          <Row label="Telefon" value={cart.phone} />
          <Row label="e-mail" value={cart.email} />
          <Row label="Napomena" value={cart.note} />
        </IonGrid>
        <OrderPriceDetails />
        <Flex css={{ padding: "0 10px" }}>
          <IonButton
            color="light"
            onClick={onCancel}
            style={{ flexGrow: 0 }}
            disabled={isSubmitting}
          >
            Vrati se nazad
          </IonButton>
          <IonButton
            color="primary"
            onClick={onSubmit}
            style={{ flexGrow: 1 }}
            disabled={isSubmitting}
          >
            {isSubmitting ? (
              <IonSpinner name="crescent" style={{ width: 21, height: 21 }} />
            ) : (
              <>NARUČI</>
            )}
          </IonButton>
        </Flex>
      </IonContent>
    </IonModal>
  );
};
